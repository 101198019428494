export class CreatePromotionModel {
    name: string;
    description: string;
    startDate: Date;
    endDate: Date;
    startDateVoucher: Date;
    endDateVoucher: Date;
    promotionRewardName: string;
    promotionRewardDescription: string;
    recurrencePattern : string;
}