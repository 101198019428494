import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { filter } from 'rxjs';
import { businessAccountGetAction } from 'src/app/stores/global/app.actions';
import { selectBusinessAccount } from 'src/app/stores/global/app.selectors';

@Component({
  selector: 'app-getting-started',
  templateUrl: './getting-started.component.html',
  styleUrls: ['./getting-started.component.scss']
})
export class GettingStartedComponent {
  constructor(private readonly router: Router,
    private readonly store: Store
  ) { }

  ngOnInit(): void {
    // Redirect if already onboarded.
    this.store.select(selectBusinessAccount).pipe(filter(result => Boolean(result))).subscribe(businessAccount => {
      const companyDetailsOnBoarded: boolean = Boolean(businessAccount.company.name) && businessAccount.company.name !== '';
      const companyLogoOnBoarded: boolean = Boolean(businessAccount.company.logo);
      if (companyDetailsOnBoarded && companyLogoOnBoarded) {
        this.router.navigate(['/dashboard']);
      }
    });
  }

  onOnBoardingFinishedEvent($event): void {
    // Refetch the business account from the server to update the store with the latest state
    this.store.dispatch(businessAccountGetAction());
  }
}
