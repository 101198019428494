import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, map, of, throwError } from 'rxjs';
import { Page } from '../models/paging/page-model';
import { PromotionModel } from '../models/promotion-model';
import { environment } from 'src/environments/environment';
import { CreatePromotionModel } from '../models/create-promotion-model';
import { formatDate } from '@angular/common';
import { NoActiveSubscriptionException } from '../core/exceptions/no-active-subscription-exception';
import { UnknownErrorException } from '../core/exceptions/unknown-error-exception';
import { MaximumActivePromotionsExceededException } from '../core/exceptions/maximum-active-promotions-exceeded-exception';
import { CompanyModel } from '../models/company-model';
import { PromotionRewardModel } from '../models/promotion-reward-model';
import { ValidationException } from '../core/exceptions/validation-exception';
import { UpdatePromotionModel } from '../models/update-promotion-model';


export class CreatePromotionApiModel {
  name: string;
  description: string;
  startDate: string;
  endDate: string;
  startDateVoucher: string;
  endDateVoucher: string;
  promotionRewardName: string;
  promotionRewardDescription: string;
  recurrencePattern : string;
}

export class UpdatePromotionApiModel {
  name: string;
  description: string;
  startDate: string;
  endDate: string;
  startDateVoucher: string;
  endDateVoucher: string;
  promotionRewardName: string;
  promotionRewardDescription: string;
  recurrencePattern : string;
  promotionStatus: string;
}

@Injectable({
  providedIn: 'root'
})
export class PromotionService {
  constructor(private http: HttpClient) { }

  getPromotions(companyId: string, currentPage: number, pageSize: number): Observable<Page<PromotionModel>> {
    let httpParams = new HttpParams();
    httpParams = httpParams.append("page", currentPage.toString());
    httpParams = httpParams.append("size", pageSize.toString());

    const httpOptions = {
      headers: new HttpHeaders({
        ContentType: 'application/json',
      }),
      params: httpParams
    };

    const url = environment.apiUrl + '/api/partner/companies/' + companyId + '/promotions';
    const result = this.http.get<Page<PromotionModel>>(url, httpOptions);
    return result.pipe(
      catchError(
        this.handleError<Page<PromotionModel>>('getPromotions', null)
      )
    );
  }

  getPromotion(companyId: string, promotionId: number): Observable<PromotionModel> {
    const httpOptions = {
      headers: new HttpHeaders({
        ContentType: 'application/json',
      }),
    };

    const url = environment.apiUrl + '/api/partner/companies/' + companyId + '/promotions/' + promotionId;
    const result = this.http.get<PromotionModel>(url, httpOptions);
    return result.pipe(
      map(data => this.mapFromData(data)),
      catchError(
        this.handleError<PromotionModel>('getPromotion', null)
      )
    );
  }

  createPromotion(companyId: string, createPromotion: CreatePromotionModel): Observable<PromotionModel> {
    const httpOptions = {
      headers: new HttpHeaders({
        ContentType: 'application/json',
      }),
    };

    const createPromotionApiModel = new CreatePromotionApiModel();
    createPromotionApiModel.name = createPromotion.name;
    createPromotionApiModel.description = createPromotion.description;
    createPromotionApiModel.startDate = formatDate(createPromotion.startDate.toUTCString(), 'yyyy-MM-ddTHH:mm:00Z', 'en-US', 'UTC');
    createPromotionApiModel.endDate = formatDate(createPromotion.endDate.toUTCString(), 'yyyy-MM-ddTHH:mm:00Z', 'en-US', 'UTC');
    createPromotionApiModel.startDateVoucher = formatDate(createPromotion.startDateVoucher.toUTCString(), 'yyyy-MM-ddTHH:mm:00Z', 'en-US', 'UTC');
    createPromotionApiModel.endDateVoucher = formatDate(createPromotion.endDateVoucher.toUTCString(), 'yyyy-MM-ddTHH:mm:00Z', 'en-US', 'UTC');
    createPromotionApiModel.promotionRewardName = createPromotion.promotionRewardName;
    createPromotionApiModel.promotionRewardDescription = createPromotion.promotionRewardDescription;
    createPromotionApiModel.recurrencePattern = createPromotion.recurrencePattern;

    const url = environment.apiUrl + '/api/partner/companies/' + companyId + '/promotions';
    const result = this.http.post<PromotionModel>(url, createPromotionApiModel, httpOptions);
    return result.pipe(
      map(data => {
        return this.mapFromData(data);
      }),
      catchError((response: HttpErrorResponse) => {
        if (response.status == 400) {
          if (response.error.violations) {
            const validationException = new ValidationException(response.error.violations);
            return throwError(() => validationException);
          }
          return throwError(() => new UnknownErrorException());
        }
        else if (response.status == 403) {
          if (response.error.title == 'Maximum active promotions exceeded.') {
            return throwError(() => new MaximumActivePromotionsExceededException());
          }
        }
        return throwError(() => this.handleError('createPromotion', null));
      })
    );
  }

  deletePromotion(companyId: string, promotionId: number) {
    const httpOptions = {
      headers: new HttpHeaders({
        ContentType: 'application/json',
      }),
    };

    const url = environment.apiUrl + '/api/partner/companies/' + companyId + '/promotions/' + promotionId;
    const result = this.http.delete(url, httpOptions);
    return result.pipe(catchError(this.handleError('deletePromotion', null)));
  }

  updatePromotion(companyId: string, promotionId: number, updatePromotion: UpdatePromotionModel) {
    const httpOptions = {
      headers: new HttpHeaders({
        ContentType: 'application/json',
      }),
    };

    const updatePromotionApiModel = new UpdatePromotionApiModel();
    updatePromotionApiModel.name = updatePromotion.name;
    updatePromotionApiModel.description = updatePromotion.description;
    updatePromotionApiModel.startDate = formatDate(updatePromotion.startDate.toUTCString(), 'yyyy-MM-ddTHH:mm:00Z', 'en-US', 'UTC');
    updatePromotionApiModel.endDate = formatDate(updatePromotion.endDate.toUTCString(), 'yyyy-MM-ddTHH:mm:00Z', 'en-US', 'UTC');
    updatePromotionApiModel.startDateVoucher = formatDate(updatePromotion.startDateVoucher.toUTCString(), 'yyyy-MM-ddTHH:mm:00Z', 'en-US', 'UTC');
    updatePromotionApiModel.endDateVoucher = formatDate(updatePromotion.endDateVoucher.toUTCString(), 'yyyy-MM-ddTHH:mm:00Z', 'en-US', 'UTC');
    updatePromotionApiModel.promotionRewardName = updatePromotion.promotionRewardName;
    updatePromotionApiModel.promotionRewardDescription = updatePromotion.promotionRewardDescription;
    updatePromotionApiModel.recurrencePattern = updatePromotion.recurrencePattern;
    updatePromotionApiModel.promotionStatus = updatePromotion.promotionStatus;

    const url = environment.apiUrl + '/api/partner/companies/' + companyId + '/promotions/' + promotionId;
    const result = this.http.put<PromotionModel>(url, updatePromotionApiModel, httpOptions);
    return result.pipe(
      map(data => this.mapFromData(data)),
      catchError((response: HttpErrorResponse) => {
        if (response.status == 400) {
          if (response.error.violations) {
            const validationException = new ValidationException(response.error.violations);
            return throwError(() => validationException);
          }
          return throwError(() => new UnknownErrorException());
        }
        else if (response.status == 409) {
          if (response.error == 'No active subscriptionplan order found') {
            return throwError(() => new NoActiveSubscriptionException());
          }
          else if (response.error == 'Maximum active promotions exceeded') {
            return throwError(() => new MaximumActivePromotionsExceededException());
          }
          else {
            return throwError(() => new UnknownErrorException());
          }
        }
        return throwError(() => this.handleError('updatePromotion', null));
      })
    );
  }

  /**
     * Handle Http operation that failed.
     * Let the app continue.
     * @param operation - name of the operation that failed
     * @param result - optional value to return as the observable result
     */
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      console.error(error); // log to console instead
      // this.log(`${operation} failed: ${error.message}`);
      // Let the app keep running by returning an empty result.
      return of(result);
    };
  }

  private mapFromData(data: PromotionModel): PromotionModel {
    const promotion = new PromotionModel();
    promotion.id = data.id;
    promotion.name = data.name;
    promotion.description = data.description;
    promotion.promotionStatus = data.promotionStatus;
    promotion.startDate = data.startDate;
    promotion.endDate = data.endDate;
    promotion.startDateVoucher = data.startDateVoucher;
    promotion.endDateVoucher = data.endDateVoucher;
    promotion.recurrencePattern = data.recurrencePattern;

    promotion.company = new CompanyModel(
      data.company.id,
      data.company.name,
      data.company.description,
      data.company.logo,
      data.company.email,
      data.company.url);

    promotion.promotionReward = new PromotionRewardModel();
    promotion.promotionReward.id = data.promotionReward.id;
    promotion.promotionReward.name = data.promotionReward.name;
    promotion.promotionReward.description = data.promotionReward.description;
    return promotion;
  }
}