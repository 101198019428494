import { Injectable } from "@angular/core";
import { ComponentStore } from "@ngrx/component-store";
import { Observable } from "rxjs";
import { catchError, map, switchMap, tap } from "rxjs/operators";
import { CampaignModel } from "src/app/models/campaign-model";
import { CampaignService } from "src/app/services/campaign.service";
import { CampaignsState } from "./campaigns.state";

const initialState: CampaignsState = {
    loading: false,
    loaded: false,
    success: false,
    errorMessage: null,
    campaigns: [],
    currentPage: 0,
    totalElements: 0,
};

@Injectable({
    providedIn: 'root'
})
export class CampaignsComponentStore extends ComponentStore<CampaignsState> {

    constructor(private readonly campaignService: CampaignService) {
        super(initialState);
    }

    readonly campaigns$: Observable<CampaignModel[]> = this.select(state => state.campaigns);
    readonly currentPage$: Observable<number> = this.select(state => state.currentPage);
    readonly totalElements$: Observable<number> = this.select(state => state.totalElements);
    readonly loading$: Observable<boolean> = this.select(state => state.loading);
    readonly loaded$: Observable<boolean> = this.select(state => state.loaded);
    readonly success$: Observable<boolean> = this.select(state => state.success);
    readonly errorMessage$: Observable<any> = this.select(state => state.errorMessage);

    readonly viewModel$ = this.select(
        this.campaigns$,
        this.currentPage$,
        this.totalElements$,
        this.loaded$,
        this.loading$,
        this.success$,
        this.errorMessage$,
        (campaigns, currentPage, totalElements, loaded, loading, success, errorMessage) => ({
            campaigns, currentPage, totalElements, loaded, loading, success, errorMessage
        })
    );

    readonly setInitial = this.updater(( _ : CampaignsState) => {
        return {
            ...initialState
        };
    });

    readonly setLoading = this.updater((state: CampaignsState) => {
        return {
            ...state,
            loading: true,
            loaded: false
        };
    });

    readonly setLoaded = this.updater((state: CampaignsState) => {
        return {
            ...state,
            loading: false,
            loaded: true,
        };
    });

    readonly updateCampaigns = this.updater((state: CampaignsState, value: {
        content: CampaignModel[],
        currentPage: number,
        totalElements: number
    }) => {
        return {
            ...state,
            loading: false,
            loaded: true,
            success: true,
            campaigns: value.content, // TODO Find out why the ... operator doesn't work here [...state.storeLocations, value.content]
            currentPage: value.currentPage,
            totalElements: value.totalElements
        };
    });


    readonly setError = this.updater((state: CampaignsState, value: {
        errorMessage: String
    }) => {
        return {
            ...state,
            loading: false,
            loaded: true,
            success: false,
            errorMessage: value.errorMessage,
        };
    });

    readonly getCampaigns = this.effect((storeLocations$: Observable<{ companyId: string, currentPage: number, pageSize: number }>) => {
        this.setLoading();
        return storeLocations$.pipe(
            switchMap((params => this.campaignService.getCampaigns(params.companyId, params.currentPage, params.pageSize).pipe(
                map((page) => {
                    this.updateCampaigns({ content: page.content, currentPage: page.number, totalElements: page.totalElements })
                    this.setLoaded();
                }),
                catchError(async (error) => {
                    this.setError({ errorMessage: error })
                    this.setLoaded()
                })
            ))
            )
        );
    });
}