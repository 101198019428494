import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, OnInit } from '@angular/core';

export interface ExplanationStep {
  index: number;
  title: string;
  subtitle: string;
  description: string;
  imageUrl: string;
  showPricingAction: boolean;
  forBusiness: boolean;
  steps: string[];
}

const EXPLANATION_STEPS: ExplanationStep[] = [
  // For your Customers
  { 
    forBusiness: false, 
    index: 0, 
    title: "Starters", 
    subtitle: "Download the Planet VG app", 
    description: "Onboard your customers.", 
    showPricingAction: false, 
    imageUrl: "/assets/img/how-it-works/product-banner-how-it-works-customers-starters.webp", 
    steps: [
      "Your customers download the Planet VG app.",
      "They create a Planet VG account.",
      "And become a member of your business.",
    ] },
  { 
    forBusiness: false, 
    index: 1, 
    title: "Mains", 
    subtitle: "Claim Vouchers and Collect Stamps", 
    description: "Give the best loyalty experience.", 
    showPricingAction: false, 
    imageUrl: "/assets/img/how-it-works/product-banner-how-it-works-customers-mains.webp", 
    steps: [
      "They can claim your promotional vouchers.",
      "Give reward vouchers when they have a full stamp card.", 
      "Redeem their rewards, the next time they visit your business.",
    ] },
  { 
    forBusiness: false, 
    index: 2, 
    title: "Desserts", 
    subtitle: "Redeem Rewards", 
    description: "Make your business highly valued.", 
    showPricingAction: true, 
    imageUrl: "/assets/img/how-it-works/product-banner-how-it-works-customers-desserts.webp", 
    steps: [
      "Your customers will feel appreciated and valued.",
      "You reward your customers for their loyalty and support.",
      "Build that stronger and lasting connection."
    ] },
  // For your Business
  { 
    forBusiness: true, 
    index: 0,
    title: "Starters", 
    subtitle: "Become a Partner", 
    description: "Let's get started.", 
    showPricingAction: false, 
    imageUrl: "/assets/img/how-it-works/product-banner-how-it-works-business-starters.webp", 
    steps: [
      "Sign up for a Planet VG account.", 
      "Pick the right subscription plan for your business.",
      "Receive an amuse-bouche, a free 90-day trial.",
    ] },
  { 
    forBusiness: true, 
    index: 1, 
    title: "Mains", 
    subtitle: "Create Promotions and Stamp Cards", 
    description: "Build the best loyalty experience.", 
    showPricingAction: false, 
    imageUrl: "/assets/img/how-it-works/product-banner-how-it-works-business-mains.webp", 
    steps: [
      "Sign in to the partner portal.",
      "Create your promotional voucher.",
      "Create your loyalty stamp card."
    ] },
  { 
    forBusiness: true, 
    index: 2, 
    title: "Desserts", 
    subtitle: "Understand your customers", 
    description: "Let's start saving.", 
    showPricingAction: true, 
    imageUrl: "/assets/img/how-it-works/product-banner-how-it-works-business-desserts.webp", 
    steps: [
      "Download the Planet VG Store app",
      "Issue stamps and redeem vouchers.",
      "Analyze how your loyalty campaigns are performing."
    ] },
];

@Component({
  selector: 'app-how-does-it-work',
  templateUrl: './how-does-it-work.component.html',
  styleUrls: ['./how-does-it-work.component.scss']
})
export class HowDoesItWorkComponent implements OnInit {
  selectedExplanationType: string = "For your Customers";
  explanationTypes: string[] = ['For your Customers', 'For your Business'];

  private _isLarge: boolean
  private _isMedium: boolean;
  private _isSmall: boolean;
  private _isXSmall: boolean;

  constructor(private readonly responsive: BreakpointObserver) {
    // Do nothing
  }

  ngOnInit(): void {
    // Do nothing
    this.responsive.observe([Breakpoints.XSmall, Breakpoints.Small, Breakpoints.Medium, Breakpoints.Large]).subscribe(
      result => {
        const breakpoints = result.breakpoints;

        this._isLarge = false;
        this._isMedium = false;
        this._isSmall = false;
        this._isXSmall = false;

        if (breakpoints[Breakpoints.Medium]) {
          this._isMedium = true;
        }
        else if (breakpoints[Breakpoints.Small]) {
          this._isSmall = true;
        }
        else if (breakpoints[Breakpoints.XSmall]) {
          this._isXSmall = true;
        }
        else {
          this._isLarge = true;
        }
      }
    );
  }

  getExplanationSteps(): ExplanationStep[] {
    const isForBusiness = this.selectedExplanationType === 'For your Business';
    return EXPLANATION_STEPS.filter(e => e.forBusiness === isForBusiness);
  }

  isLarge(): boolean {
    return this._isLarge;
  }

  isMedium(): boolean {
    return this._isMedium;
  }
  isSmall(): boolean {
    return this._isSmall;
  }
  isXSmall(): boolean {
    return this._isXSmall;
  }
}
