import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map, Observable, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { StoreUserModel } from '../models/store-user-model';
import { StoreUserPasswordModel } from '../models/store-user-password-model';

@Injectable({
  providedIn: 'root'
})
export class StoreUserService {
  constructor(private http: HttpClient) { }

  generateNewPassword(companyId: string, storeLocationId: number, storeUserId: string): Observable<StoreUserPasswordModel> {
    const httpOptions = {
      headers: new HttpHeaders({
        ContentType: 'application/json',
      })
    };

    const url = environment.apiUrl + '/api/partner/companies/' + companyId + '/storelocations/' + storeLocationId + '/users/' + storeUserId + "/password";
    const result = this.http.patch<StoreUserPasswordModel>(url, null, httpOptions);

    return result.pipe(
      map(data => this.mapFromStoreUserPassword(data)),
      catchError(
        this.handleError<StoreUserPasswordModel>('generateNewPassword', null)
      )
    );
  }


  getStoreUser(companyId: string, storeLocationId: number): Observable<StoreUserModel> {
    const httpOptions = {
      headers: new HttpHeaders({
        ContentType: 'application/json',
      })
    };

    const url = environment.apiUrl + '/api/partner/companies/' + companyId + '/storelocations/' + storeLocationId + '/users';
    const result = this.http.get<StoreUserModel>(url, httpOptions);

    return result.pipe(
      map(data => this.mapFromStoreUser(data)),
      catchError(
        this.handleError<StoreUserModel>('getStoreUser', null)
      )
    );
  }

  /**
    * Handle Http operation that failed.
    * Let the app continue.
    * @param operation - name of the operation that failed
    * @param result - optional value to return as the observable result
    */
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      console.error(error); // log to console instead
      // Let the app keep running by returning an empty result.
      return of(result);
    };
  }

  private mapFromStoreUser(data: StoreUserModel): StoreUserModel {
    const storeUser = new StoreUserModel();
    storeUser.id = data.id;
    storeUser.username = data.username;
    return storeUser;
  }

  private mapFromStoreUserPassword(data: StoreUserPasswordModel): StoreUserPasswordModel {
    const storeUserPassword = new StoreUserPasswordModel();
    storeUserPassword.id = data.id;
    storeUserPassword.password = data.password;
    return storeUserPassword;
  }
}
