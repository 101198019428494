import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { SpinnerDialogComponent } from 'src/app/core/components/dialogs/spinner-dialog/spinner-dialog.component';
import { ValidationException } from 'src/app/core/exceptions/validation-exception';
import { CompanyNameNotTakenValidator } from 'src/app/core/validators/company-name-not-taken.validator';
import { emailRegex, urlRegex } from 'src/app/core/validators/validation-regex';
import { BusinessAccountModel } from 'src/app/models/business-account-model';
import { UpdateCompanyModel } from 'src/app/models/update-company-model';
import { CompanyNameVerifyService } from 'src/app/services/company-name-verify.service';
import { SpinnerService } from 'src/app/services/spinner.service';
import { UpdateCompanyComponentStore } from 'src/app/stores/components/company-edit/update-company.component-store';
import { selectBusinessAccount, selectHasSubscriptionPlanOrder, selectUnpaidInvoice } from 'src/app/stores/global/app.selectors';

@Component({
  selector: 'app-company-page',
  templateUrl: './company-page.component.html',
  styleUrls: ['./company-page.component.scss']
})
export class CompanyPageComponent implements OnInit {
  public readonly minNameLength: number = 1;
  public readonly maxNameLength: number = 32;
  public readonly minDescriptionLength: number = 3;
  public readonly maxDescriptionLength: number = 256;
  public readonly minEmailLength: number = 3;
  public readonly maxEmailLength: number = 254;
  public readonly minUrlLength: number = 3;
  public readonly maxUrlLength: number = 254;

  businessAccount$: Observable<BusinessAccountModel> = this.store.select(selectBusinessAccount);
  unpaidInvoice$ = this.store.select(selectUnpaidInvoice);
  readMode: boolean = true;
  showPricingBanner: boolean;

  companyDetailsFormGroup: UntypedFormGroup;
  ngUnsubscribeState = new Subject<void>();

  private loadingSpinnerDialogRef: MatDialogRef<SpinnerDialogComponent>;

  constructor(
    private readonly formBuilder: UntypedFormBuilder,
    private readonly store: Store<any>,
    private readonly updateCompanyStore: UpdateCompanyComponentStore,
    private readonly snackBar: MatSnackBar,
    private readonly spinnerService: SpinnerService,
    private readonly router: Router,
    private readonly companyNameVerifyService: CompanyNameVerifyService
  ) { }

  ngOnInit(): void {
    this.store.select(selectBusinessAccount).pipe(filter(result => Boolean(result))).subscribe(businessAccount => {
      const gettingStartedCompanyDetails: boolean = !businessAccount.company.name || businessAccount.company.name === '';
      const gettingStartedCompanyLogo: boolean = !businessAccount.company.logo;
      if (gettingStartedCompanyDetails || gettingStartedCompanyLogo) {
        this.router.navigate(['/getting-started']);
      }
    });

    this.store.select(selectHasSubscriptionPlanOrder).pipe(takeUntil(this.ngUnsubscribeState)).subscribe((hasSubscriptionPlanOrder) => {
      if (hasSubscriptionPlanOrder) {
        this.showPricingBanner = false;
      }
      else {
        this.showPricingBanner = true;
      }
    });

    this.companyDetailsFormGroup = this.formBuilder.group({
      id: ['', Validators.required],
      companyName: ['', [
        Validators.required,
        Validators.minLength(this.minNameLength),
        Validators.maxLength(this.maxNameLength)],
        [CompanyNameNotTakenValidator.createValidator(this.companyNameVerifyService)]
      ],
      description: ['', [Validators.required, Validators.minLength(this.minDescriptionLength), Validators.maxLength(this.maxDescriptionLength)]],
      website: ['', [Validators.required, Validators.minLength(this.minUrlLength), Validators.maxLength(this.maxUrlLength), Validators.pattern(urlRegex)]],
      companyEmail: ['', [Validators.required, Validators.minLength(this.minEmailLength), Validators.maxLength(this.maxEmailLength), Validators.pattern(emailRegex)]]
    });

    this.updateCompanyStore.success$.pipe(takeUntil(this.ngUnsubscribeState)).subscribe((result) => {
      if (result) {
        this.snackBar.open("Company updated successfully", "Dismiss");
        this.onCancel(null);
      }
    });

    this.updateCompanyStore.errorMessage$.pipe(takeUntil(this.ngUnsubscribeState)).subscribe((errorMessage) => {
      if (errorMessage !== null && errorMessage !== undefined) {
        this.updateCompanyStore.setInitial();

        if (errorMessage instanceof ValidationException) {
          const validationViolationMessage = errorMessage.violations.map(v => v.message).join("\n");
          this.snackBar.open(validationViolationMessage, "Dismiss");
        }
        else {
          this.snackBar.open(errorMessage, "Dismiss");
        }
      }
    });

    this.updateCompanyStore.loaded$.pipe(takeUntil(this.ngUnsubscribeState), filter(result => result)).subscribe((loaded) => {
      this.hideSpinner(loaded);
    });
  }

  ngOnDestroy() {
    this.ngUnsubscribeState.next();
    this.ngUnsubscribeState.complete();
  }

  onEdit(event: any) {
    this.readMode = false;
  }

  onCancel(event: any) {
    this.readMode = true;
    // Reset the form data
  }

  onSave(event: any) {
    // Dispatch the save action
    if (this.companyDetailsFormGroup.valid) {
      this.showSpinner();

      const updateCompany = new UpdateCompanyModel();
      updateCompany.id = this.companyDetailsFormGroup.value.id;
      updateCompany.name = this.companyDetailsFormGroup.value.companyName;
      updateCompany.description = this.companyDetailsFormGroup.value.description;
      updateCompany.email = this.companyDetailsFormGroup.value.companyEmail;
      updateCompany.url = this.companyDetailsFormGroup.value.website;

      this.updateCompanyStore.updateCompany(updateCompany);
    }
  }

  private showSpinner() {
    this.loadingSpinnerDialogRef = this.spinnerService.show();
  }

  private hideSpinner(loaded: boolean) {
    if (loaded && this.loadingSpinnerDialogRef !== null) {
      this.spinnerService.hide(this.loadingSpinnerDialogRef);
      this.loadingSpinnerDialogRef = null;
    }
  }
}
