import { Injectable } from "@angular/core";
import { ComponentStore } from "@ngrx/component-store";
import { catchError, Observable, switchMap, tap } from "rxjs";
import { CompanySummariesService } from "src/app/services/company-summaries.service";
import { StatsActivePromotionChartsState } from "./stats-active-promotion-charts.state";
import { CompanyPromotionSummaryModel } from "src/app/models/company-promotion-summary-model";

const initialState: StatsActivePromotionChartsState = {
    loading: false,
    loaded: false,
    success: false,
    errorMessage: null,
    companyPromotionSummaries: null
}

@Injectable({
    providedIn: 'root'
})
export class StatsActivePromotionChartsComponentStore extends ComponentStore<StatsActivePromotionChartsState> {
    constructor(private readonly companySummariesServices: CompanySummariesService) {
        super(initialState);
    }

    readonly companyPromotionSummaries$: Observable<CompanyPromotionSummaryModel[]> = this.select(state => state.companyPromotionSummaries);
    readonly loading$: Observable<boolean> = this.select(state => state.loading);
    readonly loaded$: Observable<boolean> = this.select(state => state.loaded);
    readonly success$: Observable<boolean> = this.select(state => state.success);
    readonly errorMessage$: Observable<any> = this.select(state => state.errorMessage);

    readonly viewModel$ = this.select(
        this.companyPromotionSummaries$,
        this.loaded$,
        this.loading$,
        this.success$,
        this.errorMessage$,
        (companyPromotionSummaries, loaded, loading, success, errorMessage) => ({
            companyPromotionSummaries, loaded, loading, success, errorMessage
        })
    );

    readonly setInitial = this.updater((_: StatsActivePromotionChartsState) => {
        return {
            ...initialState
        };
    });

    readonly setLoading = this.updater((state: StatsActivePromotionChartsState) => {
        return {
            ...state,
            loading: true,
            loaded: false,
        };
    });

    readonly setLoaded = this.updater((state: StatsActivePromotionChartsState) => {
        return {
            ...state,
            loading: false,
            loaded: true,
        };
    });

    readonly updateStatsActivePromotionsChartState = this.updater((state: StatsActivePromotionChartsState, value: {
        companyPromotionSummaries: CompanyPromotionSummaryModel[]
    }) => {
        return {
            ...state,
            loading: false,
            loaded: true,
            success: true,
            companyPromotionSummaries : value.companyPromotionSummaries
        };
    });

    readonly setError = this.updater((state: StatsActivePromotionChartsState, value: {
        errorMessage: string
    }) => {
        return {
            ...state,
            loading: false,
            loaded: true,
            success: false,
            errorMessage: value.errorMessage,
        };
    });

    readonly getCompanyActivePromotionSummaries = this.effect((params$: Observable<{ companyId: string }>) => {
        this.setLoading();
        return params$.pipe(
            switchMap((params => this.companySummariesServices.getCompanyActivePromotionSummaries(params.companyId).pipe(
                tap({
                    next: (companyPromotionSummaries) => {
                        this.updateStatsActivePromotionsChartState({ companyPromotionSummaries: companyPromotionSummaries });
                        this.setLoaded();
                    },
                    error: (e) => {
                        throw e;
                    }
                }),
                catchError(async (error) => {
                    this.setError({ errorMessage: error })
                    this.setLoaded();
                    this.setInitial();
                })
            ))
            )
        );
    });
}