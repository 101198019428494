import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { RRule } from 'rrule';
import { PromotionModel } from 'src/app/models/promotion-model';

@Component({
  selector: 'app-promotion',
  templateUrl: './promotion.component.html',
  styleUrls: ['./promotion.component.scss']
})
export class PromotionComponent implements OnInit {
  @Input()
  promotion: PromotionModel;

  @Output()
  onDeletePressed = new EventEmitter();

  @Output()
  onActivatePressed = new EventEmitter();

  @Output()
  onDeactivatePressed = new EventEmitter();

  @Output()
  onEditPressed = new EventEmitter();

  recurrenceDisplayString : string;

  constructor() { 
    // Empty
  }

  ngOnInit(): void {
    // Empty
  }   

  ngOnChanges(changes : SimpleChanges) : void {
    if (this.promotion?.recurrencePattern) {
      this.recurrenceDisplayString = new RRule(RRule.parseString(this.promotion.recurrencePattern)).toText();
    }
    else {
      this.recurrenceDisplayString = 'Does not repeat';
    }
  }

  delete() {
    this.onDeletePressed.emit(null);
  }

  canShowActivateAction(): boolean {
    const result = this.promotion?.isInactive();
    return result;
  }

  canShowArchiveAction(): boolean {
    const result = this.promotion?.isActive() || this.promotion?.isPendingActivation();
    return result;
  }

  canShowEditAction() : boolean {
    const result = this.promotion?.isActive() || this.promotion?.isPendingActivation();
    return result;
  }

  activate() {
    this.onActivatePressed.emit(null);
  }

  deactivate() {
    this.onDeactivatePressed.emit(null);
  }

  edit() {
    this.onEditPressed.emit(null);
  }
}
