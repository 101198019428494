import { Injectable } from "@angular/core";
import { ComponentStore } from "@ngrx/component-store";
import { catchError, Observable, switchMap, tap } from "rxjs";
import { CompanySummariesService } from "src/app/services/company-summaries.service";
import { StatsActiveCampaignChartsState } from "./stats-active-campaign-charts.state";
import { CompanyCampaignSummaryModel } from "src/app/models/company-campaign-summary-model";

const initialState: StatsActiveCampaignChartsState = {
    loading: false,
    loaded: false,
    success: false,
    errorMessage: null,
    companyCampaignSummaries: null
}

@Injectable({
    providedIn: 'root'
})
export class StatsActiveCampaignChartsComponentStore extends ComponentStore<StatsActiveCampaignChartsState> {
    constructor(private readonly companySummariesServices: CompanySummariesService) {
        super(initialState);
    }

    readonly companyCampaignSummaries$: Observable<CompanyCampaignSummaryModel[]> = this.select(state => state.companyCampaignSummaries);
    readonly loading$: Observable<boolean> = this.select(state => state.loading);
    readonly loaded$: Observable<boolean> = this.select(state => state.loaded);
    readonly success$: Observable<boolean> = this.select(state => state.success);
    readonly errorMessage$: Observable<any> = this.select(state => state.errorMessage);

    readonly viewModel$ = this.select(
        this.companyCampaignSummaries$,
        this.loaded$,
        this.loading$,
        this.success$,
        this.errorMessage$,
        (companyCampaignSummaries, loaded, loading, success, errorMessage) => ({
            companyCampaignSummaries, loaded, loading, success, errorMessage
        })
    );

    readonly setInitial = this.updater((_: StatsActiveCampaignChartsState) => {
        return {
            ...initialState
        };
    });

    readonly setLoading = this.updater((state: StatsActiveCampaignChartsState) => {
        return {
            ...state,
            loading: true,
            loaded: false,
        };
    });

    readonly setLoaded = this.updater((state: StatsActiveCampaignChartsState) => {
        return {
            ...state,
            loading: false,
            loaded: true,
        };
    });

    readonly updateStatsActiveCampaignsChartState = this.updater((state: StatsActiveCampaignChartsState, value: {
        companyCampaignSummaries: CompanyCampaignSummaryModel[]
    }) => {
        return {
            ...state,
            loading: false,
            loaded: true,
            success: true,
            companyCampaignSummaries : value.companyCampaignSummaries
        };
    });

    readonly setError = this.updater((state: StatsActiveCampaignChartsState, value: {
        errorMessage: string
    }) => {
        return {
            ...state,
            loading: false,
            loaded: true,
            success: false,
            errorMessage: value.errorMessage,
        };
    });

    readonly getCompanyActiveCampaignSummaries = this.effect((params$: Observable<{ companyId: string }>) => {
        this.setLoading();
        return params$.pipe(
            switchMap((params => this.companySummariesServices.getCompanyActiveCampaignSummaries(params.companyId).pipe(
                tap({
                    next: (companyCampaignSummaries) => {
                        this.updateStatsActiveCampaignsChartState({ companyCampaignSummaries: companyCampaignSummaries });
                        this.setLoaded();
                    },
                    error: (e) => {
                        throw e;
                    }
                }),
                catchError(async (error) => {
                    this.setError({ errorMessage: error })
                    this.setLoaded();
                    this.setInitial();
                })
            ))
            )
        );
    });
}