import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, Input } from '@angular/core';
import { EChartsOption } from 'echarts';
import { CompanyCampaignSummaryModel } from 'src/app/models/company-campaign-summary-model';

@Component({
  selector: 'app-stats-active-campaign-chart',
  templateUrl: './stats-active-campaign-chart.component.html',
  styleUrls: ['./stats-active-campaign-chart.component.scss']
})
export class StatsActiveCampaignChartComponent {
  @Input()
  companyCampaignSummary: CompanyCampaignSummaryModel;

  options: EChartsOption;

  private _isLarge: boolean
  private _isMedium: boolean;
  private _isSmall: boolean;
  private _isXSmall: boolean;

  constructor(private responsive: BreakpointObserver,){
    // Do nothing
  }

  ngOnInit(): void {
    this._initializeChart(
      this.companyCampaignSummary.campaignName,
      this.companyCampaignSummary.totalParticipants,
      this.companyCampaignSummary.totalVouchers,
      this.companyCampaignSummary.totalClaimedVouchers);

      this.responsive.observe([Breakpoints.XSmall, Breakpoints.Small, Breakpoints.Medium, Breakpoints.Large]).subscribe(
        result => {
          const breakpoints = result.breakpoints;
  
          this._isLarge = false;
          this._isMedium = false;
          this._isSmall = false;
          this._isXSmall = false;
  
          if (breakpoints[Breakpoints.Medium]) {
            this._isMedium = true;
          }
          else if (breakpoints[Breakpoints.Small]) {
            this._isSmall = true;
          }
          else if (breakpoints[Breakpoints.XSmall]) {
            this._isXSmall = true;
          }
          else {
            this._isLarge = true;
          }
        }
      );
  }

  isLarge(): boolean {
    return this._isLarge;
  }

  isMedium(): boolean {
    return this._isMedium;
  }
  isSmall(): boolean {
    return this._isSmall;
  }
  isXSmall(): boolean {
    return this._isXSmall;
  }

  _initializeChart(name: string, totalParticipants: number, totalVouchers: number, totalClaimedVouchers: number) {
    this.options = {
      textStyle: { color: '#383838' },
      legend: {
        textStyle: { color: '#383838' },
      },
      tooltip: {},
      dataset: {
        // Provide a set of data.
        source: [
          ['campaign', 'Active Participants', 'Issued Vouchers', 'Redeemed Vouchers'],
          [name, totalParticipants, totalVouchers, totalClaimedVouchers],
        ],
      },
      // Declare an x-axis (category axis).
      // The category map the first column in the dataset by default.
      xAxis: { type: 'category' },
      // Declare a y-axis (value axis).
      yAxis: {
        splitLine: {
          lineStyle: {
            color: '#383838'
          }
        },
      },
      // Declare several 'bar' series,
      // every series will auto-map to each column by default.
      series: [
        { type: 'bar', color: '#383838' },  // Engagements
        { type: 'bar', color: '#2ca4e0' },  // Issued Vouchers
        { type: 'bar', color: '#d86caa' }   // Redeemed Vouchers
      ],
    };
  }
}
