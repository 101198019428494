import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { loadStripe } from '@stripe/stripe-js';
import { throwError } from 'rxjs';
import { PaymentInformationModel } from '../models/payment-information-model';
import { PaymentRequestModel } from '../models/payment-request-model';
import { environment } from './../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CheckoutService {
  stripePromise = loadStripe(environment.stripe_apiKey);

  constructor(private http: HttpClient) {

  }

  async checkoutShoppingCart(paymentRequest: PaymentRequestModel) {
    const httpOptions = {
      headers: new HttpHeaders({
        ContentType: 'application/json',
      }),
    };

    const url = environment.apiUrl + '/api/partner/payments';
    const paymentInformation$ = this.http.post<PaymentInformationModel>(url, paymentRequest, httpOptions);

    paymentInformation$.subscribe({
      next: async (paymentInformation) => {
        // Call your backend to create the Checkout session.
        // When the customer clicks on the button, redirect them to Checkout.
        const stripe = await this.stripePromise;
        const { error } = await stripe.redirectToCheckout({
          sessionId: paymentInformation.sessionId,
        });
        // If `redirectToCheckout` fails due to a browser or network
        // error, display the localized error message to your customer
        // using `error.message`.
        if (error) {
          console.log(error);
        }
      },
      error: (error) => {
        this.handleError(error);
      }
    });
  }

  redeemCode() {
    // Check if we can redeem a promo code
  }

  handleError(error) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // client-side error
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    window.alert(errorMessage);
    return throwError(() => errorMessage);
  }
}
