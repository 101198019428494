import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { ActiveSubscriptionPlanOrderModel } from 'src/app/models/active-subscription-plan-order-model';

@Component({
  selector: 'app-current-subscriptionplan-order',
  templateUrl: './current-subscriptionplan-order.component.html',
  styleUrls: ['./current-subscriptionplan-order.component.scss']
})
export class CurrentSubscriptionplanOrderComponent {
  ngUnsubscribeState = new Subject<void>();

  @Input()
  currentSubscriptionPlanOrder : ActiveSubscriptionPlanOrderModel;
  
  hasUnpaidInvoice : boolean;
  
  constructor(
    private router : Router, 
    private store: Store,
    ){}

  ngOnInit(): void {
  }

  ngOnDestroy(){
    this.ngUnsubscribeState.next();
    this.ngUnsubscribeState.complete();
  }

  public goToPaymentProviderCustomerPortal() : void {
    this.router.navigate(['/billing/portal']);
  }
}
