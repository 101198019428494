import { CampaignRewardModel } from "./campaign-reward-model";
import { CompanyModel } from "./company-model"

export const CampaignStatusPendingActivation = "PENDING_ACTIVATION";
export const CampaignStatusInactive = "INACTIVE";
export const CampaignStatusActive = "ACTIVE";
export const CampaignStatusExpired = "EXPIRED";
export const CampaignStatusArchived = "ARCHIVED";

export const VoucherDurationP1M = "1 Month";
export const VoucherDurationP3M = "3 Months";
export const VoucherDurationP6M = "6 Months";
export const VoucherDurationP1Y = "1 Year";
export const VoucherDurationP2Y = "2 Years";
export const VoucherDurationP3Y = "3 Years";

export class CampaignModel {
    id: number;
    company: CompanyModel;
    name: string;
    description: string;
    startDate: Date;
    endDate: Date;
    stampsObjective: number;
    campaignReward: CampaignRewardModel;
    voucherDuration: string;
    campaignStatus : string;
    recurrencePattern : string;

    public isPendingActivation() : boolean {
        return this.campaignStatus == CampaignStatusPendingActivation;
    }

    public isInactive(): boolean {
        return this.campaignStatus == CampaignStatusInactive;
    }

    public isActive() : boolean {
        return this.campaignStatus == CampaignStatusActive;
    }

    public isExpired() : boolean {
        return this.campaignStatus == CampaignStatusExpired;
    }

    public isArchived() : boolean {
        return this.campaignStatus == CampaignStatusArchived;
    }

    public toVoucherDurationDisplayString(): string {
        switch (this.voucherDuration) {
            case "P1M":
                return VoucherDurationP1M;
            case "P3M":
                return VoucherDurationP3M;
            case "P6M":
                return VoucherDurationP6M;
            case "P1Y":
                return VoucherDurationP1Y;
            case "P2Y":
                return VoucherDurationP2Y;
            case "P3Y":
                return VoucherDurationP3Y;
            default:
                throw new Error('Invalid VoucherDuration');
        }
    }
}

