import { Component, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { RRule } from 'rrule';
import { Subject, takeUntil } from 'rxjs';
import { ArrayUtils } from 'src/app/core/utilities/array-utils';

interface RecurrenceYearlyOnMonthRule {
  value: string;
  viewValue: string;
}

interface RecurrenceYearlyOnMonthWhichPositionRule {
  value: string;
  viewValue: string;
}

interface RecurrenceYearlyOnMonthWhichDayRule {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'app-yearly-repeat-rule',
  templateUrl: './yearly-repeat-rule.component.html',
  styleUrls: ['./yearly-repeat-rule.component.scss']
})
export class YearlyRepeatRuleComponent {
  @Input()
  yearlyRepeatRuleFormGroup: UntypedFormGroup;

  @Input()
  rrule: RRule;

  ngUnsubscribeState = new Subject<void>();

  recurrenceYearlyOnMonths: RecurrenceYearlyOnMonthRule[] = [
    { value: 'jan', viewValue: 'Jan' },
    { value: 'feb', viewValue: 'Feb' },
    { value: 'mar', viewValue: 'Mar' },
    { value: 'apr', viewValue: 'Apr' },
    { value: 'may', viewValue: 'May' },
    { value: 'jun', viewValue: 'Jun' },
    { value: 'jul', viewValue: 'Jul' },
    { value: 'aug', viewValue: 'Aug' },
    { value: 'sep', viewValue: 'Sep' },
    { value: 'oct', viewValue: 'Oct' },
    { value: 'nov', viewValue: 'Nov' },
    { value: 'dec', viewValue: 'Dec' },
  ];

  recurrenceYearlyOnMonthWhichPositions: RecurrenceYearlyOnMonthWhichPositionRule[] = [
    { value: "first", viewValue: "First" },
    { value: "second", viewValue: "Second" },
    { value: "third", viewValue: "Third" },
    { value: "fourth", viewValue: "Fourth" },
    { value: "last", viewValue: "Last" },
  ];

  recurrenceYearlyOnMonthWhichDays: RecurrenceYearlyOnMonthWhichDayRule[] = [
    { value: "monday", viewValue: "Monday" },
    { value: "tuesday", viewValue: "Tuesday" },
    { value: "wednesday", viewValue: "Wednesday" },
    { value: "thursday", viewValue: "Thursday" },
    { value: "friday", viewValue: "Friday" },
    { value: "saturday", viewValue: "Saturday" },
    { value: "sunday", viewValue: "Sunday" },
    { value: "weekday", viewValue: "Weekday" },
    { value: "weekendday", viewValue: "Weekend day" },
  ];

  weekDayIndex = 7;
  weekendDayIndex = 8;

  public ngOnInit(): void {
    this.yearlyRepeatRuleFormGroup.controls.onYearlyChoice.valueChanges.pipe(takeUntil(this.ngUnsubscribeState)).subscribe(value => {
      if (value === 'on-day-of-month') {
        this.setStateOnDayControls(true);
        this.setStateOnWhichDayControls(false);
      }
      else if (value === 'on-which-position-day-of-month') {
        this.setStateOnDayControls(false);
        this.setStateOnWhichDayControls(true);
      }
    });

    if (this.rrule) {
      if (this.rrule.options.bymonth 
        && this.rrule.options.bymonth .length > 0
        && this.rrule.options.bymonthday
        && this.rrule.options.bymonthday.length > 0
      ) {
        this.yearlyRepeatRuleFormGroup.patchValue({ 'onYearlyChoice': 'on-day-of-month' });
        this.yearlyRepeatRuleFormGroup.patchValue({ 'recurrenceRepeatRuleYearlyOnMonthMonth': this.getByMonth() });
        this.yearlyRepeatRuleFormGroup.patchValue({ 'recurrenceRepeatRuleYearlyOnMonthDay': this.getByMonthDay() });
      }
      else if (this.rrule.options.bysetpos
        && this.rrule.options.bysetpos.length > 0
        && this.rrule.options.bynweekday
        && this.rrule.options.bynweekday.length > 0
        && this.rrule.options.bymonth
        && this.rrule.options.bymonth.length > 0

      ) {
        this.yearlyRepeatRuleFormGroup.patchValue({ 'onYearlyChoice': 'on-which-position-day-of-month' });
        this.yearlyRepeatRuleFormGroup.patchValue({ 'recurrenceRepeatRuleYearlyOnWhichDayPositionMonth': this.getByWhichDayPosition() });
        this.yearlyRepeatRuleFormGroup.patchValue({ 'recurrenceRepeatRuleYearlyOnWhichDayMonth': this.getByWhichDay() });
        this.yearlyRepeatRuleFormGroup.patchValue({ 'recurrenceRepeatRuleYearlyOnWhichMonthMonth': this.getByMonth() });
      }
    }
  }

  public ngOnDestroy(): void {
    this.ngUnsubscribeState.next();
    this.ngUnsubscribeState.complete();
  }

  public dayRange = () => ArrayUtils.range(1, 32);

  private setStateOnDayControls(enable: boolean) {
    if (enable) {
      this.yearlyRepeatRuleFormGroup.controls.recurrenceRepeatRuleYearlyOnMonthMonth.enable();
      this.yearlyRepeatRuleFormGroup.controls.recurrenceRepeatRuleYearlyOnMonthDay.enable();
    }
    else {
      this.yearlyRepeatRuleFormGroup.controls.recurrenceRepeatRuleYearlyOnMonthMonth.disable();
      this.yearlyRepeatRuleFormGroup.controls.recurrenceRepeatRuleYearlyOnMonthDay.disable();
    }
  }

  private setStateOnWhichDayControls(enable: boolean) {
    if (enable) {
      this.yearlyRepeatRuleFormGroup.controls.recurrenceRepeatRuleYearlyOnWhichDayPositionMonth.enable();
      this.yearlyRepeatRuleFormGroup.controls.recurrenceRepeatRuleYearlyOnWhichDayMonth.enable();
      this.yearlyRepeatRuleFormGroup.controls.recurrenceRepeatRuleYearlyOnWhichMonthMonth.enable();

    }
    else {
      this.yearlyRepeatRuleFormGroup.controls.recurrenceRepeatRuleYearlyOnWhichDayPositionMonth.disable();
      this.yearlyRepeatRuleFormGroup.controls.recurrenceRepeatRuleYearlyOnWhichDayMonth.disable();
      this.yearlyRepeatRuleFormGroup.controls.recurrenceRepeatRuleYearlyOnWhichMonthMonth.disable();
    }
  }

  private getByMonth() : string {
    // We only support a single month
    return this.recurrenceYearlyOnMonths[this.rrule.options.bymonth[0] - 1].value;
  }

  private getByMonthDay() : string {
    // We only support a single month
    return this.rrule.options.bymonthday[0].toString();  
  }

  private getByWhichDayPosition() : string {
    // We only support a single position
    return this.recurrenceYearlyOnMonthWhichPositions[this.rrule.options.bysetpos[0] - 1].value;
  }

  private getByWhichDay() : string {
    // We only support a single day/weekday/weekendday 
    if(this.isSingleDay()){
      return this.recurrenceYearlyOnMonthWhichDays[this.rrule.options.bynweekday[0][0]].value;
    }
    else if(this.isSingleWeekDay()){
      return this.recurrenceYearlyOnMonthWhichDays[this.weekDayIndex].value;
    }
    else if(this.isSingleWeekendDay()){
      return this.recurrenceYearlyOnMonthWhichDays[this.weekendDayIndex].value;
    }
    else {
      throw new RangeError("Unspported day.");
    }
  }

  private isSingleDay() : boolean {
    return this.rrule.options.bynweekday.length == 1;
  }

  private isSingleWeekDay() : boolean {
    return this.rrule.options.bynweekday.length == 5;
  }

  private isSingleWeekendDay() : boolean {
    return this.rrule.options.bynweekday.length == 2;
  }
}
