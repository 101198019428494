import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { AbstractSecurityStorage, AuthInterceptor, AuthModule, DefaultLocalStorageService, LogLevel } from 'angular-auth-oidc-client';
import { environment } from 'src/environments/environment';

@NgModule({
    imports: [AuthModule.forRoot({
        config: {
             authority : environment.stsServer,
              redirectUrl: window.location.origin + '/oidc-callback',
              postLogoutRedirectUri: window.location.origin,
              clientId: 'planetvg-business-loyalty-portal',
              scope: 'openid profile offline_access ',
              responseType: 'code',
              silentRenew: true,
              useRefreshToken: true,
              autoUserInfo: false,
              renewTimeBeforeTokenExpiresInSeconds: 30,
              ignoreNonceAfterRefresh: true, // this is required if the id_token is not returned
              triggerRefreshWhenIdTokenExpired: false, // required when refreshing the browser if id_token is not updated after the first authentication
              logLevel: LogLevel.Warn,
              secureRoutes: [environment.apiUrl],
              startCheckSession: true
              
        }
    })],
    exports: [AuthModule],
    providers: [
        { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
        // Use the local shared storage instead of an isolated session storage
        {
            provide: AbstractSecurityStorage,
            useClass: DefaultLocalStorageService,
          },
      ],
})
export class AuthConfigModule {}
