import { CompanyModel } from "./company-model";
import { PromotionRewardModel } from "./promotion-reward-model";

const PromotionStatusPendingActivation = "PENDING_ACTIVATION";
const PromotionStatusInactive = "INACTIVE";
const PromotionStatusActive = "ACTIVE";
const PromotionStatusExpired = "EXPIRED";
const PromotionStatusArchived = "ARCHIVED";

export class PromotionModel {
    id: number;
    company: CompanyModel;
    name: string;
    description: string;
    startDate: Date;
    endDate: Date;
    promotionReward: PromotionRewardModel;
    startDateVoucher: Date;
    endDateVoucher: Date;
    promotionStatus : string;
    recurrencePattern : string;

    public isInactive() : boolean {
        return this.promotionStatus == PromotionStatusInactive;
    }

    public isPendingActivation() : boolean {
        return this.promotionStatus == PromotionStatusPendingActivation;
    }

    public isActive() : boolean {
        return this.promotionStatus == PromotionStatusActive;
    }

    public isExpired() : boolean {
        return this.promotionStatus == PromotionStatusExpired;
    }

    public isArchived() : boolean {
        return this.promotionStatus == PromotionStatusArchived;
    }
}