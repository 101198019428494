import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface DialogData {
  title: string;
  message: string;
  data: string;
  confirm: string;
  cancel: string;
}

@Component({
  selector: 'app-acknowledge-dialog',
  templateUrl: './acknowledge-dialog.component.html',
  styleUrls: ['./acknowledge-dialog.component.scss']
})
export class AcknowledgeDialogComponent {

  constructor(
    public dialogRef: MatDialogRef<AcknowledgeDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {
    if (data.confirm === null || data.confirm === undefined) {
      data.confirm = "Ok";
    }
  }

  onConfirmClick(): void {
    this.dialogRef.close(true);
  }
}
