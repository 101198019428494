import { Component, Inject } from '@angular/core';
import { FormGroup, UntypedFormBuilder } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { RRule } from 'rrule';
import { RRuleUtils } from 'src/app/core/utilities/rrule-utils';

export interface RecurrenceDialogData {
  title: string;
  startDate : Date;
  recurrencePattern: string;
  confirm: string;
  cancel: string;
}

export class RecurrenceDialogDataResult {
  recurrencePatternDisplayText : string;
  recurrencePattern: string;
  recurrenceRule: RRule;
}

@Component({
  selector: 'app-recurrence-dialog',
  templateUrl: './recurrence-dialog.component.html',
  styleUrls: ['./recurrence-dialog.component.scss']
})
export class RecurrenceDialogComponent {
  recurrenceRuleFormGroup: FormGroup;
  recurrenceRule : RRule;

  constructor(
    public dialogRef: MatDialogRef<RecurrenceDialogComponent>,
    private formBuilder: UntypedFormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: RecurrenceDialogData
  ) {
    if (data.confirm === null || data.confirm === undefined) {
      data.confirm = "Done";
    }
  }

  ngOnInit(): void {
    this.recurrenceRuleFormGroup = this.formBuilder.group({
      recurrenceRepeatRuleFrequency: [''],
      recurrenceRepeatRuleInterval: [''],

      recurrenceRepeatRuleWeeklyDays: [''],

      onMonthlyChoice: [''],
      recurrenceRepeatRuleMonthlyOnDay: [''],

      recurrenceRepeatRuleMonthlyOnWhichDayPosition: [''],
      recurrenceRepeatRuleMonthlyOnWhichDayDay: [''],

      onYearlyChoice: [''],
      recurrenceRepeatRuleYearlyOnMonthMonth: [''],
      recurrenceRepeatRuleYearlyOnMonthDay: [''],

      recurrenceRepeatRuleYearlyOnWhichDayPositionMonth: [''],
      recurrenceRepeatRuleYearlyOnWhichDayMonth: [''],
      recurrenceRepeatRuleYearlyOnWhichMonthMonth: [''],

      recurrenceEndRule: [''],
      recurrenceEndRuleOccurrences: [''],
      recurrenceEndRuleEndDate: [''],

    });

    if(this.data.recurrencePattern){
      this.recurrenceRule = new RRule(RRule.parseString(this.data.recurrencePattern));
    }
  }

  onCancelClick(): void {
    this.data.recurrencePattern = null;
    this.dialogRef.close(null);
  }

  onConfirmClick(): void {
    const rrule = this.computeRecurrenceRule();
    if (rrule) {
      const result = new RecurrenceDialogDataResult();
      result.recurrenceRule = rrule;
      result.recurrencePattern = rrule.toString();
      result.recurrencePatternDisplayText = rrule.toText();

      this.dialogRef.close(result);
    }
    else {
      throw new Error('Recurrence Rules not valid.');
    }
  }

  private computeRecurrenceRule(): RRule {
    if (!this.recurrenceRuleFormGroup.valid) {
      return null;
    }

    const repeatRuleFrequency = this.recurrenceRuleFormGroup.controls.recurrenceRepeatRuleFrequency.value;
    const repeatRuleInterval = this.recurrenceRuleFormGroup.controls.recurrenceRepeatRuleInterval.value;

    const repeatRuleWeeklyDays = this.recurrenceRuleFormGroup.controls.recurrenceRepeatRuleWeeklyDays.value;

    const onMonthlyChoice = this.recurrenceRuleFormGroup.controls.onMonthlyChoice.value;
    const repeatRuleMonthlyOnDay = this.recurrenceRuleFormGroup.controls.recurrenceRepeatRuleMonthlyOnDay.value;

    const repeatRuleMonthlyOnWhichDayPosition = this.recurrenceRuleFormGroup.controls.recurrenceRepeatRuleMonthlyOnWhichDayPosition.value;
    const repeatRuleMonthlyOnWhichDayDay = this.recurrenceRuleFormGroup.controls.recurrenceRepeatRuleMonthlyOnWhichDayDay.value;

    const onYearlyChoice = this.recurrenceRuleFormGroup.controls.onYearlyChoice.value;
    const repeatRuleYearlyOnMonthDay = this.recurrenceRuleFormGroup.controls.recurrenceRepeatRuleYearlyOnMonthDay.value;
    const repeatRuleYearlyOnMonthMonth = this.recurrenceRuleFormGroup.controls.recurrenceRepeatRuleYearlyOnMonthMonth.value;

    const repeatRuleYearlyOnWhichDayMonth = this.recurrenceRuleFormGroup.controls.recurrenceRepeatRuleYearlyOnWhichDayMonth.value;
    const repeatRuleYearlyOnWhichDayPositionMonth = this.recurrenceRuleFormGroup.controls.recurrenceRepeatRuleYearlyOnWhichDayPositionMonth.value;
    const repeatRuleYearlyOnWhichMonthMonth = this.recurrenceRuleFormGroup.controls.recurrenceRepeatRuleYearlyOnWhichMonthMonth.value;

    const endRule = this.recurrenceRuleFormGroup.controls.recurrenceEndRule.value;
    const endRuleOccurrences = this.recurrenceRuleFormGroup.controls.recurrenceEndRuleOccurrences.value;
    const endRuleEndDate = this.recurrenceRuleFormGroup.controls.recurrenceEndRuleEndDate.value;


    const rruleOptions = {
      ...RRuleUtils.computeRepeatRule(
        repeatRuleFrequency,
        repeatRuleInterval,
        repeatRuleWeeklyDays,
        onMonthlyChoice,
        repeatRuleMonthlyOnDay,
        repeatRuleMonthlyOnWhichDayPosition,
        repeatRuleMonthlyOnWhichDayDay,
        onYearlyChoice,
        repeatRuleYearlyOnMonthDay,
        repeatRuleYearlyOnMonthMonth,
        repeatRuleYearlyOnWhichDayMonth,
        repeatRuleYearlyOnWhichDayPositionMonth,
        repeatRuleYearlyOnWhichMonthMonth
      ),
      ...RRuleUtils.computeEndRule(endRule, endRuleOccurrences, endRuleEndDate),
      ...RRuleUtils.computeExtraOptions(false, false, false),
    };

    return new RRule(rruleOptions);
  }
}
