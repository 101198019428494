<div class="container">
  <div class="container-content">
    <h1>Climate Pledge</h1>
    <div class="banner-content">
      <h2>
        Did you know that a plant-based diet is one of the most effective ways
        to reduce CO2 emissions?
      </h2>
      <p>
        Eating a <strong>plant-based diet</strong> saves about
        <strong>4 times</strong> more greenhouse gas emissions per year than
        recycling. Avoiding just <strong>one transatlantic flight</strong> saves
        <strong>8 times</strong> more, and
        <strong>living car-free</strong> saves <strong>11 times</strong> more.
        Similarly, switching from plastic to canvas bags is less than 1% as
        effective for the climate as a year without consuming meat.
      </p>

      <p>
        Reducing CO2 emissions and also reducing animal suffering at the same time? Yes, we can
        have both! Because these two go hand-in-hand.
      </p>

      <p>
        Source:
        <a href="https://www.lunduniversity.lu.se/article/four-lifestyle-choices-most-reduce-your-carbon-footprint"
          target="_blank" rel="noopener">The four lifestyle choices that most reduce your carbon footprint</a>
      </p>
    </div>
    <div class="container-divider">
      <img src="/assets/img/planetvg_icon.png" width="48px" alt="planetvg-logo" />
    </div>
    <div class="banner-content">
      <h2>
        Did you know that the internet is the world's largest coal-powered
        machine?
      </h2>
      <p>
        The internet is one of the most carbon-polluting industries on the
        planet, consuming an estimated <strong>416.2TWh</strong> of energy per
        year* - more than the entire United Kingdom.
      </p>
      <p>
        We believe that the internet must be fossil-free by 2030. And to
        facilitate that journey we run our servers on renewable energy.<br />
        We have partnered with <a href="https://www.netcup.eu/ueber-netcup/oekostrom.php"  target="_blank" rel="noopener">NetCup, GmbH</a>. Their
        datacenter runs on <a href="https://www.netcup.eu/ueber-netcup/oekostrom.php"  target="_blank" rel="noopener">hydroelectricity</a>.

      </p>
      <div class="container-climate-badge">
        <img src="./assets/img/climate/greenelectricity.svg"
          alt="This website runs on green electicity provided by NetCup, gmbh." width="200px" height="95px" />
      </div>
    </div>
    <div class="container-references">
      <p>
        * Source <a href="https://www.websitecarbon.com/" target="_blank" rel="noopener">Website Carbon</a>
      </p>
    </div>
  </div>
  <app-bottom-menu></app-bottom-menu>
</div>