import { Injectable } from "@angular/core";
import { ComponentStore } from "@ngrx/component-store";
import { catchError, Observable, switchMap, tap } from "rxjs";
import { CompanySummariesService } from "src/app/services/company-summaries.service";
import { StatsTotalStoreLocationsChartState } from "./stats-total-store-locations-chart.state";
import { CompanyStoreLocationSummaryModel } from "src/app/models/company-store-location-summary-model";

const initialState: StatsTotalStoreLocationsChartState = {
    loading: false,
    loaded: false,
    success: false,
    errorMessage: null,
    companyStoreLocationSummary: null
}

@Injectable({
    providedIn: 'root'
})
export class StatsTotalStoreLocationsChartComponentStore extends ComponentStore<StatsTotalStoreLocationsChartState> {
    constructor(private readonly companySummariesServices: CompanySummariesService) {
        super(initialState);
    }

    readonly companyStoreLocationSummary$: Observable<CompanyStoreLocationSummaryModel> = this.select(state => state.companyStoreLocationSummary);
    readonly loading$: Observable<boolean> = this.select(state => state.loading);
    readonly loaded$: Observable<boolean> = this.select(state => state.loaded);
    readonly success$: Observable<boolean> = this.select(state => state.success);
    readonly errorMessage$: Observable<any> = this.select(state => state.errorMessage);

    readonly viewModel$ = this.select(
        this.companyStoreLocationSummary$,
        this.loaded$,
        this.loading$,
        this.success$,
        this.errorMessage$,
        (companyStoreLocationSummary, loaded, loading, success, errorMessage) => ({
            companyStoreLocationSummary, loaded, loading, success, errorMessage
        })
    );

    readonly setInitial = this.updater((_: StatsTotalStoreLocationsChartState) => {
        return {
            ...initialState
        };
    });

    readonly setLoading = this.updater((state: StatsTotalStoreLocationsChartState) => {
        return {
            ...state,
            loading: true,
            loaded: false,
        };
    });

    readonly setLoaded = this.updater((state: StatsTotalStoreLocationsChartState) => {
        return {
            ...state,
            loading: false,
            loaded: true,
        };
    });

    readonly updateStatsTotalMembersChartState = this.updater((state: StatsTotalStoreLocationsChartState, value: {
        companyStoreLocationSummary: CompanyStoreLocationSummaryModel
    }) => {
        return {
            ...state,
            loading: false,
            loaded: true,
            success: true,
            companyStoreLocationSummary : value.companyStoreLocationSummary
        };
    });

    readonly setError = this.updater((state: StatsTotalStoreLocationsChartState, value: {
        errorMessage: string
    }) => {
        return {
            ...state,
            loading: false,
            loaded: true,
            success: false,
            errorMessage: value.errorMessage,
        };
    });

    readonly getCompanyStoreLocationSummary = this.effect((params$: Observable<{ companyId: string }>) => {
        this.setLoading();
        return params$.pipe(
            switchMap((params => this.companySummariesServices.getCompanyStoreLocationSummary(params.companyId).pipe(
                tap({
                    next: (companyStoreLocationSummary) => {
                        this.updateStatsTotalMembersChartState({ companyStoreLocationSummary: companyStoreLocationSummary });
                        this.setLoaded();
                    },
                    error: (e) => {
                        throw e;
                    }
                }),
                catchError(async (error) => {
                    this.setError({ errorMessage: error })
                    this.setLoaded();
                    this.setInitial();
                })
            ))
            )
        );
    });
}