import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { Observable, of } from 'rxjs';
import { businessAccountGetAction, clearSessionAction } from 'src/app/stores/global/app.actions';

@Component({
  selector: 'app-checkout-success',
  templateUrl: './checkout-success.component.html',
  styleUrls: ['./checkout-success.component.scss']
})
export class CheckoutSuccessComponent implements OnInit {
  timeLeft: number = 30;
  timerInterval: any;
  showNavigateButton$: Observable<boolean>;

  private _isXLarge : boolean;
  private _isLarge: boolean
  private _isMedium: boolean;
  private _isSmall: boolean;
  private _isXSmall : boolean;


  constructor(
    private readonly router: Router, 
    private readonly store: Store, 
    private readonly oidcSecurityService : OidcSecurityService, 
    private readonly responsive: BreakpointObserver) {
    // Do nothing
  }

  ngOnInit(): void {
    this.startTimer();

    this.responsive.observe([Breakpoints.XSmall, Breakpoints.Small, Breakpoints.Medium, Breakpoints.Large, Breakpoints.XLarge]).subscribe(
      result => {
        const breakpoints = result.breakpoints;

        this._isXLarge = false;
        this._isLarge = false;
        this._isMedium = false;
        this._isSmall = false;
        this._isXSmall = false;

        if (breakpoints[Breakpoints.Medium]) {
          this._isMedium = true;
        }
        else if (breakpoints[Breakpoints.Small]) {
          this._isSmall = true;
        }
        else if (breakpoints[Breakpoints.XSmall]) {
          this._isXSmall = true;
        }
        else if (breakpoints[Breakpoints.Large]) {
          this._isLarge = true;
        }
        else {
          this._isXLarge = true;
        }
      }
    );
  }

  isXLarge(): boolean {
    return this._isXLarge;
  }

  isLarge(): boolean {
    return this._isLarge;
  }

  isMedium(): boolean {
    return this._isMedium;
  }
  isSmall(): boolean{
    return this._isSmall;
  }
  isXSmall(): boolean{
    return this._isXSmall;
  }

  startTimer(): void {
    this.timerInterval = setInterval(() => {
      if (this.timeLeft > 0) {
        this.timeLeft--;
      } else {
        clearInterval(this.timerInterval);
        this.showNavigateButton$ = of(true);
        this.store.dispatch(clearSessionAction());
        this.store.dispatch(businessAccountGetAction());
        this.router.navigate(['/dashboard']);
        // HACK: Refresh the session, forceRefreshSession, uses the persisted token and doesn't trigger the userdata changed event.          
        this.oidcSecurityService.authorize(null, { customParams: { prompt: 'none' } });
      }
    }, 1000)
  }

  navigateManually(): void {
    this.store.dispatch(clearSessionAction());
    this.store.dispatch(businessAccountGetAction());
    this.router.navigate(['/dashboard']);
    // HACK: Refresh the session, forceRefreshSession, uses the persisted token and doesn't trigger the userdata changed event.          
    this.oidcSecurityService.authorize(null, { customParams: { prompt: 'none' } });
  }
}
