import { Injectable } from "@angular/core";
import { ComponentStore } from "@ngrx/component-store";
import { Store } from "@ngrx/store";
import { Observable } from "rxjs";
import { catchError, switchMap, tap } from "rxjs/operators";
import { CompanyModel } from "src/app/models/company-model";
import { UpdateCompanyModel } from "src/app/models/update-company-model";
import { CompanyService } from "src/app/services/company.service";
import { businessAccountGetAction } from "../../global/app.actions";
import { UpdateCompanyState } from "./update-company.state";

const initialState: UpdateCompanyState = {
    loading: false,
    loaded: false,
    success: false,
    errorMessage: null,
    updateCompany: null,
    company: null
};

@Injectable({
    providedIn: 'root'
})
export class UpdateCompanyComponentStore extends ComponentStore<UpdateCompanyState> {

    constructor(private store: Store<any>, private readonly companyService: CompanyService) {
        super(initialState);
    }

    readonly updateCompanyModel$: Observable<UpdateCompanyModel> = this.select(state => state.updateCompany);
    readonly loading$: Observable<boolean> = this.select(state => state.loading);
    readonly loaded$: Observable<boolean> = this.select(state => state.loaded);
    readonly success$: Observable<boolean> = this.select(state => state.success);
    readonly errorMessage$: Observable<any> = this.select(state => state.errorMessage);

    readonly viewModel$ = this.select(
        this.updateCompanyModel$,
        this.loaded$,
        this.loading$,
        this.success$,
        this.errorMessage$,
        (updateCompany, loaded, loading, success, errorMessage) => ({
            updateCompany, loaded, loading, success, errorMessage
        })
    );

    readonly setInitial = this.updater(( _ : UpdateCompanyState) => {
        return {
            ...initialState
        };
    });

    readonly setLoading = this.updater((state: UpdateCompanyState) => {
        return {
            ...state,
            loading: true,
            loaded: false,
        };
    });

    readonly setLoaded = this.updater((state: UpdateCompanyState) => {
        return {
            ...state,
            loading: false,
            loaded: true,
        };
    });

    readonly updateCompanyState = this.updater((state: UpdateCompanyState, value: {
        company: CompanyModel
    }) => {
        return {
            ...state,
            loading: false,
            loaded: true,
            success: true,
            company: value.company
        };
    });

    readonly setError = this.updater((state: UpdateCompanyState, value: {
        errorMessage: String
    }) => {
        return {
            ...state,
            loading: false,
            loaded: true,
            success: false,
            errorMessage: value.errorMessage,
        };
    });

    readonly updateCompany = this.effect((updateCompanyParams$: Observable<UpdateCompanyModel>) => {
        this.setLoading();
        return updateCompanyParams$.pipe(
            switchMap((params => this.companyService.updateCompany(params).pipe(
                tap({
                    next: (company) => {
                        this.store.dispatch(businessAccountGetAction());
                        this.updateCompanyState({ company: company });
                        this.setLoaded();
                        this.setInitial();
                    },
                    error: (e) => { 
                        throw e;
                    }
                }),
                catchError(async (error) => {
                    this.setError({ errorMessage: error })
                    this.setLoaded();
                    this.setInitial();
                })
            ))
            )
        );
    });
}