import { Component } from '@angular/core';

@Component({
  selector: 'app-pricing-faq',
  templateUrl: './pricing-faq.component.html',
  styleUrls: ['./pricing-faq.component.scss']
})
export class PricingFaqComponent {

}
