import { Injectable } from "@angular/core";
import { ComponentStore } from "@ngrx/component-store";
import { Observable } from "rxjs";
import { catchError, map, switchMap } from "rxjs/operators";
import { PromotionModel } from "src/app/models/promotion-model";
import { CreatePromotionModel } from "src/app/models/create-promotion-model";
import { PromotionService } from "src/app/services/promotion.service";
import { CreatePromotionState } from "./create-promotion.state";

const initialState: CreatePromotionState = {
    loading: false,
    loaded: false,
    success: false,
    errorMessage: null,
    createPromotionModel: null,
    promotion: null,
};

@Injectable({
    providedIn: 'root'
})
export class CreatePromotionComponentStore extends ComponentStore<CreatePromotionState> {

    constructor(private readonly promotionService: PromotionService) {
        super(initialState);
    }

    readonly createPromotionModel$: Observable<CreatePromotionModel> = this.select(state => state.createPromotionModel);
    readonly promotion$: Observable<PromotionModel> = this.select(state => state.promotion);
    readonly loading$: Observable<boolean> = this.select(state => state.loading);
    readonly loaded$: Observable<boolean> = this.select(state => state.loaded);
    readonly success$: Observable<boolean> = this.select(state => state.success);
    readonly errorMessage$: Observable<any> = this.select(state => state.errorMessage);

    readonly viewModel$ = this.select(
        this.createPromotionModel$,
        this.promotion$,
        this.loaded$,
        this.loading$,
        this.success$,
        this.errorMessage$,
        (createPromotionModel, promotion, loaded, loading, success, errorMessage) => ({
            createPromotionModel, promotion
            , loaded, loading, success, errorMessage
        })
    );

    readonly setInitial = this.updater(( _ : CreatePromotionState) => {
        return {
            ...initialState
        };
    });

    readonly setLoading = this.updater((state: CreatePromotionState) => {
        return {
            ...state,
            loading: true,
            loaded: false,
        };
    });

    readonly setLoaded = this.updater((state: CreatePromotionState) => {
        return {
            ...state,
            loading: false,
            loaded: true,
        };
    });

    readonly updateCreatePromotionState = this.updater((state: CreatePromotionState, value: {
        createPromotionModel: CreatePromotionModel
    }) => {
        return {
            ...state,
            loading: false,
            loaded: true,
            success: true,
            createPromotionModel: value.createPromotionModel
        };
    });

    readonly updatePromotionState = this.updater((state: CreatePromotionState, value: {
        promotion: PromotionModel
    }) => {
        return {
            ...state,
            loading: false,
            loaded: true,
            success: true,
            promotion: value.promotion
        };
    });

    readonly setError = this.updater((state: CreatePromotionState, value: {
        errorMessage: string
    }) => {
        return {
            ...state,
            loading: false,
            loaded: true,
            success: false,
            errorMessage: value.errorMessage,
        };
    });

    readonly createPromotion = this.effect((createPromotionParams$: Observable<{ companyId: string, createPromotionModel: CreatePromotionModel }>) => {
        this.setLoading();
        return createPromotionParams$.pipe(
            switchMap((params => this.promotionService.createPromotion(params.companyId, params.createPromotionModel).pipe(
                map((promotion) => {
                    if(promotion === null || promotion === undefined){
                        this.setError({ errorMessage: "Can't create a new promotion. You already have reached the maximum active promotions. Please deactivate or archive a promotion first and try again." });
                        this.setLoaded();
                        this.setInitial();
                    }
                    else {
                        this.updatePromotionState({ promotion: promotion });
                        this.setLoaded();
                        this.setInitial();
                    }
                }),
                catchError(async (error) => {
                    this.setError({ errorMessage: error });
                    this.setLoaded();
                    this.setInitial();
                })
            ))
            )
        );
    });
}