<div class="container">
  <h1>Users - Terms and Conditions</h1>
  <h2><strong>Last Updated:</strong> July 31st, 2024</h2>
  <p>
    Please read these Users - Terms and Conditions (“Terms”, “Terms and
    Conditions”) carefully before using the Planet VG websites (<a
      href="https://www.planet.vg"
      >www.planet.vg</a
    >, <a href="https://partner.planet.vg">partner.planet.vg</a>,
    <a href="https://account.planet.vg">account.planet.vg</a>) (the
    “Website(s)”) and the Planet VG mobile applications (the “Mobile
    Application” or the “Application”, collectively with the Website, the
    “Services”) operated by Almost Level 5 B.V., a
    <em>besloten vennootschap</em> (limited liability company) governed by the
    laws of the Netherlands, having its registered office at Jan Pieterz.
    Coenstraat 7, 2595WP The Hague, the Netherlands, registered with the Dutch
    chamber of commerce and companies under number 93949138 (“Almost Level 5”,
    “Us”, “We”, or “Our”).
  </p>
  <p>
    By signing up or otherwise using the Services or accessing any content or
    material that is made available by Almost Level 5 through the Services (the
    “Content”) you get access to:
  </p>
  <ul>
    <li>
      a list of promotional activities that you can perform to collect
      points/stamps that later you can redeem towards gifts that we or the
      Partners, in our sole discretion, have decided to make available to you in
      exchange for the collected points/stamps (“Planet VG Points/Stamps”); and
    </li>
    <li>
      promotional, marketing and advertising content that Partners have decided
      to share with you.
    </li>
  </ul>
  <p>
    Your access to and use of the Services is conditioned on your acceptance of
    and compliance with these Terms. These Terms apply to all visitors, users
    and others who access or use the Services.
  </p>
  <p>
    To use the Services and access the Content, you need to (1) be 13 or older
    and have your parent's or guardian's consent to the Terms, (2) have the
    power to enter a binding contract with us and not be barred from doing so
    under any applicable laws, and (3) be resident in a country where the
    Services are available. You also promise that any registration information
    that you submit to Almost Level 5 is true, accurate, and complete, and you
    agree to always keep it that way.
  </p>
  <p>
    <strong
      >If you disagree with any part of the Terms, then you may not access the
      Services.</strong
    >
  </p>
  <h2>1. License</h2>
  <p>
    1.1. Subject to the Terms hereof, Almost Level 5 grants you a
    non-transferable, non-exclusive, revocable, limited license to use the
    Services including any updates that might become available in the future
    (the “License”). This License shall remain in effect until and unless
    terminated by you or Almost Level 5.
  </p>
  <p>
    1.2. All content included in the Services, such as text, graphics, logos,
    images, data compilations, and software, is the property of Almost Level 5
    or the participating Partners. The compilation of all content on the
    Services and all the software used on or provided through the Services is
    the exclusive property of Almost Level 5.
  </p>
  <p>
    1.3. Any information, including but not limited to remarks, suggestions,
    ideas, graphics or other submissions, communicated to Almost Level 5 by you
    through the Services, including but not limited to intellectual property
    related thereto (except as may otherwise be expressly provided), becomes the
    exclusive property of Almost Level 5 without compensation and Almost Level 5
    may copy, incorporate, distribute or otherwise use such communications for
    any commercial or non-commercial purpose. You hereby agree to take whatever
    further actions, may be necessary to assign your rights to the same to
    Almost Level 5.
  </p>
  <p>
    1.4. By sending, posting and or publishing any Content on or through the
    Services, you hereby grant to Almost Level 5 a non-exclusive, fully paid and
    royalty-free, worldwide, limited license to use, modify, delete from, add
    to, publicly perform, publicly display, reproduce and translate such
    Content.
  </p>
  <h2>2. User login details</h2>
  <p>
    2.1. To access our Services, you will be required to register for a Planet
    VG account.
  </p>
  <p>
    2.2. Your username must not be liable to mislead and must comply with the
    content rules set out in section 4 of these Terms; you must not use your
    account or username for or in connection with the impersonation of any
    person.
  </p>
  <p>2.3. You must keep your password confidential.</p>
  <p>
    2.4. You must notify us in writing immediately if you become aware of any
    disclosure of your password.
  </p>
  <p>
    2.5. You are responsible for any activity on our website arising out of any
    failure to keep your password confidential, and may be held liable for any
    losses arising out of such a failure.
  </p>
  <p>
    2.6. You may create one and only one account with Planet VG and you may not
    transfer, sell or otherwise assign it to any other person.
  </p>
  <h2>3. Fees</h2>
  <p>
    Almost Level 5 does not currently charge its users to access and use the
    Services, however Almost Level 5 may, at any point in time and in its
    discretion, decide to start charging fees for the access and use of the
    Services or specific functionalities of the Services, upon [thirty (30)]
    days' prior notice to you, in accordance with clause 15 of these Terms.
  </p>
  <h2>4. Your content: rules</h2>
  <p>
    4.1. By using our Services you will have the opportunity to post, send,
    publish and/or submit content to us or the Partners. You warrant and
    represent that your content will comply with these terms and conditions.
  </p>
  <p>
    4.2. Your content must not be illegal or unlawful, must not infringe any
    person's legal rights, and must not be capable of giving rise to legal
    action against any person (in each case in any jurisdiction and under any
    applicable law).
  </p>
  <p>
    4.3. Your content, and the use of your content by us in accordance with
    these terms and conditions, must not:
  </p>
  <ul>
    <li>
      contain or show animal suffering or animal produce, e.g. honey, cow's
      milk, spareribs, eggs;
    </li>
    <li>be libelous or maliciously false;</li>
    <li>be obscene or indecent;</li>
    <li>
      infringe any copyright, moral right, database right, trademark right,
      design right, right in passing off, or other intellectual property right;
    </li>
    <li>
      infringe any right of confidence, right of privacy or right under data
      protection legislation;
    </li>
    <li>constitute negligent advice or contain any negligent statement;</li>
    <li>
      constitute an incitement to commit a crime, instructions for the
      commission of a crime or the promotion of criminal activity;
    </li>
    <li>be in contempt of any court, or in breach of any court order;</li>
    <li>
      be in breach of racial or religious hatred or discrimination legislation;
    </li>
    <li>be blasphemous;</li>
    <li>be in breach of official secrets legislation;</li>
    <li>depict violence in an explicit, graphic or gratuitous manner;</li>
    <li>be pornographic, lewd, suggestive or sexually explicit;</li>
    <li>be untrue, false, inaccurate or misleading;</li>
    <li>
      consist of or contain any instructions, advice or other information which
      may be acted upon and could, if acted upon, cause illness, injury or
      death, or any other loss or damage;
    </li>
    <li>constitute spam;</li>
    <li>
      be offensive, deceptive, fraudulent, threatening, abusive, harassing,
      anti-social, menacing, hateful, discriminatory or inflammatory; or
    </li>
    <li>cause annoyance, inconvenience or needless anxiety to any person.</li>
  </ul>
  <p>
    4.4. Almost Level 5 reserves the right to monitor all content submitted by
    you using the Services but at no instance Almost Level 5 is obliged to do
    so. You shall be solely responsible for all content you submitted to the
    Services.
  </p>
  <h2>5. Disclaimers</h2>
  <p>5.1. No warranty or support</p>
  <ul>
    <li>
      You expressly acknowledge and agree that use of the Services provided is
      at your sole risk and that the entire risk as to satisfactory quality,
      performance, accuracy and effort is with you.
    </li>
    <li>
      To the fullest extent permitted by applicable law, the Services are
      provided “as is” and “as available”, with all faults and without warranty
      of any kind, and we hereby disclaim all warranties and conditions with
      respect to the Services, either express, implied or statutory, including,
      but not limited to, any implied warranties and/or conditions of
      merchantability, of satisfactory quality, of fitness for a particular
      purpose, of accuracy, of quiet enjoyment, and non-infringement of third
      party rights. We do not warrant against interference with your enjoyment
      of the Services, that the functions contained in the Services will meet
      your requirements, that the operation of the Services will be
      uninterrupted or error-free, or that defects in the Services will be
      corrected. No oral or written information or advice given by us, or our
      authorized representative shall create a warranty.
    </li>
    <li>
      You expressly acknowledge and agree that Almost Level 5 is not responsible
      for making sure that Partners provide the promised rewards or any other
      obligations they might have towards you.
    </li>
    <li>
      You expressly acknowledge and agree that Almost Level 5 is not responsible
      or liable for any transaction between you and third-party providers of
      Third-Party Applications or products or services advertised on or through
      the Services.
    </li>
  </ul>
  <p>
    5.2. We do not warrant the Services or their availability. We reserve the
    right to discontinue or alter any or all of our Services, at any time in our
    sole discretion without notice or explanation. We also reserve the right to
    revoke your access to any or all of our Services at any time in our sole
    discretion without notice or explanation. In either case you will not be
    entitled to any compensation or other payment upon the revocation of access,
    discontinuance or alteration of any or all of our Services and any type of
    points/stamps and rewards that you have collected may become invalid.
  </p>
  <p>
    5.3. You are solely responsible for all service, telephony, data charges
    and/or other fees and costs associated with your access to and use of the
    Services, as well as for obtaining and maintaining all telephone, computer
    hardware, and other equipment required for such access and use.
  </p>
  <p>
    5.4. Almost Level 5 does not exercise any control over any Partner or other
    third parties, and as such is not liable or responsible for any actions
    taken or omitted to be taken by any such third party.
  </p>
  <p>
    5.5. Planet VG stamps and rewards have a validity period and if not
    used/claimed within that validity period they expire.
  </p>
  <h2>6. Acceptable use</h2>
  <p>6.1. You must not:</p>
  <ul>
    <li>
      use our Services in any way or take any action that causes, or may cause,
      damage to the Services or impairment of the performance, availability or
      accessibility of the Service;
    </li>
    <li>
      use our Services in any way that is unlawful, illegal, fraudulent or
      harmful, or in connection with any unlawful, illegal, fraudulent or
      harmful purpose or activity;
    </li>
    <li>
      use our Services to copy, store, host, transmit, post, send, use, publish
      or distribute any material which:
    </li>
    <li>
      consists of (or is linked to) any spyware, computer virus, Trojan horse,
      worm, keystroke logger, rootkit or other malicious computer software;
    </li>
    <li>do not comply with the content rules set out in Section 4</li>
    <li>
      access or otherwise interact with our Services using any robot, spider or
      other automated means, except for the purpose of search engine indexing;
    </li>
    <li>
      reverse engineer, decipher, decompile, disassemble or in any other way
      attempt to obtain the source code or other trade secrets of the Services
      or any part of the Services.
    </li>
    <li>
      use the Services to get in touch or attract businesses or persons to a
      directly or remotely competitive Services to Planet VG.
    </li>
    <li>
      post, send, publish, submit or in any other way make public coupons, QR
      codes, bar codes and any other information that might help other persons
      to claim rewards made available through Planet VG.
    </li>
    <li>
      exceed or attempt to exceed the limits that Almost Level 5 and/or the
      Partners have decided to set for the promotional activities. Such limits
      include but are not limited to the number of times you can claim a reward
      in a predefined period.
    </li>
  </ul>
  <p>
    6.2. You must ensure that all the information you supply to us through our
    Services, or in relation to our Services, is true, accurate, current,
    complete and non-misleading.
  </p>
  <p>
    6.3. You must represent only yourself on the Services, and no other persons,
    living, dead, or imagined. The Services may only be used by natural persons
    (i.e., individuals) on their own behalf and not by or on behalf of any
    commercial entity unless explicitly permitted by Almost Level 5.
  </p>
  <p>
    6.4. Any use or access by anyone under the age of 13 is prohibited. If you
    access or use the Services, you agree that you are NOT under the age of 13.
  </p>
  <p>
    6.5. When providing feedback to a Business through the Planet VG Services
    you must provide your honest, legitimate, accurate and timely opinion of the
    service you experienced. You accept that in cases our review of your
    provided feedback indicates that it might not be accurate, legitimate,
    honest and/or timely we, at our sole discretion, might decide to not grant
    you or revoke the associated Planet VG Points/Stamps. In case you fail to
    pass the review more than 3 times we might deactivate your account and in
    such case, you will not be entitled to any compensation or other payment and
    any type of points/stamps and rewards that you have collected may become
    invalid.
  </p>
  <p>
    6.6. When you claim a visit reward you should be located inside the Business
    that is providing you with this promotional activity. You accept that in
    case our review of your in-shop check-in indicates that you were not present
    at the location of the Business we might decide, at our sole discretion, to
    not grant you or revoke the associated Planet VG Points/Stamps. In case you
    fail to pass the review more than 3 times we might deactivate your account
    and in such case, you will not be entitled to any compensation or other
    payment and any type of points/stamps and rewards that you have collected
    may become invalid.
  </p>
  <p>
    6.7. You accept that in case our review of your collected stamps indicates
    that they were collected in an unauthorized way we might decide, at our sole
    discretion, to not grant you or revoke the associated Planet VG
    Points/Stamps. In case you fail to pass the review more than 3 times we
    might deactivate your account and in such case, you will not be entitled to
    any compensation or other payment and any type of points/stamps and rewards
    that you have collected may become invalid.
  </p>
  <p>
    6.8. Planet VG rewards and promotional offers cannot be combined with any
    other offers, vouchers or coupons and cannot be redeemed or exchanged for
    cash.
  </p>
  <h2>7. Privacy Policy</h2>
  <p>
    Almost Level 5's current privacy policy is available at Almost Level 5's
    <a href="./privacy-policy">PRIVACY POLICY</a> website (the “Privacy
    Policy”), which is incorporated by this reference. Almost Level 5 strongly
    recommends that you review the Privacy Policy closely.
  </p>
  <h2>8. Limitation of Liability</h2>
  <p>
    YOU AGREE THAT, TO THE EXTENT PERMITTED BY APPLICABLE LAW, YOUR SOLE AND
    EXCLUSIVE REMEDY FOR ANY PROBLEMS OR DISSATISFACTION WITH THE SERVICES IS TO
    UNINSTALL ANY ALMOST LEVEL 5 SOFTWARE AND TO STOP USING THE SERVICES. WHILE
    ALMOST LEVEL 5 ACCEPTS NO RESPONSIBILITY FOR THIRD-PARTY APPLICATIONS OR THE
    CONTENT THEREOF, AND WHILE YOUR RELATIONSHIP WITH SUCH THIRD-PARTY
    APPLICATIONS MAY BE GOVERNED BY SEPARATE AGREEMENTS WITH SUCH THIRD PARTIES,
    TO THE EXTENT PERMITTED BY APPLICABLE LAW, YOUR SOLE AND EXCLUSIVE REMEDY,
    AS WITH RESPECT TO ALMOST LEVEL 5, FOR ANY PROBLEMS OR DISSATISFACTION WITH
    THIRD-PARTY APPLICATIONS OR THE CONTENT THEREOF, IS TO UNINSTALL AND/OR STOP
    USING ANY SUCH THIRD-PARTY APPLICATIONS.
  </p>
  <p>
    TO THE FULLEST EXTENT PERMITTED BY LAW, IN NO EVENT WILL ALMOST LEVEL 5, ITS
    OFFICERS, SHAREHOLDERS, EMPLOYEES, AGENTS, DIRECTORS, SUBSIDIARIES,
    AFFILIATES, SUCCESSORS, ASSIGNS, SUPPLIERS, OR LICENSORS BE LIABLE FOR (1)
    ANY INDIRECT, SPECIAL, INCIDENTAL, PUNITIVE, EXEMPLARY, OR CONSEQUENTIAL
    DAMAGES; (2) ANY LOSS OF USE, DATA, BUSINESS, OR PROFITS (WHETHER DIRECT OR
    INDIRECT), IN ALL CASES ARISING OUT OF THE USE OR INABILITY TO USE THE
    SERVICES OR THIRD-PARTY APPLICATIONS, OR THIRD-PARTY APPLICATION CONTENT,
    WITHOUT REGARD TO WHETHER ALMOST LEVEL 5 HAS BEEN WARNED OF THE POSSIBILITY
    OF THOSE DAMAGES, AND EVEN IF A REMEDY FAILS ITS ESSENTIAL PURPOSE.
  </p>
  <p>
    Nothing in the Terms removes or limits Almost Level 5's liability for fraud,
    fraudulent misrepresentation and, if required by applicable law, gross
    negligence. Some aspects of this section may not apply in some jurisdictions
    if prohibited by applicable law.
  </p>
  <h2>9. Third-party rights</h2>
  <p>
    Other than as set out in this section, the Terms are not intended to grant
    rights to anyone except you and Almost Level 5, and in no event shall the
    Terms create any third-party beneficiary rights. Furthermore, the rights to
    terminate, rescind, or agree to any variation, waiver, or settlement of the
    Terms are not subject to the consent of any other person.
  </p>
  <h2>10. Indemnification</h2>
  <p>
    To the fullest extent permitted by applicable law, you agree to indemnify
    and hold harmless Almost Level 5, its contractors, its licensors, and its
    Partners, and their respective directors, officers, employees and agents
    from and against any and all damages, losses, claims and expenses of any
    kind, including reasonable attorneys' fees and costs, arising out of your
    use of the Services, including but not limited to your breach of these
    Terms, the use of the stamping device and your violation of any law or the
    rights of a third party.
  </p>
  <h2>11. Third-Party Content</h2>
  <p>
    Almost Level 5 may provide third-party content (the “Third-Party Content”)
    on the Services as well as links to websites and content of third parties
    (the “Third-Party Applications “), as a service to those interested in this
    information. Such Third-Party Applications may have their own terms and
    conditions of use and privacy policies and your use of such Third-Party
    Applications will be governed by and subject to such terms and conditions
    and privacy policies. Almost Level 5 does not control, endorse or adopt any
    Third-Party Content and makes no representation or warranty of any kind
    regarding the content, data, products and other information offered via
    those websites. You acknowledge and agree that Almost Level 5 is not
    responsible or liable in any manner for the behavior, features or content of
    any Third-Party Content or for any transaction you may enter into with the
    provider of any such Third-Party Application and undertakes no
    responsibility to update or review any Third-Party Content. Users use such
    Third-Party Content contained therein at their own risk. This provision will
    survive the use of the Services.
  </p>
  <h2>12. Entire agreement</h2>
  <p>
    Other than as stated in this section or as explicitly agreed upon in writing
    between you and Almost Level 5, the Terms constitute all the terms and
    conditions agreed upon between you and Almost Level 5 and supersede any
    prior agreements in relation to the subject matter of these Terms, whether
    written or oral.
  </p>
  <h2>13. Severability and waiver</h2>
  <p>
    Unless as otherwise stated in the Terms, should any provision of these Terms
    be held invalid, unenforceable or prohibited in any jurisdiction, for any
    reason or to any extent, this shall not affect the validity and
    enforceability of the remaining provisions of these Terms or the validity
    and enforceability of such provisions in any other jurisdiction.
  </p>
  <p>
    Any failure by Almost Level 5 or any third-party beneficiary to enforce the
    Terms or any provision thereof shall not waive Almost Level 5's or the
    applicable third-party beneficiary's right to do so.
  </p>
  <h2>14. Law and jurisdiction</h2>
  <p>
    These terms and conditions shall be governed by and construed in accordance
    with the laws of the Netherlands. Any disputes arising out or relating to
    these Terms and Conditions shall be subject to the exclusive jurisdiction of
    the courts of the district of The Hague, the Netherlands.
  </p>
  <h2>15. Changes</h2>
  <p>
    We reserve the right, at our sole discretion, to modify or replace these
    Terms at any time. When we make material changes to the Terms, we will
    provide you with prominent notice as appropriate under the circumstances,
    e.g., by displaying a prominent notice within the Services or by sending you
    an email. In some cases, we will notify you in advance, and your continued
    use of the Services after the changes have been made will constitute your
    acceptance of the changes. Please therefore make sure you read any such
    notice carefully. If you do not wish to continue using the Services under
    the new version of the Terms, you may terminate your use of the Services by
    deleting your Planet VG account or by contacting us by sending an email to
    <a href="mailto:privacy@almostlevel5.com">privacy&#64;almostlevel5.com</a>.
  </p>
  <h2>16. Miscellaneous</h2>
  <p>
    If any clause of these Terms & Conditions is or becomes void, unenforceable
    or prohibited in any jurisdiction, this shall not affect the validity and
    enforceability of the other clauses of these Terms & Conditions or the
    validity and enforceability of such clauses in any other jurisdiction. In
    any such event, Almost Level 5 and you agree to substitute a valid and
    enforceable clause in the place of the invalid, unenforceable or prohibited
    clause in the spirit and to the effect of the latter.
  </p>
  <p>
    By subscribing to the Service, you are opting in to receive e-mails about
    our campaigns, events and activities.
  </p>
  <h2>17. Contacting Us</h2>
  <p>
    If you have any questions about these Terms or the Services, please contact
    us using the contact details below:
  </p>
  <p>
    Almost Level 5 B.V.<br />
    Jan Pieterz. Coenstraat 7<br />
    2595WP The Hague <br />
    The Netherlands<br />
    <a href="mailto:info@almostlevel5.com">info&#64;almostlevel5.com</a>
  </p>
</div>
<app-bottom-menu></app-bottom-menu>
