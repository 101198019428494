<div [ngClass]="{
    'container-large': isLarge(),
    'container-medium': isMedium(),
    'container-small': isSmall(),
    'container-xsmall': isXSmall()
  }">
    <div class="container-banner">
        <h1>Help Center</h1>
    </div>

    <div class="container-category">
        <h2>General Questions</h2>
    </div>
    <mat-accordion class="container-content" multi>
        <mat-expansion-panel class="mat-component-background">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    What's the difference between the Planet VG app and the Planet VG
                    Store app?
                </mat-panel-title>
            </mat-expansion-panel-header>
            <p>
                The Planet VG app is the app for <u>your customers</u>. Using this app
                your customers can collect stamps and vouchers. They can also manage
                their club memberships.
            </p>
            <p>
                The Planet VG Store app is the app for <u>your employees</u>. Using this
                app your employees can issue stamps for a specific stamp card or
                redeem vouchers.
            </p>
            <p><em>And, while we are at it;</em></p>
            <p></p>
            <p>
                The Planet VG Portal is the portal where you can manage your promotions,
                stamp cards, store locations, and your company. You can also
                manage your subscription within the Planet VG Partner Portal.
            </p>
        </mat-expansion-panel>
    </mat-accordion>


    <div class="container-category">
        <h2>How to use the Planet VG Store app</h2>
    </div>
    <mat-accordion class="container-content" multi>
        <mat-expansion-panel class="mat-component-background">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    Can I run the Planet VG Store app on multiple devices?
                </mat-panel-title>
            </mat-expansion-panel-header>
            <p>
                Yes, there are no restrictions on the amount of devices the Planet VG
                Store App can be installed on. Be aware that each store user account is 
                tied to a specific store location.
            </p>
        </mat-expansion-panel>

        <mat-expansion-panel class="mat-component-background">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    My employee lost the store user password. How can they login again?
                </mat-panel-title>
            </mat-expansion-panel-header>
            <p>Log into the Planet VG Partner Portal.</p>
            <p>Go to <strong>Store Locations</strong> page.</p>
            <p>Select the store location your employee works in.</p>
            <p>
                In the <strong>Store User Details</strong> section, press the
                <mat-icon>lock_reset</mat-icon> icon to generate a new store user
                password.
            </p>
            <p>The store username will remain the same.</p>
            <p>Two things will happen:</p>
            <ul>
                <li>You get a pop-up with the new store user password.</li>
                <li>You will receive an email with the new store user password.</li>
            </ul>
        </mat-expansion-panel>

        <mat-expansion-panel class="mat-component-background">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    How do I reset the store location login?
                </mat-panel-title>
            </mat-expansion-panel-header>
            <p>Log into the Planet VG Partner Portal.</p>
            <p>Go to <strong>Store Locations</strong> page.</p>
            <p>Select the store location.</p>
            <p>
                In the <strong>Store User Details</strong> section, press the
                <mat-icon>lock_reset</mat-icon> icon to generate a new store user
                password.
            </p>
            <p>The store username will remain the same.</p>
            <p>Two things will happen:</p>
            <ul>
                <li>You get a pop-up with the new store user password.</li>
                <li>You will receive an email with the new store user password.</li>
            </ul>
        </mat-expansion-panel>

        <mat-expansion-panel class="mat-component-background">
            <mat-expansion-panel-header>
                <mat-panel-title> How do I issue a stamp </mat-panel-title>
            </mat-expansion-panel-header>
            <p>
                Log into the Planet VG Store app using the
                <strong>Store User</strong> account for that store location.
            </p>
            <p>On the Home screen:</p>
            <ul>
                <li>Press the <strong>Issue Stamp</strong> button.</li>
                <li>Select the stamp card to issue the stamp for.</li>
                <li>Scan the customer's loyalty card QR code.</li>
            </ul>
            <p>
                <mat-icon>lightbulb_outline</mat-icon> The customer needs to be logged
                into the Planet VG app on their phone. Then they need to do the
                following:
            </p>
            <ul>
                <li>
                    Press the <mat-icon>badge</mat-icon> button to present their loyalty
                    card QR code.
                </li>
            </ul>
        </mat-expansion-panel>

        <mat-expansion-panel class="mat-component-background">
            <mat-expansion-panel-header>
                <mat-panel-title> How do I redeem a voucher </mat-panel-title>
            </mat-expansion-panel-header>
            <p>
                Log into the Planet VG Store app using the
                <strong>Store User</strong> account for that store location.
            </p>
            <p>On the Home screen:</p>
            <ul>
                <li>Press the <strong>Redeem Voucher</strong> button.</li>
                <li>Scan the customer's voucher QR code.</li>
                <li>Give the customer their reward.</li>
            </ul>
            <p>
                <mat-icon>lightbulb_outline</mat-icon> The customer needs to be logged
                into the Planet VG app on their phone. Then they need to do the
                following:
            </p>
            <ul>
                <li>Select the voucher in the <strong>Vouchers</strong> page.</li>
                <li>
                    Press the <mat-icon>redeem</mat-icon>
                    <strong>Redeem Reward</strong> button to present their voucher's QR
                    code.
                </li>
            </ul>
            <p>
                <mat-icon>lightbulb_outline</mat-icon> Your employee will see an error
                popup when their voucher is:
            </p>
            <ul>
                <li>Already redeemed. You can only redeem a voucher once.</li>
                <li>Is not from your company, but a different company.</li>
            </ul>
        </mat-expansion-panel>
        <mat-expansion-panel class="mat-component-background">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    Your customer forgot to get their stamp, what can I do?
                </mat-panel-title>
            </mat-expansion-panel-header>
            <p>
                No worries, you can always issue the stamp when the customer returns
                later on.
            </p>
            <p>You have complete control over when and how you issue stamps.</p>
        </mat-expansion-panel>
    </mat-accordion>

    <div class="container-category">
        <h2>Security, Data Compliance and Policies</h2>
    </div>
    <mat-accordion class="container-content" multi>
        <mat-expansion-panel class="mat-component-background">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    What Terms of Service do my customers have?
                </mat-panel-title>
            </mat-expansion-panel-header>
            <p>
                You can find our user terms on the
                <a href="/user-terms">User Terms</a> page.
            </p>
        </mat-expansion-panel>

        <mat-expansion-panel class="mat-component-background">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    What Terms of Service do I have as a partner?
                </mat-panel-title>
            </mat-expansion-panel-header>
            <p>You have agree to the following Terms of Services:</p>
            <ul>
                <li>User Terms of Service</li>
                <li>Partner Terms of Service</li>
            </ul>
            <p>
                You can find our user terms on the
                <a href="/user-terms">User Terms</a> page.
            </p>
            <p>
                You can find our partner terms on the
                <a href="/partner-terms">Partner Terms</a> page.
            </p>
        </mat-expansion-panel>

        <mat-expansion-panel class="mat-component-background">
            <mat-expansion-panel-header>
                <mat-panel-title> What is the Privacy Policy? </mat-panel-title>
            </mat-expansion-panel-header>
            <p>
                You can find our privacy policy on the
                <a href="/privacy-policy">Privacy Policy</a> page.
            </p>
        </mat-expansion-panel>

        <mat-expansion-panel class="mat-component-background">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    How do I delete my Planet VG business account?
                </mat-panel-title>
            </mat-expansion-panel-header>
            <p>Log into the Planet VG Partner Portal.</p>
            <p>Go to <strong>Account</strong> page.</p>
            <p>
                In the <strong>Account Deletion</strong> section, press the
                <mat-icon>delete_outline</mat-icon><strong>Delete Business Account</strong> button.
            </p>
            <p>
                You can only delete your Planet VG business account if you meet the
                following conditions:
            </p>
            <ul>
                <li>You have <strong>no unpaid</strong> invoices.</li>
                <li>You have <strong>canceled</strong> your subscription plan.</li>
            </ul>
        </mat-expansion-panel>

        <mat-expansion-panel class="mat-component-background">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    My phone is stolen or lost, is my account safe?
                </mat-panel-title>
            </mat-expansion-panel-header>
            <p>No worries, you can do the following:</p>
            <ul>
                <li>
                    Reset your Password: Navigate to the login page and request a reset
                    password. You will receive an email on how to reset your password.
                </li>
                <li>
                    Logout your session: If this is the case contact us, so that we can
                    revoke your logged in sessions.
                </li>
            </ul>
        </mat-expansion-panel>
    </mat-accordion>

    <div class="container-category">
        <h2>Troubleshooting</h2>
    </div>
    <mat-accordion class="container-content" multi>
        <mat-expansion-panel class="mat-component-background">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    Why can’t my business be found in the Planet VG app?
                </mat-panel-title>
            </mat-expansion-panel-header>
            <p>Check if you have an active subscription and no unpaid invoice.</p>
        </mat-expansion-panel>

        <mat-expansion-panel class="mat-component-background">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    I’m not receiving the password reset email. Please help!
                </mat-panel-title>
            </mat-expansion-panel-header>
            <p>Have you double checked your spam box?</p>
            <p>You can always retry requesting a password reset.</p>
            <p>If that didn't work, please <a href="/contact">contact</a> us.</p>
        </mat-expansion-panel>
    </mat-accordion>

    <div class="container-category">
        <h2>Help And Support</h2>
    </div>
    <mat-accordion class="container-content" multi>
        <mat-expansion-panel class="mat-component-background">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    Where can I download the Planet VG Store app?
                </mat-panel-title>
            </mat-expansion-panel-header>
            <p>You can find the Planet VG Store app in the following app stores:</p>
            <p>
                <a href="https://play.google.com/store/apps/details?id=com.almostlevel5.planetvg.loyalty.store"
                    target="_blank" rel="noopener"><img class="app-store-icon" src="/assets/img/google-play-badge.png"
                        alt="Planet VG Store App - Google Play Store"></a>
                <br />
                <a href="https://apps.apple.com/app/planet-vg-store/id6505109752" target="_blank" rel="noopener"><img
                        class="app-store-icon" src="/assets/img/apple-app-store-badge.png"
                        alt="Planet VG Store App - Apple App Store"></a>
            </p>
        </mat-expansion-panel>

        <mat-expansion-panel class="mat-component-background">
            <mat-expansion-panel-header>
                <mat-panel-title> Why can't I find the Planet VG Store within the Apple App Store? </mat-panel-title>
            </mat-expansion-panel-header>
            <p>Due to the Apple App Store Guidelines and Terms of Usage, we are not allowed to have the Planet VG Store publicly available within the Apple App Store.
                This means you can only download the Planet VG Store app using the following link:<br/>
                <a href="https://apps.apple.com/app/planet-vg-store/id6505109752" target="_blank" rel="noopener"><img
                    class="app-store-icon" src="/assets/img/apple-app-store-badge.png"
                    alt="Planet VG Store App - Apple App Store"></a>
            </p>
            
        </mat-expansion-panel>

        <mat-expansion-panel class="mat-component-background">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    Where can my customers download the Planet VG app?
                </mat-panel-title>
            </mat-expansion-panel-header>
            <p>You can find the Planet VG app in the following app stores:</p>
            <p>
                <a href="https://play.google.com/store/apps/details?id=com.almostlevel5.planetvg.loyalty"
                    target="_blank" rel="noopener"><img class="app-store-icon" src="/assets/img/google-play-badge.png"
                        alt="Planet VG App - Google Play Store"></a>
                <br />
                <a href="https://apps.apple.com/app/planet-vg/id6505109649" target="_blank" rel="noopener"><img
                        class="app-store-icon" src="/assets/img/apple-app-store-badge.png"
                        alt="Planet VG App - Apple App Store"></a>
            </p>
        </mat-expansion-panel>

        <mat-expansion-panel class="mat-component-background">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    Do my customers need a Planet VG account?
                </mat-panel-title>
            </mat-expansion-panel-header>
            <p>
                Yes, they do. The loyalty card is tied to a Planet VG account. Your
                customers can create a Planet VG account within the Planet VG app.
            </p>
            <p>You can find the Planet VG app in the following app stores:</p>
            <p>
                <a href="https://play.google.com/store/apps/details?id=com.almostlevel5.planetvg.loyalty"
                    target="_blank" rel="noopener"><img class="app-store-icon" src="/assets/img/google-play-badge.png"
                        alt="Planet VG App - Google Play Store"></a>
                <br />
                <a href="https://apps.apple.com/app/planet-vg/id6505109649" target="_blank" rel="noopener"><img
                        class="app-store-icon" src="/assets/img/apple-app-store-badge.png"
                        alt="Planet VG App - Apple App Store"></a>
            </p>
        </mat-expansion-panel>

        <mat-expansion-panel class="mat-component-background">
            <mat-expansion-panel-header>
                <mat-panel-title> Do vouchers expire? </mat-panel-title>
            </mat-expansion-panel-header>
            <p>Yes. You determine the vouchers expiration date.</p>
            <p>For a promotion:</p>
            <ul>
                <li>
                    Specify the <strong><em>Voucher Valid Until Date</em></strong>.
                </li>
            </ul>
            <p>For a stamp card:</p>
            <ul>
                <li>
                    Specify the <strong><em>Voucher Expiration</em></strong>.
                </li>
            </ul>
        </mat-expansion-panel>

        <mat-expansion-panel class="mat-component-background">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    How do customers update their communication preferences?
                </mat-panel-title>
            </mat-expansion-panel-header>
            <p>
                Your customers can update their communication preferences using the
                Planet VG app.
            </p>

            <p>They need to do the following:</p>
            <ul>
                <li>
                    Go to the <mat-icon>settings</mat-icon><strong>Settings</strong> page.
                </li>
                <li>
                    Click the <strong>Communication</strong> option.
                </li>
                <li>
                    In the <strong>Email</strong> section they can toggle their email
                    preferences.
                </li>
                <li>
                    In the <strong>Push Notification</strong> section they can toggle
                    their push notification preferences.
                </li>
            </ul>
        </mat-expansion-panel>

        <mat-expansion-panel class="mat-component-background">
            <mat-expansion-panel-header>
                <mat-panel-title> Can I log into multiple phones? </mat-panel-title>
            </mat-expansion-panel-header>
            <p>Yes, you can.</p>
        </mat-expansion-panel>
        <mat-expansion-panel class="mat-component-background"
            *ngIf="(isAuthenticated$ | async).isAuthenticated && hasBusinessAccount">
            <mat-expansion-panel-header>
                <mat-panel-title>Didn't find an answer? Contact us.</mat-panel-title>
            </mat-expansion-panel-header>
            <p>Contact the <a href="{{ getMailSupportLink() }}">Planet VG Support Chefs</a>.</p>
        </mat-expansion-panel>
    </mat-accordion>
</div>