import { Injectable } from "@angular/core";
import { ComponentStore } from "@ngrx/component-store";
import { catchError, Observable, switchMap, tap } from "rxjs";
import { StoreUserModel } from "src/app/models/store-user-model";
import { StoreUserService } from "src/app/services/store-user.service";
import { StoreUserState } from "./store-user.state";

const initialState: StoreUserState = {
    loading: false,
    loaded: false,
    success: false,
    errorMessage: null,
    storeUser: null,
    newPassword: null,
};

@Injectable({
    providedIn: 'root'
})
export class StoreUserComponentStore extends ComponentStore<StoreUserState> {

    constructor(private readonly storeUserService: StoreUserService) {
        super(initialState);
    }

    readonly storeUser$: Observable<StoreUserModel> = this.select(state => state.storeUser);
    readonly newPassword$: Observable<string> = this.select(state => state.newPassword);
    readonly loading$: Observable<boolean> = this.select(state => state.loading);
    readonly loaded$: Observable<boolean> = this.select(state => state.loaded);
    readonly success$: Observable<boolean> = this.select(state => state.success);
    readonly errorMessage$: Observable<any> = this.select(state => state.errorMessage);

    readonly viewModel$ = this.select(
        this.storeUser$,
        this.loaded$,
        this.loading$,
        this.success$,
        this.errorMessage$,
        (storeUser, loaded, loading, success, errorMessage) => ({
            storeUser, loaded, loading, success, errorMessage
        })
    );

    readonly setInitial = this.updater(( _ : StoreUserState) => {
        return {
            ...initialState
        };
    });

    readonly setLoading = this.updater((state: StoreUserState) => {
        return {
            ...state,
            loading: true,
            loaded: false,
        };
    });

    readonly setLoaded = this.updater((state: StoreUserState) => {
        return {
            ...state,
            loading: false,
            loaded: true,
        };
    });

    readonly updateStoreUser = this.updater((state: StoreUserState, value: {
        storeUser: StoreUserModel
    }) => {
        return {
            ...state,
            loading: false,
            loaded: true,
            success: true,
            storeUser: value.storeUser
        };
    });

    readonly updateNewPassword = this.updater((state: StoreUserState, value: {
        newPassword: string
    }) => {
        return {
            ...state,
            loading: false,
            loaded: true,
            success: true,
            newPassword: value.newPassword
        };
    });

    readonly setError = this.updater((state: StoreUserState, value: {
        errorMessage: string
    }) => {
        return {
            ...state,
            loading: false,
            loaded: true,
            success: false,
            errorMessage: value.errorMessage,
        };
    });

    readonly getStoreUser = this.effect((storeUser$: Observable<{ companyId: string, storeLocationId: number }>) => {
        this.setLoading();
        return storeUser$.pipe(
            switchMap((params => this.storeUserService.getStoreUser(params.companyId, params.storeLocationId).pipe(
                tap({
                    next: (storeUser) => {
                        this.updateStoreUser({ storeUser: storeUser })
                        this.setLoaded()
                    },
                    error: (e) => { throw e; }
                }),
                catchError(async (error) => {
                    this.setError({ errorMessage: error })
                    this.setLoaded()
                })
            ))
            )
        );
    });

    readonly generateNewPassword = this.effect((storeUser$: Observable<{ companyId: string, storeLocationId: number, storeUserId: string }>) => {
        this.setLoading();
        return storeUser$.pipe(
            switchMap((params => this.storeUserService.generateNewPassword(params.companyId, params.storeLocationId, params.storeUserId).pipe(
                tap({
                    next: (storeUserPassword) => {
                        this.updateNewPassword({ newPassword: storeUserPassword.password });
                        this.setLoaded();
                    },
                    error: (e) => { throw e; }
                }),
                catchError(async (error) => {
                    this.setError({ errorMessage: error });
                    this.setLoaded();
                    this.setInitial();
                })
            ))
            )
        );
    });
}