<div [ngClass]="{
  'container-xlarge': isXLarge(),
  'container-large': isLarge(),
  'container-medium': isMedium(),
  'container-small': isSmall(),
  'container-xsmall': isXSmall()
}">
  <div class="compare-plans-banner">
    <h2>What we bring to the table</h2>
  </div>

  <div [ngClass]="{
    'container-content-xlarge': isXLarge(),
    'container-content-large': isLarge(),
    'container-content-medium': isMedium(),
    'container-content-small': isSmall(),
    'container-content-xsmall': isXSmall()
  }">
    <table mat-table [dataSource]="dataSource" class="mat-component-background mat-elevation-z2">
      <ng-container matColumnDef="featureName">
        <th mat-header-cell *matHeaderCellDef>&nbsp;</th>
        <td mat-cell *matCellDef="let element"> {{element.featureName}} </td>
      </ng-container>

      <ng-container matColumnDef="basic">
        <th mat-header-cell *matHeaderCellDef><span class="mat-headline-6">Starters</span><br /><span>Basic Plan
            (vg)</span></th>
        <td mat-cell *matCellDef="let element"> {{element.basicValue}} </td>
      </ng-container>

      <ng-container matColumnDef="standard">
        <th mat-header-cell *matHeaderCellDef><span class="mat-headline-6">Mains</span><br /><span>Standard Plan
            (vg)</span></th>
        <td mat-cell *matCellDef="let element">
          {{element.standardValue}}
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  </div>
</div>