<div class="container" [formGroup]="weeklyRepeatRuleFormGroup">
  <mat-button-toggle-group
    name="recurrenceRepeatWeekly"
    aria-label="recurrenceRepeatWeekly"
    multiple
    class="mat-component-background mat-elevation-z2"
    formControlName="recurrenceRepeatRuleWeeklyDays" 
  >
    <mat-button-toggle
      *ngFor="let recurrenceFrequency of recurrenceFrequencies"
      [value]="recurrenceFrequency.value"
      >{{ recurrenceFrequency.viewValue }}</mat-button-toggle
    >
  </mat-button-toggle-group>
</div>
