export class UpdateCampaignModel {
    name: string;
    description: string;
    startDate: Date;
    endDate: Date;
    stampsObjective: number;
    campaignRewardName: string;
    campaignRewardDescription: string;
    voucherDuration: string;
    recurrencePattern : string;
    campaignStatus: string
}