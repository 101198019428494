import { RRule, Weekday } from "rrule";

export class RRuleUtils {
    static readonly MONTHS = [
        'jan',
        'feb',
        'mar',
        'apr',
        'may',
        'jun',
        'jul',
        'aug',
        'sep',
        'oct',
        'nov',
        'dec'
    ];

    static readonly DAYS = [
        'monday',
        'tuesday',
        'wednesday',
        'thursday',
        'friday',
        'saturday',
        'sunday',
    ];

    static readonly TYPE_OF_DAYS = [
        'monday',
        'tuesday',
        'wednesday',
        'thursday',
        'friday',
        'saturday',
        'sunday',
        'day',
        'weekday',
        'weekendday'
    ];

    static readonly isValidDate = (d) => {
        return !isNaN(d) && d instanceof Date;
    }

    static readonly getDateParts = (date: Date) => {
        return this.isValidDate(date) ? {
            year: date.getFullYear(),
            month: date.getMonth() + 1,
            day: date.getDate()
        } : {};
    }

    public static toDisplayText(recurrencePattern: string) {
        if (recurrencePattern) {
            return new RRule(RRule.parseString(recurrencePattern)).toText();
        };
        return '';
    }

    public static readonly computeStartRule = (date) => {
        let start: any = {};

        if (date) {
            const dateParts = RRuleUtils.getDateParts(date);
            return {
                dtstart: new Date(Date.UTC(dateParts.year, dateParts.month - 1, dateParts.day, 0, 0))
            };
        }

        return start;
    };

    public static computeRepeatRule(
        frequency: string,
        interval: string,
        weeklyDays,
        onMonthlyChoice: string,
        onDay: string,
        repeatRuleMonthlyOnWhichDayPosition: string,
        repeatRuleMonthlyOnWhichDayDay: string,
        onYearlyChoice: string,
        repeatRuleYearlyOnMonthDay: string,
        repeatRuleYearlyOnMonthMonth: string,
        repeatRuleYearlyOnWhichDayMonth: string,
        repeatRuleYearlyOnWhichDayPositionMonth: string,
        repeatRuleYearlyOnWhichMonthMonth: string
    ) {
        switch (frequency) {
            case 'daily':
                return this.computeDailyRule(interval);
            case 'weekly':
                return this.computeWeeklyRule(interval, weeklyDays);
            case 'monthly':
                return this.computeMonthlyRule(interval, onMonthlyChoice, onDay, repeatRuleMonthlyOnWhichDayPosition, repeatRuleMonthlyOnWhichDayDay);
            case 'yearly':
                return this.computeYearlyRule(interval, onYearlyChoice, repeatRuleYearlyOnMonthDay, repeatRuleYearlyOnMonthMonth, repeatRuleYearlyOnWhichDayMonth, repeatRuleYearlyOnWhichDayPositionMonth, repeatRuleYearlyOnWhichMonthMonth)
            default:
                return {};
        }
    }

    public static readonly computeDailyRule = (interval: string) => ({
        freq: RRule.DAILY,
        interval,
    });

    public static readonly computeWeeklyRule = (interval: string, weeklyDays: []) => ({
        freq: RRule.WEEKLY,
        interval,
        byweekday: this.getWeekDays(weeklyDays)
        ,
    });

    public static readonly computeMonthlyRule = (interval: string, onMonthlyChoice: string, onDay: string, repeatRuleMonthlyOnWhichDayPosition: string, repeatRuleMonthlyOnWhichDayDay: string) => {
        return {
            freq: RRule.MONTHLY,
            interval,
            ... (onMonthlyChoice === 'on-day'
                ? this.computeMonthlyOnDayRule(onDay)
                : this.computeMonthlyOnWhichDayRule(repeatRuleMonthlyOnWhichDayPosition, repeatRuleMonthlyOnWhichDayDay)
            )
        };
    };

    public static readonly computeYearlyRule = (
        interval: string,
        onYearlyChoice: string,
        repeatRuleYearlyOnMonthDay: string,
        repeatRuleYearlyOnMonthMonth: string,
        repeatRuleYearlyOnWhichDayMonth: string,
        repeatRuleYearlyOnWhichDayPositionMonth: string,
        repeatRuleYearlyOnWhichMonthMonth: string) => {
        return {
            freq: RRule.YEARLY,
            interval,
            ...(onYearlyChoice === 'on-day-of-month'
                ? this.computeYearlyOnMonthMonthRule(repeatRuleYearlyOnMonthDay, repeatRuleYearlyOnMonthMonth)
                : this.computeYearlyOnWhichDayMonthRule(repeatRuleYearlyOnWhichDayMonth, repeatRuleYearlyOnWhichDayPositionMonth, repeatRuleYearlyOnWhichMonthMonth)
            )
        };
    }

    public static readonly computeEndRule = (endRule, endRuleOccurrences, endRuleEndDate) => {
        const end: any = {};

        if (endRule === 'after') {
            end.count = endRuleOccurrences;
        }
        else if (endRule === 'on-date') {
            const dateParts = RRuleUtils.getDateParts(endRuleEndDate);
            end.until = new Date(Date.UTC(dateParts.year, dateParts.month - 1, dateParts.day, 23, 59, 59, 999));
        }

        return end;
    }

    public static computeExtraOptions(hideStartDate: boolean, weekStartsOnSunday: boolean, localTimeZone: boolean) {
        const extraOptions: any = {};

        if (hideStartDate) {
            extraOptions.dtstart = null;
        }

        if (weekStartsOnSunday) {
            extraOptions.wkst = RRule.SU;
        }

        if (localTimeZone) {
            extraOptions.tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
        }

        return extraOptions;
    }

    private static getWeekDays(weeklyDays: []): number[] {
        const result = weeklyDays.map(
            d => {
                switch (d) {
                    case 'monday':
                        return 0;
                    case 'tuesday':
                        return 1;
                    case 'wednesday':
                        return 2;
                    case 'thursday':
                        return 3;
                    case 'friday':
                        return 4;
                    case 'saturday':
                        return 5;
                    case 'sunday':
                        return 6;
                    default:
                        throw new RangeError('Day not supported.');
                }
            }
        );
        return result;
    }



    private static readonly computeMonthlyOnDayRule = (onDay: string) => ({
        bymonthday: onDay,
    });

    private static readonly computeMonthlyOnWhichDayRule = (repeatRuleMonthlyOnWhichDayPosition: string, repeatRuleMonthlyOnWhichDayDay: string) => {
        let repeat: any = {};

        switch (repeatRuleMonthlyOnWhichDayPosition) {
            case 'first':
                repeat.bysetpos = 1;
                break;
            case 'second':
                repeat.bysetpos = 2;
                break;
            case 'third':
                repeat.bysetpos = 3;
                break;
            case 'fourth':
                repeat.bysetpos = 4;
                break;
            case 'last':
                repeat.bysetpos = -1;
                break;
            default:
                break;
        }

        switch (repeatRuleMonthlyOnWhichDayDay) {
            case 'monday':
                repeat.byweekday = [0];
                break;
            case 'tuesday':
                repeat.byweekday = [1];
                break;
            case 'wednesday':
                repeat.byweekday = [2];
                break;
            case 'thursday':
                repeat.byweekday = [3];
                break;
            case 'friday':
                repeat.byweekday = [4];
                break;
            case 'saturday':
                repeat.byweekday = [5];
                break;
            case 'sunday':
                repeat.byweekday = [6];
                break;
            case 'day':
                repeat.byweekday = [0, 1, 2, 3, 4, 5, 6];
                break;
            case 'weekday':
                repeat.byweekday = [0, 1, 2, 3, 4];
                break;
            case 'weekendday':
                repeat.byweekday = [5, 6];
                break;
            default:
                break;
        }

        if (repeat.byweekday && repeat.bysetpos) {
            repeat.byweekday = repeat.byweekday.map((r: number) => new Weekday(r, repeat.bysetpos));
        }
        return repeat;
    };

    private static readonly computeYearlyOnMonthMonthRule = (onMonthDay: string, onMonthMonth: string) => {
        return {
            bymonth: RRuleUtils.MONTHS.indexOf(onMonthMonth) + 1,
            bymonthday: onMonthDay,
        };
    }

    private static readonly computeYearlyOnWhichDayMonthRule = (
        repeatRuleYearlyOnWhichDayMonth: string,
        repeatRuleYearlyOnWhichDayPositionMonth: string,
        repeatRuleYearlyOnWhichMonthMonth: string) => {
        const repeat: any = {};

        switch (repeatRuleYearlyOnWhichDayPositionMonth) {
            case 'first':
                repeat.bysetpos = 1;
                break;
            case 'second':
                repeat.bysetpos = 2;
                break;
            case 'third':
                repeat.bysetpos = 3;
                break;
            case 'fourth':
                repeat.bysetpos = 4;
                break;
            case 'last':
                repeat.bysetpos = -1;
                break;
            default:
                break;
        }

        switch (repeatRuleYearlyOnWhichDayMonth) {
            case 'monday':
                repeat.byweekday = [0];
                break;
            case 'tuesday':
                repeat.byweekday = [1];
                break;
            case 'wednesday':
                repeat.byweekday = [2];
                break;
            case 'thursday':
                repeat.byweekday = [3];
                break;
            case 'friday':
                repeat.byweekday = [4];
                break;
            case 'saturday':
                repeat.byweekday = [5];
                break;
            case 'sunday':
                repeat.byweekday = [6];
                break;
            case 'day':
                repeat.byweekday = [0, 1, 2, 3, 4, 5, 6];
                break;
            case 'weekday':
                repeat.byweekday = [0, 1, 2, 3, 4];
                break;
            case 'weekendday':
                repeat.byweekday = [5, 6];
                break;
            default:
                break;
        }


        if (repeat.byweekday && repeat.bysetpos) {
            repeat.byweekday = repeat.byweekday.map((r: number) => new Weekday(r, repeat.bysetpos));
        }

        repeat.bymonth = RRuleUtils.MONTHS.indexOf(repeatRuleYearlyOnWhichMonthMonth) + 1;

        return repeat;
    }
}