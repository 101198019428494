import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { SpinnerDialogComponent } from 'src/app/core/components/dialogs/spinner-dialog/spinner-dialog.component';
import { BusinessAccountModel } from 'src/app/models/business-account-model';
import { SpinnerService } from 'src/app/services/spinner.service';
import { StoreLocationsComponentStore } from 'src/app/stores/components/store-locations/store-locations.component-store';
import { selectBusinessAccount, selectHasSubscriptionPlanOrder, selectUnpaidInvoice } from 'src/app/stores/global/app.selectors';

@Component({
  selector: 'app-store-locations-page',
  templateUrl: './store-locations-page.component.html',
  styleUrls: ['./store-locations-page.component.scss']
})
export class StoreLocationsPageComponent implements OnInit {
  businessAccount$: Observable<BusinessAccountModel> = this.store.select(selectBusinessAccount);
  unpaidInvoice$ = this.store.select(selectUnpaidInvoice);
  ngUnsubscribeState = new Subject<void>();

  displayedColumns: string[] = ['id', 'name', 'addressLine1', 'city', 'phoneNumber', 'storeLocationStatus'];
  viewModel$ = this.storeLocationsStore.viewModel$;

  showAdd$: Observable<boolean>;

  currentPage: number = 0;
  pageSize: number = 20;
  pageSizeOptions = [this.pageSize];

  private businessAccount: BusinessAccountModel;
  private loadingSpinnerDialogRef: MatDialogRef<SpinnerDialogComponent>;

  constructor(
    private store: Store<any>,
    private storeLocationsStore: StoreLocationsComponentStore,
    private router: Router,
    private spinnerService: SpinnerService
  ) { }

  ngOnInit(): void {
    this.store.select(selectBusinessAccount).pipe(filter(result => Boolean(result))).subscribe(businessAccount => {
      const gettingStartedCompanyDetails: boolean = !businessAccount.company.name || businessAccount.company.name === '';
      const gettingStartedCompanyLogo: boolean = !businessAccount.company.logo;
      if (gettingStartedCompanyDetails || gettingStartedCompanyLogo) {
        this.router.navigate(['/getting-started']);
      }
      else {
        this.businessAccount = businessAccount;

        this.storeLocationsStore.setInitial();
        this.showSpinner();
        this.storeLocationsStore.getStoreLocations({ companyId: businessAccount.company.id, currentPage: this.currentPage, pageSize: this.pageSize });
      }
    });

    this.showAdd$ = this.store.select(selectHasSubscriptionPlanOrder).pipe(takeUntil(this.ngUnsubscribeState));

    this.storeLocationsStore.loaded$.pipe(takeUntil(this.ngUnsubscribeState)).subscribe((loaded) => {
      this.hideSpinner(loaded);
    });
  }

  ngOnDestroy() {
    this.storeLocationsStore.setInitial();
    this.ngUnsubscribeState.next();
    this.ngUnsubscribeState.complete();
  }

  onPageEvent(event?: PageEvent) {
    this.showSpinner();
    this.storeLocationsStore.getStoreLocations({ companyId: this.businessAccount.company.id, currentPage: event.pageIndex, pageSize: event.pageSize });
    return event;
  }

  create() {
    this.router.navigate(['/companies/', this.businessAccount.company.id, 'store-locations', 'create']);
  }

  private showSpinner() {
    this.loadingSpinnerDialogRef = this.spinnerService.show();
  }

  private hideSpinner(loaded: boolean) {
    if (loaded && this.loadingSpinnerDialogRef !== null) {
      this.spinnerService.hide(this.loadingSpinnerDialogRef);
      this.loadingSpinnerDialogRef = null;
    }
  }
}
