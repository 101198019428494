import { Injectable } from "@angular/core";
import { ComponentStore } from "@ngrx/component-store";
import { Observable } from "rxjs";
import { catchError, map, switchMap } from "rxjs/operators";
import { PromotionDetailState } from "./promotion-detail.state";
import { PromotionService } from "src/app/services/promotion.service";
import { PromotionModel } from "src/app/models/promotion-model";

const initialState: PromotionDetailState = {
    loading: false,
    loaded: false,
    success: false,
    errorMessage: null,
    promotion: null
};

@Injectable({
    providedIn: 'root'
})
export class PromotionDetailComponentStore extends ComponentStore<PromotionDetailState> {

    constructor(private readonly promotionService: PromotionService) {
        super(initialState);
    }

    readonly promotion$: Observable<PromotionModel> = this.select(state => state.promotion);
    readonly loading$: Observable<boolean> = this.select(state => state.loading);
    readonly loaded$: Observable<boolean> = this.select(state => state.loaded);
    readonly success$: Observable<boolean> = this.select(state => state.success);
    readonly errorMessage$: Observable<any> = this.select(state => state.errorMessage);

    readonly viewModel$ = this.select(
        this.promotion$,
        this.loaded$,
        this.loading$,
        this.success$,
        this.errorMessage$,
        (promotion, loaded, loading, success, errorMessage) => ({
            promotion, loaded, loading, success, errorMessage
        })
    );

    readonly setInitial = this.updater(( _ : PromotionDetailState) => {
        return {
            ...initialState
        };
    });

    readonly setLoading = this.updater((state: PromotionDetailState) => {
        return {
            ...state,
            loading: true,
            loaded: false,
        };
    });

    readonly setLoaded = this.updater((state: PromotionDetailState) => {
        return {
            ...state,
            loading: false,
            loaded: true,
        };
    });

    readonly updateDetailState = this.updater((state: PromotionDetailState, value: {
        promotion: PromotionModel
    }) => {
        return {
            ...state,
            loading: false,
            loaded: true,
            success: true,
            promotion: value.promotion
        };
    });

    readonly setError = this.updater((state: PromotionDetailState, value: {
        errorMessage: string
    }) => {
        return {
            ...state,
            loading: false,
            loaded: true,
            success: false,
            errorMessage: value.errorMessage,
        };
    });

    readonly getPromotion = this.effect((detailParams$: Observable<{ companyId: string, promotionId: number }>) => {
        this.setLoading();
        return detailParams$.pipe(
            switchMap((params => this.promotionService.getPromotion(params.companyId, params.promotionId).pipe(
                map((promotion) => {
                    this.updateDetailState({ promotion: promotion });
                    this.setLoaded();
                }
                ),
                catchError(async (error) => {
                    this.setError({ errorMessage: error });
                    this.setLoaded();
                })
            ))
            )
        );
    });
}