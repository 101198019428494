import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { RRule } from 'rrule';
import { CampaignModel } from 'src/app/models/campaign-model';

@Component({
  selector: 'app-campaign',
  templateUrl: './campaign.component.html',
  styleUrls: ['./campaign.component.scss']
})
export class CampaignComponent implements OnInit {
  @Input()
  campaign: CampaignModel;

  @Output()
  onDeletePressed = new EventEmitter();

  @Output()
  onActivatePressed = new EventEmitter();

  @Output()
  onDeactivatePressed = new EventEmitter();

  @Output()
  onEditPressed = new EventEmitter();

  recurrenceDisplayString: string;

  ngOnInit(): void {
    // Do nothing
  }

  ngOnChanges(changes : SimpleChanges) : void {
    if (this.campaign?.recurrencePattern) {
      this.recurrenceDisplayString = new RRule(RRule.parseString(this.campaign.recurrencePattern)).toText();
    }
    else {
      this.recurrenceDisplayString = 'Does not repeat';
    }
  }

  edit() {
    this.onEditPressed.emit(null);
  }

  delete() {
    this.onDeletePressed.emit(null);
  }

  canShowActivateAction(): boolean {
    const result = this.campaign?.isInactive();
    return result;
  }

  canShowArchiveAction(): boolean {
    const result = this.campaign?.isActive() || this.campaign?.isPendingActivation();
    return result;
  }

  canShowEditAction() : boolean {
    const result = this.campaign?.isActive() || this.campaign?.isPendingActivation();
    return result;
  }

  activate() {
    this.onActivatePressed.emit(null);
  }
}
