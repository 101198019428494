import { Injectable } from "@angular/core";
import { ComponentStore } from "@ngrx/component-store";
import { Observable } from "rxjs";
import { catchError, map, switchMap, tap } from "rxjs/operators";
import { CreateStoreLocationModel } from "src/app/models/create-store-location-model";
import { StoreLocationModel } from "src/app/models/store-location-model";
import { StoreLocationService } from "src/app/services/store-location.service";
import { CreateStoreLocationState } from "./create-store-location.state";

const initialState: CreateStoreLocationState = {
    loading: false,
    loaded: false,
    success: false,
    errorMessage: null,
    createStoreLocation: null,
    storeLocation: null,
};

@Injectable({
    providedIn: 'root'
})
export class CreateStoreLocationComponentStore extends ComponentStore<CreateStoreLocationState> {

    constructor(private readonly storeLocationService: StoreLocationService) {
        super(initialState);
    }

    readonly createStoreLocationModel$: Observable<CreateStoreLocationModel> = this.select(state => state.createStoreLocation);
    readonly storeLocation$: Observable<StoreLocationModel> = this.select(state => state.storeLocation);
    readonly loading$: Observable<boolean> = this.select(state => state.loading);
    readonly loaded$: Observable<boolean> = this.select(state => state.loaded);
    readonly success$: Observable<boolean> = this.select(state => state.success);
    readonly errorMessage$: Observable<any> = this.select(state => state.errorMessage);

    readonly viewModel$ = this.select(
        this.createStoreLocationModel$,
        this.storeLocation$,
        this.loaded$,
        this.loading$,
        this.success$,
        this.errorMessage$,
        (createStoreLocationModel, storeLocation, loaded, loading, success, errorMessage) => ({
            createStoreLocationModel, storeLocation
            , loaded, loading, success, errorMessage
        })
    );

    readonly setInitial = this.updater(( _ : CreateStoreLocationState) => {
        return {
            ...initialState
        };
    });

    readonly setLoading = this.updater((state: CreateStoreLocationState) => {
        return {
            ...state,
            loading: true,
            loaded: false,
        };
    });

    readonly setLoaded = this.updater((state: CreateStoreLocationState) => {
        return {
            ...state,
            loading: false,
            loaded: true,
        };
    });

    readonly updateCreateStoreLocationState = this.updater((state: CreateStoreLocationState, value: {
        createStoreLocationModel: CreateStoreLocationModel
    }) => {
        return {
            ...state,
            loading: false,
            loaded: true,
            success: true,
            createStoreLocation: value.createStoreLocationModel
        };
    });

    readonly updateStoreLocationState = this.updater((state: CreateStoreLocationState, value: {
        storeLocation: StoreLocationModel
    }) => {
        return {
            ...state,
            loading: false,
            loaded: true,
            success: true,
            storeLocation: value.storeLocation
        };
    });

    readonly setError = this.updater((state: CreateStoreLocationState, value: {
        errorMessage: String
    }) => {
        return {
            ...state,
            loading: false,
            loaded: true,
            success: false,
            errorMessage: value.errorMessage,
        };
    });

    readonly createStoreLocation = this.effect((createStoreLocationParams$: Observable<{ companyId: string, createStoreLocation: CreateStoreLocationModel }>) => {
        this.setLoading();
        return createStoreLocationParams$.pipe(
            switchMap((params => this.storeLocationService.createStoreLocation(params.companyId, params.createStoreLocation).pipe(
                map((storeLocation) => {
                    this.updateStoreLocationState({ storeLocation: storeLocation });
                    this.setLoaded();
                    this.setInitial();
                }),
                catchError(async (error) => {
                    this.setError({ errorMessage: error });
                    this.setLoaded();
                    this.setInitial();
                })
            ))
            )
        );
    });
}