import { Component, Input, SimpleChanges } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { StoreLocationOption } from 'src/app/models/store-location-option-model';

@Component({
  selector: 'app-store-location-options-edit',
  templateUrl: './store-location-options-edit.component.html',
  styleUrls: ['./store-location-options-edit.component.scss']
})
export class StoreLocationOptionsEditComponent {
  @Input()
  storeLocationDetailsFormGroup: UntypedFormGroup;

  @Input()
  storeLocationOptions : StoreLocationOption[];

  ngOnChanges(changes: SimpleChanges) {
    if (changes.storeLocationOptions?.currentValue
      && this.storeLocationOptions
    ) {
      this._patchStoreLocationOptionsValues();
    }
  }

  private _patchStoreLocationOptionsValues() : void {
    const optionVeganOptions = this.storeLocationOptions.some(e => e === StoreLocationOption.VEGAN_OPTIONS);
    const optionVegetarianOptions = this.storeLocationOptions.some(e => e === StoreLocationOption.VEGETARIAN_OPTIONS);
    const optionGlutenFreeOptions = this.storeLocationOptions.some(e => e === StoreLocationOption.GLUTEN_FREE_OPTIONS);
    const optionBioOrganic = this.storeLocationOptions.some(e => e === StoreLocationOption.BIO_ORGANIC);
    const optionBioDynamic = this.storeLocationOptions.some(e => e === StoreLocationOption.BIO_DYNAMIC);
    const optionZeroWaste = this.storeLocationOptions.some(e => e === StoreLocationOption.ZERO_WASTE);
    const optionPlasticFree = this.storeLocationOptions.some(e => e === StoreLocationOption.PLASTIC_FREE);
    const optionPalmOilFree = this.storeLocationOptions.some(e => e === StoreLocationOption.PALM_OIL_FREE);

    this.storeLocationDetailsFormGroup.patchValue({ optionVeganOptions: optionVeganOptions });
    this.storeLocationDetailsFormGroup.patchValue({ optionVegetarianOptions: optionVegetarianOptions });
    this.storeLocationDetailsFormGroup.patchValue({ optionGlutenFreeOptions: optionGlutenFreeOptions });
    this.storeLocationDetailsFormGroup.patchValue({ optionBioOrganic: optionBioOrganic });
    this.storeLocationDetailsFormGroup.patchValue({ optionBioDynamic: optionBioDynamic });
    this.storeLocationDetailsFormGroup.patchValue({ optionZeroWaste: optionZeroWaste });
    this.storeLocationDetailsFormGroup.patchValue({ optionPlasticFree: optionPlasticFree });
    this.storeLocationDetailsFormGroup.patchValue({ optionPalmOilFree: optionPalmOilFree });
  }
}
