<div class="container">
  <div *ngIf="showPricingBanner">
    <app-buy-subscription-plan-banner></app-buy-subscription-plan-banner>
  </div>
  <div *ngIf="unpaidInvoice$ | async">
    <app-unpaid-invoice-banner
      [unpaidInvoice]="unpaidInvoice$ | async"
    ></app-unpaid-invoice-banner>
  </div>
  <mat-tab-group >
    <mat-tab label="Orders">
      <app-current-subscriptionplan-order [currentSubscriptionPlanOrder]="(currentActiveSubscriptionPlanOrder$ | async)"></app-current-subscriptionplan-order>
    </mat-tab>
    <mat-tab label="Invoices">
      <app-invoices [currentSubscriptionPlanOrder]="(currentActiveSubscriptionPlanOrder$ | async)" (onCancelSubscriptionPlanOrder)="onCancelSubscriptionPlanOrder()"></app-invoices>
    </mat-tab>
    <mat-tab label="Billing Information">
      <div *ngIf="readMode; else editMode">
        <div class="container-billing-details">
          <app-billing-details
            [billingDetails]="billingDetails$ | async"
            (onEditPressed)="onEditBillingDetails($event)"
          ></app-billing-details>
        </div>
      </div>
    </mat-tab>
  </mat-tab-group>
</div>

<ng-template #editMode>
  <div class="container-billing-details">
    <app-billing-details-edit
      [billingDetails]="billingDetails$ | async"
      [billingDetailsFormGroup]="billingDetailsFormGroup"
      (onCancelPressed)="onCancelBillingDetails($event)"
      (onSavePressed)="onSaveBillingDetails($event)"
    ></app-billing-details-edit>
  </div>
</ng-template>
