<div [ngClass]="{
  'container-xlarge': isXLarge(),
  'container-large': isLarge(),
  'container-medium': isMedium(),
  'container-small': isSmall(),
  'container-xsmall': isXSmall()
}">
  <h1>Thank you for joining our community!</h1>
  <img src="/assets/img/planetvg_icon.png" alt="planetvg-logo" />
  <div class="container-card">
    <app-signin (onSignInPressed)="signIn()"></app-signin>
  </div>
</div>
<app-bottom-menu></app-bottom-menu>