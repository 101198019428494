import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, mergeMap, of, catchError, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { UnknownErrorException } from '../core/exceptions/unknown-error-exception';
import { ValidationException } from '../core/exceptions/validation-exception';

@Injectable({
  providedIn: 'root'
})
export class CompanyNameVerifyService {

  constructor(private readonly http: HttpClient) {
    // Do nothing
  }

  checkCompanyNameTaken(companyName: string): Observable<boolean> {
    const url = environment.apiUrl + '/api/partner/companies/name/' + companyName;
    return this.http.get(url, { observe: 'response' }).pipe(
      mergeMap(response => {
        if (response.status == 200) {
          return of(true);
        }
        return of(false);
      }),
      catchError((response: HttpErrorResponse) => {
        if(response.status == 404){
          return of(false);
        }
        if (response.status == 400) {
          if (response.error.violations) {
            const validationException = new ValidationException(response.error.violations);
            return throwError(() => validationException);
          }
          return throwError(() => new UnknownErrorException());
        }
        return throwError(() => this.handleError('checkCompanyNameTaken', null));
      })
    );
  }

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      console.error(error); // log to console instead
      // Let the app keep running by returning an empty result.
      return of(result);
    };
  }
}
