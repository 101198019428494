import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { of } from "rxjs";
import { catchError, map, mergeMap } from "rxjs/operators";
import { SubscriptionPlanService } from "src/app/services/subscription-plan.service";
import { subscriptionPlansGetAction, subscriptionPlansGetActionFailure, subscriptionPlansGetActionSuccess } from "./subscriptionplans.actions";


@Injectable()
export class SubscriptionPlanGetEffects {
    constructor(private actions$: Actions, private subscriptionPlanService: SubscriptionPlanService) { }

    subscriptionPlansGetEffect$ = createEffect(() =>
        this.actions$.pipe(
            ofType(subscriptionPlansGetAction),
            mergeMap(
                () => {
                    return this.subscriptionPlanService.getSubscriptionPlans().pipe(
                        map(
                            data =>
                                subscriptionPlansGetActionSuccess({ subscriptionPlans: data })
                        )
                    )
                }
            ),
            catchError(error => {
                var errorMessage = this.errorMessage(error);
                return of(subscriptionPlansGetActionFailure({ error: errorMessage }));
            })
        )
    );

    private errorMessage(error: any): string {
        if (error.status == 409) {
            return error.error;
        }
        return error.error;
    }
}