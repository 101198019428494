import { Injectable } from "@angular/core";
import { ComponentStore } from "@ngrx/component-store";
import { Observable } from "rxjs";
import { catchError, map, switchMap } from "rxjs/operators";
import { BillingDetailsState } from "./billing-details.state";
import { BillingDetailsModel } from "src/app/models/billing-details-model";
import { BillingDetailsService } from "src/app/services/billing-details.service";
import { UpdateBillingDetailsModel } from "src/app/models/update-billing-details-model";


const initialState: BillingDetailsState = {
    loading: false,
    loaded: false,
    success: false,
    updateSuccess: false,
    errorMessage: null,
    billingDetails : null,
}

@Injectable({
    providedIn: 'root'
})
export class BillingDetailsComponentStore extends ComponentStore<BillingDetailsState> {
    constructor(private readonly billingDetailsService : BillingDetailsService) {
        super(initialState);
    }

    readonly billingDetails$: Observable<BillingDetailsModel> = this.select(state => state.billingDetails);
    readonly loading$: Observable<boolean> = this.select(state => state.loading);
    readonly loaded$: Observable<boolean> = this.select(state => state.loaded);
    readonly success$: Observable<boolean> = this.select(state => state.success);
    readonly updateSuccess$: Observable<boolean> = this.select(state => state.updateSuccess);
    readonly errorMessage$: Observable<any> = this.select(state => state.errorMessage);

    readonly viewModel$ = this.select(
        this.billingDetails$,
        this.loaded$,
        this.loading$,
        this.success$,
        this.errorMessage$,
        (billingPortalSession, loaded, loading, success, errorMessage) => ({
            billingPortalSession, loaded, loading, success, errorMessage
        })
    );

    readonly setInitial = this.updater(( _ : BillingDetailsState) => {
        return {
            ...initialState
        };
    });

    readonly setLoading = this.updater((state: BillingDetailsState) => {
        return {
            ...state,
            loading: true,
            loaded: false,
        };
    });

    readonly setLoaded = this.updater((state: BillingDetailsState, value: {
        billingDetails: BillingDetailsModel
    }) => {
        return {
            ...state,
            loading: false,
            loaded: true,
            success: true,
            billingDetails: value.billingDetails
        };
    });

    readonly setUpdated = this.updater((state: BillingDetailsState, value: {
        billingDetails: BillingDetailsModel
    }) => {
        return {
            ...state,
            loading: false,
            loaded: true,
            updateSuccess: true,
            billingDetails: value.billingDetails
        };
    });

    readonly setError = this.updater((state: BillingDetailsState, value: {
        errorMessage: string
    }) => {
        return {
            ...state,
            loading: false,
            loaded: true,
            success: false,
            errorMessage: value.errorMessage,
        };
    });

    readonly getBillingDetails = this.effect((detailParams$: Observable<{ businessAccountId: string }>) => {
        this.setLoading();
        return detailParams$.pipe(
            switchMap((params => this.billingDetailsService.getBillingDetails(params.businessAccountId).pipe(
                map((billingDetails) => {
                    this.setLoaded({ billingDetails: billingDetails });
                }
                ),
                catchError(async (error) => {
                    this.setError({ errorMessage: error });
                    this.setInitial();
                })
            ))
            )
        );
    });

    readonly updateBillingDetails = this.effect((detailParams$: Observable<{ businessAccountId: string, updateBillingDetails: UpdateBillingDetailsModel }>) => {
        this.setLoading();
        return detailParams$.pipe(
            switchMap((params => this.billingDetailsService.updateBillingDetails(params.businessAccountId, params.updateBillingDetails).pipe(
                map((billingDetails) => {
                    this.setUpdated({ billingDetails: billingDetails });
                }
                ),
                catchError(async (error) => {
                    this.setError({ errorMessage: error });
                    this.setInitial();
                })
            ))
            )
        );
    });
}