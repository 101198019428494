import { Injectable } from "@angular/core";
import { ComponentStore } from "@ngrx/component-store";
import { Observable } from "rxjs";
import { catchError, map, switchMap } from "rxjs/operators";
import { BillingState } from "./billing.state";
import { SubscriptionPlanOrderService } from "src/app/services/subscription-plan-order.service";
import { ActiveSubscriptionPlanOrderModel } from "src/app/models/active-subscription-plan-order-model";

const initialState: BillingState = {
    loading: false,
    loaded: false,
    success: false,
    updateSuccess: false,
    subscriptionCancellationSuccess: false,
    errorMessage: null,
    activeSubscriptionPlanOrders: null,
    currentPage: 0,
    totalElements: 0,
}

@Injectable({
    providedIn: 'root'
})
export class BillingComponentStore extends ComponentStore<BillingState> {
    constructor(private readonly subscriptionPlanOrderService: SubscriptionPlanOrderService) {
        super(initialState);
    }

    readonly activeSubscriptionPlanOrders$ = this.select(state => state.activeSubscriptionPlanOrders);
    readonly currentPage$: Observable<number> = this.select(state => state.currentPage);
    readonly totalElements$: Observable<number> = this.select(state => state.totalElements);
    readonly loading$: Observable<boolean> = this.select(state => state.loading);
    readonly loaded$: Observable<boolean> = this.select(state => state.loaded);
    readonly success$: Observable<boolean> = this.select(state => state.success);
    readonly updateSuccess$: Observable<boolean> = this.select(state => state.updateSuccess);
    readonly subscriptionCancellationSuccess$: Observable<boolean> = this.select(state => state.subscriptionCancellationSuccess);
    readonly errorMessage$: Observable<any> = this.select(state => state.errorMessage);

    readonly viewModel$ = this.select(
        this.activeSubscriptionPlanOrders$,
        this.currentPage$,
        this.totalElements$,
        this.loaded$,
        this.loading$,
        this.success$,
        this.errorMessage$,
        (activeSubscriptionPlanOrders, currentPage, totalElements, loaded, loading, success, errorMessage) => ({
            activeSubscriptionPlanOrders, currentPage, totalElements, loaded, loading, success, errorMessage
        })
    );

    readonly setInitial = this.updater((_: BillingState) => {
        return {
            ...initialState
        };
    });

    readonly setLoading = this.updater((state: BillingState) => {
        return {
            ...state,
            loading: true,
            loaded: false,
        };
    });

    readonly setLoaded = this.updater((state: BillingState, value: {
    }) => {
        return {
            ...state,
            loading: false,
            loaded: true,
            success: true,
        };
    });

    readonly setCancelSuccess = this.updater((state: BillingState, value: {
    }) => {
        return {
            ...state,
            loading: false,
            loaded: true,
            subscriptionCancellationSuccess: true,
        };
    });

    readonly setError = this.updater((state: BillingState, value: {
        errorMessage: string
    }) => {
        return {
            ...state,
            loading: false,
            loaded: true,
            success: false,
            errorMessage: value.errorMessage,
        };
    });

    readonly setActiveSubscriptionPlanOrders = this.updater((state: BillingState, value: {
        activeSubscriptionPlanOrders: ActiveSubscriptionPlanOrderModel[], currentPage: number, totalElements: number
    }) => {
        return {
            ...state,
            loading: false,
            loaded: true,
            success: true,
            errorMessage: null,
            activeSubscriptionPlanOrders: value.activeSubscriptionPlanOrders,
            currentPage: value.currentPage,
            totalElements : value.totalElements
        };
    });

    readonly cancelSubscriptionPlanOrder = this.effect((detailParams$: Observable<{ companyId: string, subscriptionPlanOrderId: string }>) => {
        this.setLoading();
        return detailParams$.pipe(
            switchMap((params => this.subscriptionPlanOrderService.cancelSubscriptionPlanOrder(params.companyId, params.subscriptionPlanOrderId).pipe(
                map((_) => {
                    this.setCancelSuccess({});
                    this.setInitial();
                }
                ),
                catchError(async (error) => {
                    this.setError({ errorMessage: error });
                    this.setInitial();
                })
            ))
            )
        );
    });

    readonly getActiveSubscriptionPlanOrders = this.effect((detailParams$: Observable<{ companyId: string, currentPage: number, pageSize: number }>) => {
        this.setLoading();
        return detailParams$.pipe(
            switchMap((params => this.subscriptionPlanOrderService.getActiveSubscriptionPlanOrder(params.companyId /* TODO Paging */).pipe(
                map((data) => {
                    this.setActiveSubscriptionPlanOrders({
                        activeSubscriptionPlanOrders: data.content,
                        currentPage: data.number,
                        totalElements: data.totalElements
                    });
                }
                ),
                catchError(async (error) => {
                    this.setError({ errorMessage: error });
                    this.setInitial();
                })
            ))
            )
        );
    });
}