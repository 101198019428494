<div class="container">
  <mat-card appearance="outlined" class="mat-component-background mat-elevation-z2">
    <mat-card-header>
      <mat-card-title> Company Details </mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <div class="container-content">
        <div class="container-column">
          <mat-form-field appearance="outline">
            <mat-label>Company Name</mat-label>
            <input
              matInput
              readonly
              value="{{ businessAccount?.company.name }}"
            />
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>Description</mat-label>
            <textarea
              matInput
              readonly
              value="{{ businessAccount?.company.description }}"
            ></textarea>
          </mat-form-field>
          
          <mat-form-field appearance="outline">
            <mat-label>Website</mat-label>
            <input
              matInput
              readonly
              value="{{ businessAccount?.company.url }}"
            />
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>Company Email</mat-label>
            <input
              matInput
              readonly
              value="{{ businessAccount?.company.email }}"
            />
          </mat-form-field>
        </div>
      </div>
    </mat-card-content>
    <mat-card-actions class="container-actions">
        <button mat-icon-button (click)="edit()">
          <mat-icon>edit</mat-icon>
        </button>
    </mat-card-actions>
  </mat-card>
</div>
