import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { RecurrenceDialogComponent } from 'src/app/core/components/dialogs/recurrence-dialog/recurrence-dialog.component';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Subject, takeUntil } from 'rxjs';
import { RRuleUtils } from 'src/app/core/utilities/rrule-utils';

@Component({
  selector: 'app-recurrence-field',
  templateUrl: './recurrence-field.component.html',
  styleUrls: ['./recurrence-field.component.scss']
})
export class RecurrenceFieldComponent {
  @Output()
  onRecurrencePatternChanged = new EventEmitter();

  @Input()
  recurrencePattern: string;

  @Input()
  recurrenceStartDate: Date;

  hasCustomRecurrence: boolean = false;
  reccurenceRuleFormGroup: FormGroup;

  selectedValue: string;
  selectedValueDisplay: string;

  private ngUnsubscribeState = new Subject<void>();

  constructor(private recurrenceRuleDialog: MatDialog, private formBuilder: FormBuilder) {
    // Do nothing
  }

  ngOnInit(): void {
    this.reccurenceRuleFormGroup = this.formBuilder.group({
      recurrencePattern: [''],
    });

    this.updateRecurrencePattern();

    this.reccurenceRuleFormGroup.controls.recurrencePattern.valueChanges.pipe(takeUntil(this.ngUnsubscribeState)).subscribe(value => {
      if (value === 'custom-recurrence') {
        this.computeRecurrenceRule();
      }
      else if (value === 'does-repeat') {
        this.hasCustomRecurrence = true;
          this.onRecurrencePatternChanged.emit(this.recurrencePattern);
      }
      else {
        this.onRecurrencePatternChanged.emit('');
      }
    });
  }

  public ngOnDestroy(): void {
    this.ngUnsubscribeState.next();
    this.ngUnsubscribeState.complete();
  }

  public ngOnChanges(changes: SimpleChanges) {
    if (changes.recurrencePattern) {
      this.updateRecurrencePattern();
    }
  }

  private updateRecurrencePattern() {
    if (this.recurrencePattern) {
      this.selectedValue = 'does-repeat';

      this.hasCustomRecurrence = true;
      this.selectedValueDisplay = this.getRecurrenceDisplayText();
    }
    else {
      this.selectedValue = 'does-not-repeat';
      this.hasCustomRecurrence = false;
    }

    this.reccurenceRuleFormGroup?.patchValue({ 'recurrencePattern': this.selectedValue });
  }

  private computeRecurrenceRule() {
    const startDate = this.recurrenceStartDate ? this.recurrenceStartDate : new Date();
    const dialogRef = this.recurrenceRuleDialog.open(RecurrenceDialogComponent, {
      data: {
        title: "Custom Recurrence",
        startDate: startDate,
        recurrencePattern: this.recurrencePattern,
        confirm: "Done",
        cancel: 'Cancel',
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.recurrencePattern = result.recurrencePattern;
      }

      this.updateRecurrencePattern();
    });
  }

  private getRecurrenceDisplayText(): string {
    return RRuleUtils.toDisplayText(this.recurrencePattern);
  }
}
