<div class="container" [formGroup]="reccurenceRuleFormGroup">
  <mat-form-field appearance="outline" class="container-recurrence">
    <mat-label>Recurrence</mat-label>
    <mat-select
      formControlName="recurrencePattern"
      panelClass="mat-app-background"
      [(value)]="selectedValue"
    >
      <mat-option value="does-not-repeat">Does not repeat</mat-option>
      <mat-option *ngIf="hasCustomRecurrence" value="does-repeat">{{selectedValueDisplay }}</mat-option>
      <mat-option value="custom-recurrence">Custom recurrence</mat-option>
    </mat-select>
  </mat-form-field>
</div>
