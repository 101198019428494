import { countryList } from "../core/components/country-selector/country-list";
import { CompanyModel } from "./company-model"
import { CategoryBagelShop, CategoryBakery, CategoryBar, CategoryBarberShop, CategoryBedAndBreakfast, CategoryBodyPieringShop, CategoryCafeCoffeeAndTeaHouse, CategoryCafeteria, CategoryDryCleaner, CategoryFarmersMarket, CategoryFastFoodRestaurant, CategoryFitnessCenter, CategoryFoodStand, CategoryFoodTruck, CategoryFruitAndVegetableStore, CategoryGroceryStore, CategoryHairSalon, CategoryHealthFoodStore, CategoryHotel, CategoryIceCreamParlor, CategoryJuiceBar, CategoryMassageClinic, CategoryNailSalon, CategoryPetService, CategoryRestaurant, CategoryRetailStore, CategorySkinCareClinic, CategorySpa, StoreLocationCategory, CategoryTattooParlor, CategoryYogaStudio } from "./store-location-category-model";
import { StoreLocationOption } from "./store-location-option-model";
import { StoreLocationType, TypeOther, TypeVegan, TypeVegetarian } from "./store-location-type-model";

export class StoreLocationModel {
    id: number;
    company: CompanyModel;
    name: string;
    description: string;
    addressLine1: string;
    addressLine2: string;
    postalCode: string;
    city: string;
    country: string;
    phoneNumber: string;
    storeLocationStatus: string;
    storeLocationType: StoreLocationType;
    storeLocationCategory: StoreLocationCategory;
    storeLocationOptions: StoreLocationOption[];

    public isActive(): boolean {
        return this.storeLocationStatus.toUpperCase() === "ACTIVE";
    }

    public isArchived(): boolean {
        return this.storeLocationStatus.toUpperCase() === "ARCHIVE";
    }

    public toStoreLocationTypeString(): string {
        switch (this.storeLocationType) {
            case StoreLocationType.VEGAN:
                return TypeVegan;
            case StoreLocationType.VEGETARIAN:
                return TypeVegetarian;
            case StoreLocationType.OTHER:
                return TypeOther;
            default:
                return "Unknown";
        }
    }

    public toStoreLocationCategoryString(): string {
        switch (this.storeLocationCategory) {
            case StoreLocationCategory.BAGEL_SHOP:
                return CategoryBagelShop;
            case StoreLocationCategory.BAKERY:
                return CategoryBakery;
            case StoreLocationCategory.BAR:
                return CategoryBar;
            case StoreLocationCategory.BARBER_SHOP:
                return CategoryBarberShop;
            case StoreLocationCategory.BED_AND_BREAKFAST:
                return CategoryBedAndBreakfast;
            case StoreLocationCategory.BODY_PIERCING_SHOP:
                return CategoryBodyPieringShop;
            case StoreLocationCategory.CAFE_COFFEE_AND_TEA_HOUSE:
                return CategoryCafeCoffeeAndTeaHouse;
            case StoreLocationCategory.CAFETERIA:
                return CategoryCafeteria;
            case StoreLocationCategory.DRY_CLEANER:
                return CategoryDryCleaner;
            case StoreLocationCategory.JUICE_BAR:
                return CategoryJuiceBar;
            case StoreLocationCategory.FARMERS_MARKET:
                return CategoryFarmersMarket;
            case StoreLocationCategory.FAST_FOOD_RESTAURANT:
                return CategoryFastFoodRestaurant;
            case StoreLocationCategory.FITNESS_CENTER:
                return CategoryFitnessCenter;
            case StoreLocationCategory.FOOD_STAND:
                return CategoryFoodStand;
            case StoreLocationCategory.FOOD_TRUCK:
                return CategoryFoodTruck;
            case StoreLocationCategory.FRUIT_AND_VEGETABLE_STORE:
                return CategoryFruitAndVegetableStore;
            case StoreLocationCategory.GROCERY_STORE:
                return CategoryGroceryStore;
            case StoreLocationCategory.HAIR_SALON:
                return CategoryHairSalon;
            case StoreLocationCategory.HEALTH_FOOD_STORE:
                return CategoryHealthFoodStore;
            case StoreLocationCategory.HOTEL:
                return CategoryHotel;
            case StoreLocationCategory.ICE_CREAM_PARLOR:
                return CategoryIceCreamParlor;
            case StoreLocationCategory.MASSAGE_CLINIC:
                return CategoryMassageClinic;
            case StoreLocationCategory.NAIL_SALON:
                return CategoryNailSalon;
            case StoreLocationCategory.PET_SERVICE:
                return CategoryPetService;
            case StoreLocationCategory.RESTAURANT:
                return CategoryRestaurant;
            case StoreLocationCategory.RETAIL_STORE:
                return CategoryRetailStore;
            case StoreLocationCategory.SKIN_CARE_CLINIC:
                return CategorySkinCareClinic;
            case StoreLocationCategory.SPA:
                return CategorySpa;
            case StoreLocationCategory.TATTOO_PARLOR:
                return CategoryTattooParlor;
            case StoreLocationCategory.YOGA_STUDIO:
                return CategoryYogaStudio;
            default:
                return "Unknown";
        }
    }

    public toCountryString() : string {
        const countryDefault = countryList.find(c => c.code.startsWith(this.country));
        return countryDefault.name;
    }
}