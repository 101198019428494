import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { filter } from 'rxjs';
import { BusinessAccountModel } from 'src/app/models/business-account-model';
import { selectBusinessAccount } from 'src/app/stores/global/app.selectors';

@Component({
  selector: 'app-help-center-store',
  templateUrl: './help-center-store.component.html',
  styleUrls: ['./help-center-store.component.scss']
})
export class HelpCenterStoreComponent {
  isAuthenticated$ = this.oidcSecurityService.isAuthenticated$;
  hasBusinessAccount: boolean = false;
  businessAccount: BusinessAccountModel;

  private _isLarge: boolean
  private _isMedium: boolean;
  private _isSmall: boolean;
  private _isXSmall: boolean;

  constructor(private oidcSecurityService: OidcSecurityService,
    private store: Store<any>,
    private responsive: BreakpointObserver,
  ) {

  }

  ngOnInit(): void {
    this.oidcSecurityService.isAuthenticated$.pipe(filter(isAuthenticated => Boolean(isAuthenticated))).subscribe(
      _ => {
        this.store.select(selectBusinessAccount).pipe(filter(result => Boolean(result))).subscribe((businessAccount) => {
          this.businessAccount = businessAccount;
          this.hasBusinessAccount = true;
        });
      });
    this.responsive.observe([Breakpoints.XSmall, Breakpoints.Small, Breakpoints.Medium, Breakpoints.Large]).subscribe(
      result => {
        const breakpoints = result.breakpoints;

        this._isLarge = false;
        this._isMedium = false;
        this._isSmall = false;
        this._isXSmall = false;

        if (breakpoints[Breakpoints.Medium]) {
          this._isMedium = true;
        }
        else if (breakpoints[Breakpoints.Small]) {
          this._isSmall = true;
        }
        else if (breakpoints[Breakpoints.XSmall]) {
          this._isXSmall = true;
        }
        else {
          this._isLarge = true;
        }
      }
    );
  }

  isLarge(): boolean {
    return this._isLarge;
  }

  isMedium(): boolean {
    return this._isMedium;
  }
  isSmall(): boolean {
    return this._isSmall;
  }
  isXSmall(): boolean {
    return this._isXSmall;
  }

  getMailSupportLink(): string {
    const mailto = "support@planet.vg";
    const mailtoSubject = "Support needed";
    let mailtoBody = "Hi Planet VG Chefs,\n";
    mailtoBody += "\n";
    mailtoBody += "I need support for the following issue:\n";
    mailtoBody += "\n";
    mailtoBody += "<Replace this with your support question>\n";
    mailtoBody += "\n";
    mailtoBody += "Account details\n";
    mailtoBody += "Business account: " + this.businessAccount.id + "\n";
    mailtoBody += "Company: " + this.businessAccount.company.name + "\n";
    mailtoBody += "\n";
    mailtoBody += "Regards,\n";
    mailtoBody += "<Replace with your name>\n";
    mailtoBody += "\n";
    mailtoBody += "\n";

    const mailtoSubjectEncoded = encodeURI(mailtoSubject);
    const mailtoBodyEncoded = encodeURI(mailtoBody);
    return "mailto:" + mailto + "?subject=" + mailtoSubjectEncoded + "&body=" + mailtoBodyEncoded;
  }
}
