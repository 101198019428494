import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component } from '@angular/core';

@Component({
  selector: 'app-product-banner-made-for-animals',
  templateUrl: './product-banner-made-for-animals.component.html',
  styleUrls: ['./product-banner-made-for-animals.component.scss']
})
export class ProductBannerMadeForAnimalsComponent {
  private _isXLarge : boolean;
  private _isLarge: boolean
  private _isMedium: boolean;
  private _isSmall: boolean;
  private _isXSmall : boolean;

  constructor(private responsive: BreakpointObserver) {
    // Do nothing
  }

  ngOnInit(): void {
    // Do nothing
    this.responsive.observe([Breakpoints.XSmall, Breakpoints.Small, Breakpoints.Medium, Breakpoints.Large, Breakpoints.XLarge]).subscribe(
      result => {
        const breakpoints = result.breakpoints;

        this._isXLarge = false;
        this._isLarge = false;
        this._isMedium = false;
        this._isSmall = false;
        this._isXSmall = false;

        if (breakpoints[Breakpoints.Medium]) {
          this._isMedium = true;
        }
        else if (breakpoints[Breakpoints.Small]) {
          this._isSmall = true;
        }
        else if (breakpoints[Breakpoints.XSmall]) {
          this._isXSmall = true;
        }
        else if (breakpoints[Breakpoints.Large]) {
          this._isLarge = true;
        }
        else {
          this._isXLarge = true;
        }
      }
    );
  }

  isXLarge(): boolean {
    return this._isXLarge;
  }

  isLarge(): boolean {
    return this._isLarge;
  }

  isMedium(): boolean {
    return this._isMedium;
  }
  isSmall(): boolean{
    return this._isSmall;
  }
  isXSmall(): boolean{
    return this._isXSmall;
  }
}
