import { UntypedFormGroup } from "@angular/forms";

export function EndDateAfterStartDate(startDateControlName: string, endDateControlName: string) {
    return (formGroup: UntypedFormGroup) => {
        const startDateControl = formGroup.controls[startDateControlName];
        const endDateControl = formGroup.controls[endDateControlName];

        if(startDateControl.errors && !startDateControl.errors.endDateBeforeStartDate){
            // return if another validator has already found an error on the startDateControl
            return; 
         }

        if(endDateControl.errors && !endDateControl.errors.endDateBeforeStartDate){
           // return if another validator has already found an error on the endDateControl
           return; 
        }

        let startDate = new Date(startDateControl.value);
        let endDate = new Date(endDateControl.value);

        if(endDate < startDate){
            if(endDateControl.touched){
                endDateControl.setErrors({endDateBeforeStartDate: true});
            }
        }
    }    
}