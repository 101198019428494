<div class="container">
    <div [ngClass]="{
        'container-quote-xlarge': isXLarge(),
        'container-quote-large': isLarge(),
        'container-quote-medium': isMedium(),
        'container-quote-small': isSmall(),
        'container-quote-xsmall': isXSmall()
      }" >
        Did you know that our servers run on renewable energy?<mat-icon color="primary">mode_comment_outline</mat-icon>
    </div>
</div>
