import { Injectable } from "@angular/core";
import { ComponentStore } from "@ngrx/component-store";
import { Observable } from "rxjs";
import { catchError, switchMap, tap } from "rxjs/operators";
import { StoreLocationModel } from "src/app/models/store-location-model";
import { UpdateStoreLocationModel } from "src/app/models/update-store-location-model";
import { StoreLocationService } from "src/app/services/store-location.service";
import { UpdateStoreLocationState } from "./update-store-location.state";

const initialState: UpdateStoreLocationState = {
    loading: false,
    loaded: false,
    success: false,
    errorMessage: null,
    updateStoreLocation: null,
    storeLocation: null,
};

@Injectable({
    providedIn: 'root'
})
export class UpdateStoreLocationComponentStore extends ComponentStore<UpdateStoreLocationState> {

    constructor(private readonly storeLocationService: StoreLocationService) {
        super(initialState);
    }

    readonly updateStoreLocationModel$: Observable<UpdateStoreLocationModel> = this.select(state => state.updateStoreLocation);
    readonly storeLocation$: Observable<StoreLocationModel> = this.select(state => state.storeLocation);
    readonly loading$: Observable<boolean> = this.select(state => state.loading);
    readonly loaded$: Observable<boolean> = this.select(state => state.loaded);
    readonly success$: Observable<boolean> = this.select(state => state.success);
    readonly errorMessage$: Observable<any> = this.select(state => state.errorMessage);

    readonly viewModel$ = this.select(
        this.updateStoreLocationModel$,
        this.storeLocation$,
        this.loaded$,
        this.loading$,
        this.success$,
        this.errorMessage$,
        (updateStoreLocation, storeLocation, loaded, loading, success, errorMessage) => ({
            updateStoreLocation, storeLocation
            , loaded, loading, success, errorMessage
        })
    );

    readonly setInitial = this.updater(( _ : UpdateStoreLocationState) => {
        return {
            ...initialState
        };
    });

    readonly setLoading = this.updater((state: UpdateStoreLocationState) => {
        return {
            ...state,
            loading: true,
            loaded: false,
        };
    });

    readonly setLoaded = this.updater((state: UpdateStoreLocationState) => {
        return {
            ...state,
            loading: false,
            loaded: true,
        };
    });

    readonly updateUpdateStoreLocationState = this.updater((state: UpdateStoreLocationState, value: {
        updateStoreLocationModel: UpdateStoreLocationModel
    }) => {
        return {
            ...state,
            loading: false,
            loaded: true,
            success: true,
            updateStoreLocation: value.updateStoreLocationModel
        };
    });

    readonly updateStoreLocationState = this.updater((state: UpdateStoreLocationState, value: {
        storeLocation: StoreLocationModel
    }) => {
        return {
            ...state,
            loading: false,
            loaded: true,
            success: true,
            storeLocation: value.storeLocation
        };
    });

    readonly setError = this.updater((state: UpdateStoreLocationState, value: {
        errorMessage: String
    }) => {
        return {
            ...state,
            loading: false,
            loaded: true,
            success: false,
            errorMessage: value.errorMessage,
        };
    });

    readonly updateStoreLocation = this.effect((updateStoreLocationParams$: Observable<{ companyId: string, updateStoreLocation: UpdateStoreLocationModel }>) => {
        this.setLoading();
        return updateStoreLocationParams$.pipe(
            switchMap((params => this.storeLocationService.updateStoreLocation(params.companyId, params.updateStoreLocation).pipe(
                tap({
                    next: (storeLocation) => {
                        this.updateStoreLocationState({ storeLocation: storeLocation });
                        this.setLoaded();
                        this.setInitial();
                    },
                    error: (e) => { }
                }),
                catchError(async (error) => {
                    this.setError({ errorMessage: error });
                    this.setLoaded();
                    this.setInitial();
                })
            ))
            )
        );
    });
}