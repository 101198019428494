import { Injectable } from "@angular/core";
import { ComponentStore } from "@ngrx/component-store";
import { Observable } from "rxjs";
import { catchError, map, switchMap, tap } from "rxjs/operators";
import { CampaignModel } from "src/app/models/campaign-model";
import { CreateCampaignModel } from "src/app/models/create-campaign-model";
import { CreateStoreLocationModel } from "src/app/models/create-store-location-model";
import { StoreLocationModel } from "src/app/models/store-location-model";
import { CampaignService } from "src/app/services/campaign.service";
import { StoreLocationService } from "src/app/services/store-location.service";
import { CreateCampaignState } from "./create-campaign.state";

const initialState: CreateCampaignState = {
    loading: false,
    loaded: false,
    success: false,
    errorMessage: null,
    createCampaignModel: null,
    campaign: null,
};

@Injectable({
    providedIn: 'root'
})
export class CreateCampaignComponentStore extends ComponentStore<CreateCampaignState> {

    constructor(private readonly campaignService: CampaignService) {
        super(initialState);
    }

    readonly createCampaignModel$: Observable<CreateCampaignModel> = this.select(state => state.createCampaignModel);
    readonly campaign$: Observable<CampaignModel> = this.select(state => state.campaign);
    readonly loading$: Observable<boolean> = this.select(state => state.loading);
    readonly loaded$: Observable<boolean> = this.select(state => state.loaded);
    readonly success$: Observable<boolean> = this.select(state => state.success);
    readonly errorMessage$: Observable<any> = this.select(state => state.errorMessage);

    readonly viewModel$ = this.select(
        this.createCampaignModel$,
        this.campaign$,
        this.loaded$,
        this.loading$,
        this.success$,
        this.errorMessage$,
        (createCampaignModel, campaign, loaded, loading, success, errorMessage) => ({
            createCampaignModel, campaign
            , loaded, loading, success, errorMessage
        })
    );

    readonly setInitial = this.updater(( _ : CreateCampaignState) => {
        return {
            ...initialState
        };
    });

    readonly setLoading = this.updater((state: CreateCampaignState) => {
        return {
            ...state,
            loading: true,
            loaded: false,
        };
    });

    readonly setLoaded = this.updater((state: CreateCampaignState) => {
        return {
            ...state,
            loading: false,
            loaded: true,
        };
    });

    readonly updateCreateCampaignState = this.updater((state: CreateCampaignState, value: {
        createCampaignModel: CreateCampaignModel
    }) => {
        return {
            ...state,
            loading: false,
            loaded: true,
            success: true,
            createCampaignModel: value.createCampaignModel
        };
    });

    readonly updateCampaignState = this.updater((state: CreateCampaignState, value: {
        campaign: CampaignModel
    }) => {
        return {
            ...state,
            loading: false,
            loaded: true,
            success: true,
            campaign: value.campaign
        };
    });

    readonly setError = this.updater((state: CreateCampaignState, value: {
        errorMessage: String
    }) => {
        return {
            ...state,
            loading: false,
            loaded: true,
            success: false,
            errorMessage: value.errorMessage,
        };
    });

    readonly createCampaign = this.effect((createCampaignParams$: Observable<{ companyId: string, createCampaignModel: CreateCampaignModel }>) => {
        this.setLoading();
        return createCampaignParams$.pipe(
            switchMap((params => this.campaignService.createCampaign(params.companyId, params.createCampaignModel).pipe(
                map((campaign) => {
                    if(campaign === null || campaign === undefined){
                        this.setError({ errorMessage: "Can't create a new campaign. You already have reached the maximum active campaigns. Please deactivate or archive a campaign first and try again." });
                        this.setLoaded();
                        this.setInitial();
                    }
                    else {
                        this.updateCampaignState({ campaign: campaign });
                        this.setLoaded();
                        this.setInitial();
                    }
                }),
                catchError(async (error) => {
                    this.setError({ errorMessage: error });
                    this.setLoaded();
                    this.setInitial();
                })
            ))
            )
        );
    });
}