import { Injectable } from '@angular/core';
import { BillingDetailsModel } from '../models/billing-details-model';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Observable, catchError, of, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { UpdateBillingDetailsModel } from '../models/update-billing-details-model';
import { ValidationException } from '../core/exceptions/validation-exception';
import { UnknownErrorException } from '../core/exceptions/unknown-error-exception';

class UpdateBillingDetailsApiModel {
  addressLine1: string;
  addressLine2: string;
  postalCode: string;
  city: string;
  country: string;
  phoneNumber: string;
  vatNumber: string;
}

@Injectable({
  providedIn: 'root'
})
export class BillingDetailsService {
  constructor(private http: HttpClient) { }

  getBillingDetails(businessAccountId: string): Observable<BillingDetailsModel> {
    const httpOptions = {
      headers: new HttpHeaders({
        ContentType: 'application/json',
      }),
    };

    const url = environment.apiUrl + '/api/partner/businessaccounts/' + businessAccountId + '/billingdetails';
    const result = this.http.get<BillingDetailsModel>(url, httpOptions);
    return result.pipe(catchError(this.handleError<BillingDetailsModel>('getBillingDetails', null)));
  }

  updateBillingDetails(businessAccountId: string, billingDetails: UpdateBillingDetailsModel): Observable<BillingDetailsModel> {
    const httpOptions = {
      headers: new HttpHeaders({
        ContentType: 'application/json',
      }),
    };

    const updateBillingDetailsApiModel = new UpdateBillingDetailsApiModel();
    updateBillingDetailsApiModel.phoneNumber = billingDetails.phoneNumber;
    updateBillingDetailsApiModel.addressLine1 = billingDetails.addressLine1;
    updateBillingDetailsApiModel.addressLine2 = billingDetails.addressLine2;
    updateBillingDetailsApiModel.postalCode = billingDetails.postalCode;
    updateBillingDetailsApiModel.city = billingDetails.city;
    updateBillingDetailsApiModel.country = billingDetails.country;

    const url = environment.apiUrl + '/api/partner/businessaccounts/' + businessAccountId + '/billingdetails';
    const result = this.http.put<BillingDetailsModel>(url, updateBillingDetailsApiModel, httpOptions);
    return result.pipe(
      catchError((response: HttpErrorResponse) => {
        if (response.status == 400) {
          if (response.error.violations) {
            const validationException = new ValidationException(response.error.violations);
            return throwError(() => validationException);
          }
          return throwError(() => new UnknownErrorException());
        }
        return throwError(() => this.handleError('updateBillingDetails', null));
      })
    );
  }

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      // this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result);
    };
  }
}
