import { LocationStrategy, PathLocationStrategy } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { InvoiceModel } from 'src/app/models/invoice-model';

@Component({
  selector: 'app-unpaid-invoice-banner',
  templateUrl: './unpaid-invoice-banner.component.html',
  styleUrls: ['./unpaid-invoice-banner.component.scss'],
  providers: [Location, {provide: LocationStrategy, useClass: PathLocationStrategy}],
})
export class UnpaidInvoiceBannerComponent {

  @Input()
  unpaidInvoice : InvoiceModel;

  constructor(private snackbar: MatSnackBar){}

  public payInvoice() : void {
      if(this.unpaidInvoice){
        (window as any).open(this.unpaidInvoice.hostedInvoiceUrl, "_blank");
      }
      else {
        this.snackbar.open('Something went wrong', 'Dismiss');
      }
  }
}
