<div class="container">
  <mat-card class="mat-component-background mat-elevation-z2">
    <mat-card-header>
      <mat-card-title>No active subscription plan</mat-card-title>
    </mat-card-header>
    <mat-card-content
      >You have no active subscription plan. Please buy a new subscription plan.
    </mat-card-content>
    <mat-card-actions>
      <button mat-raised-button (click)="buySubscriptionPlan()" color="primary">
        <mat-icon>storefront</mat-icon>
        Buy
      </button>
    </mat-card-actions>
  </mat-card>
</div>
