<div class="container">
  <h1>General Terms and Conditions</h1>
  <h2><strong>Last Updated:</strong> July 31st, 2024</h2>
  <h2>1. Introduction</h2>
  <p>
    Almost Level 5 B.V. (hereafter referred to as “Almost Level 5”), a
    <em>besloten vennootschap</em> (limited liability company) registered
    with the Dutch chamber of commerce and companies under number 93949138,
    governed by Dutch law, with its registered office at Jan Pieterz.
    Coenstraat 7, 2595WP The Hague, 
    the Netherlands, was authorized by the Netherlands in 2024 to provide
    Services falling within its corporate purpose (hereinafter “Planet VG
    Service(s)").
  </p>
  <p>
    Unless the context clearly implies otherwise, words that are written in the
    singular include the plural and vice versa, reference to one gender includes
    the other genders, reference to a natural person includes legal persons,
    associations, etc. and vice versa, and the parent terms have the
    corresponding meanings.
  </p>
  <p>
    The titles of the articles in the Contract are provided solely for ease of
    review and must not in any way affect how the provisions of the Contract are
    construed.
  </p>
  <h2>2. Purpose</h2>
  <p>
    2.1. These General Terms and Conditions of Sale apply to contracts for the
    supply of equipment and/or the provision of services concluded between
    Almost Level 5 and a business customer (hereinafter the ‘Business
    Customer'). They govern the contractual relationships between Almost Level 5
    and any natural person or legal entity, whether public or private, which
    acts, including via the intermediary of another person acting in its name or
    on its behalf, for purposes relating to its commercial, industrial, artisan
    or liberal profession activities.
  </p>
  <p>
    2.2. These General Terms and Conditions of Sale determine the rights and
    obligations of the parties, together with the Special Conditions of each
    Planet VG Services. The duly filled out and signed subscription application
    form and any amendments, as well as the pricing plan corresponding to the
    Planet VG Services chosen by the Business Customer.
  </p>
  <p>
    2.3. These General Terms and Conditions of Sale apply to the Planet VG
    Services.
  </p>
  <p>
    2.4. In the event of a contradiction between these General Terms and
    Conditions of Sale and the Special Conditions of the Planet VG Services, the
    latter shall prevail over the General Terms and Conditions of Sale.
  </p>
  <h2>
    3. Signing up for the Subscription Contract providing access to the Planet
    VG Service
  </h2>
  <p>
    3.1. A Business Customer who wishes to sign up for a Subscription Contract
    providing access to the Planet VG Services commits to duly fill out and sign
    the subscription application form and submit it to Almost Level 5 along with
    a signed copy of these General Terms and Conditions of Sale.
  </p>
  <p>
    3.2. In particular, the Business Customer must provide Almost Level 5 with
    the following information and documents (hereinafter 'the Data'):
  </p>
  <ul>
    <li>bank account details and/or</li>
    <li>
      proof of payment of a guarantee deposit to Almost Level 5 for the purpose
      of paying the bills and fees, in accordance with the provisions of Article
      8 of these General Terms and Conditions of Sale.
    </li>
  </ul>
  <p>
    3.3. The Business Customer commits to immediately inform Almost Level 5 in
    writing of any changes occurring in the information supplied when signing up
    for the contract application or any financial information (bank account,
    direct debit, credit card number, expiry date, etc.).
  </p>
  <p>
    3.4. The Business Customer shall be solely responsible for ensuring that the
    information it supplies is correct.
  </p>
  <h2>4. Subscription contract</h2>
  <p>
    The Planet VG Services are provided by Almost Level 5 in accordance with the
    provisions of the following documents, which are listed in order of
    precedence:
  </p>
  <ul>
    <li>Privacy Policy,</li>
    <li>Purchase Orders, if any,</li>
    <li>Special Conditions, if any,</li>
    <li>General Terms and Conditions of Sale,</li>
    <li>Users Terms and Conditions.</li>
  </ul>
  <p>
    All of these documents make up the entirety of the contract (hereinafter
    ‘the Contract’). In subscribing to the Planet VG Service(s), the Business
    Customer acknowledges having received all the information needed to reach a
    decision to enter into the Contract and that it was in a position to assess
    said information, and declares that the Planet VG Service(s) concerned
    meet(s) the Business Customer’s requirements. The Contract takes precedence
    over all the Business Customer’s other documents (in particular, general
    and/or specific terms of purchase, business proposals, etc.). It cancels and
    supersedes all prior agreements with the same purpose.
  </p>
  <p>
    These General Terms are applicable to all Planet VG Service(s) to which the
    Business Customer subscribes and that are provided by Almost Level 5,
    including any Planet VG Service(s) provided by Almost Level 5 free of charge
    or on a trial basis.
  </p>
  <h2>5. Reasons for refusal</h2>
  <p>
    Almost Level 5 reserves the right to refuse an application and consequently
    to refuse to provide the Planet VG Services or refuse access to certain
    Planet VG Services in the following specific cases:
  </p>
  <ul>
    <li>
      the Business Customer omits or refuses to provide the information and/or
      documents mentioned in Article 3 of these General Terms and Conditions of
      Sale;
    </li>
    <li>the Business Customer supplied incorrect and/or false information;</li>
    <li>the application form was not duly filled out and/or signed;</li>
    <li>
      the Business Customer did not submit a signed copy of these General Terms
      and Conditions of Sale to Almost Level 5;
    </li>
    <li>
      in the event of late payment or non-payment of one or several bills,
      and/or in the event of fraud committed by the Business Customer;
    </li>
    <li>
      the Business Customer has not supplied a guarantee deposit set out in
      Articles 3 and 9 of these General Terms and Conditions of Sale;
    </li>
  </ul>
  <h2>6. Duration and automatic renewal of the Contract</h2>
  <p>
    6.1. The Planet VG Service subscription contract is concluded annual or
    monthly, according to the provisions of the Special Conditions, which apply
    as soon as they are signed.
  </p>
  <p>
    6.2. The Contract is automatically renewed by tacit agreement for an annual
    or monthly (as applicable) basis at the then-current standard rates, if it
    is not terminated by registered letter following a period of notice of 15
    days, before the expiry of the contract period.
  </p>
  <h2>7. Planet VG Service activation date</h2>
  <p>
    Save in exceptional circumstances, Almost Level 5 commits to activate the
    Planet VG Services within a reasonable period, after the application is
    accepted and after the Business Customer has provided the documents and
    information listed in Article 3 of these General Terms and Conditions of
    Sale, and it has filled out and signed the subscription application
    form and these General Terms and Conditions of Sale and the Special
    Conditions of the Planet VG Service.
  </p>
  <h2>8. Guarantee deposit</h2>
  <p>
    8.1. Almost Level 5 reserves the right to require the payment of a guarantee
    deposit, both when signing up for the Subscription Contract, and during the
    performance of the contract.
  </p>
  <p>
    8.2. This guarantee deposit shall be made according to Almost Level 5’s
    instructions either with a bank or directly with Almost Level 5.
  </p>
  <p>
    8.3. If the guarantee deposit is not paid on the date indicated by Almost
    Level 5, the application to sign up for a Subscription Contract will be
    refused, and the Contract will automatically be terminated without notice or
    other formalities, without the Business Customer being entitled to any
    compensation.
  </p>
  <p>8.4. The amount deposited as a guarantee shall not bear any interest.</p>
  <p>
    8.5. The Business Customer authorizes Almost Level 5 to offset its
    debts with Almost Level 5 using the amount deposited as a guarantee, with no
    distinction regarding the nature or amount of the debt.
  </p>
  <p>
    8.6. At the end of the period, or following the termination of the Contract,
    Almost Level 5 commits to return any remaining balance to the Business
    Customer within a reasonable period, after having set off all the Business
    Customer's debts to Almost Level 5 which are due or yet to become due, with
    the deposited sums.
  </p>
  <h2>9. Billing and Payment Methods</h2>
  <p>
    9.1. Almost Level 5 will send the Business Customer a monthly or annual bill
    which, other than the legal notices, also includes:
  </p>
  <ul>
    <li>the Planet VG Service activation fees if applicable;</li>
    <li>
      the fee corresponding to the Planet VG Services covered by the
      Subscription Contract;
    </li>
    <li>
      the fees relating to any paid-for options chosen by the Business Customer;
    </li>
    <li>
      the fees related to the usage and/or the activation of services, which are
      not included in the Planet VG Services fee covered by the Subscription
      Contract;
    </li>
    <li>
      consultation fees that might have occurred within this billing period and
      prior agreed with the Business Customer.
    </li>
  </ul>
  <p>
    9.2. You may amend your contract to include additional Planet VG features
    through our Customer Service or our Online Partner Portal. When you make
    changes through Customer Service or our Online Dashboard, you are providing
    explicit authorization for Almost Level 5 to process payment on your
    existing Almost Level 5 account and payment method without any further
    authorization necessary from you.
  </p>
  <p>
    9.3. The cost and fees for the Planet VG Services covered by the
    Subscription Contract are payable by the date indicated on the bill.
  </p>
  <p>
    9.4. In all cases, the Business Customer is advised that the monthly fees
    are due in their entirety, even if the Business Customer did not use the
    Planet VG Service, or if it only used it partially, for any reason.
  </p>
  <p>
    9.5. In particular, the fees relating to the first and last month of the
    subscription, for Contracts signed in the course of the month, are due in
    their entirety. In the same way, fees are due in full, in the context of
    Contracts which provide access, on a monthly basis, to the supply of a
    specific quantity of the Planet VG Service (in terms of Promotions, Stamp Cards, 
    Store Locations, etc.) when the Business Customer has not
    consumed the full amount of the services to which its was/were
    entitled.
  </p>
  <p>
    9.6. Any complaints regarding bills must be addressed to Almost Level 5 in
    writing, within a reasonable period after the date the disputed bill was
    issued or the reason for the complaint was discovered. Beyond this period,
    it is assumed that the Business Customer has accepted the principle and
    amount of the bill.
  </p>
  <p>
    9.7. Making a complaint does not exempt the Business Customer from
    its obligation to pay the undisputed portion of the bill within
    the period stipulated in Article 10.3 of these General Terms and Conditions
    of Sale.
  </p>
  <p>
    9.8. The Business Customer commits to pay the bills within the period
    indicated on the invoice.
  </p>
  <p>
    9.9. The Business Customer may obtain a duplicate of the bill from Almost
    Level 5 by a written request. Almost Level 5 reserves the right to bill
    administrative fees of €10 for each duplicate sent by post.
  </p>
  <p>
    9.10. Almost Level 5 debts and bills shall automatically and without any
    formality or notice, bear interest at the legal rate, as from the due date.
  </p>
  <p>
    9.11. Any late payment will result in the Business Customer being obliged to
    pay late payment interest, as well as being obliged to pay a fixed sum of
    €40 for recovery costs.
  </p>
  <p>
    9.12. Other than the fixed sum set out in Article above, Almost Level 5 is
    entitled to claim compensation from the Business Customer for all the other
    recovery costs, such as lawyer's fees to the extent as permitted by law,
    without prejudice to the provisions of Articles 23 and 24 of these General
    Terms and Conditions of Sale.
  </p>
  <p>
    9.13. The Business Customer will be responsible for any sales, use,
    value-added or import taxes, customs duties or similar taxes assessed in
    accordance with applicable law with respect to the provision of the Planet
    VG Services.
  </p>
  <h2>10. The Business Customer's obligations</h2>
  <p>
    10.1. The Business Customer commits to use the Planet VG Services in a
    prudent and responsible manner, in accordance with the provisions of the
    Contract and the laws and regulations in force, in particular regarding
    public order and public decency.
  </p>
  <p>
    10.2. The Business Customer shall ensure that its employees use the Planet
    VG Services in accordance with the provisions of the Contract and the laws
    and regulations in force, in particular regarding public order and public
    decency.
  </p>
  <p>
    10.3. The Business Customer commits to only use the Planet VG Services for the
    purposes of its own professional, commercial, industrial, artisan
    or liberal profession activities.
  </p>
  <p>
    10.4. The Business Customer and its employees may not directly or
    indirectly commit acts liable to damage the integrity or reliability of the
    Planet VG Services.
  </p>
  <p>
    10.5. The Business Customer commits to only use Application approved under
    the Dutch regulations in force and which are in perfect working order. The
    Business Customer shall not connect any other element that may cause
    disruptions to the Planet VG Services to the devices and their accessories.
  </p>
  <p>
    10.6. The Business Customer is aware that by activating, consuming and/or
    using, directly and/or via its employees, services not included in the
    Planet VG Services fee covered by the Subscription Contract, such as
    spending Planet VG stamps and sending messages, it shall
    exclusively bear the fees related to the usage and/or activation of such
    services, even if it had not agreed to this usage or these
    activations, and even if its employees acted outside their duties
    or for purely private purposes.
  </p>
  <p>
    10.7. The Business Customer commits to release and hold harmless Almost
    Level 5 of any conviction that may be given to it due to improper use of the
    Planet VG Service by the Business Customer and/or its employees,
    and/or due to the Business Customer or its employees violating one
    or several obligations arising from the Contract and/or the laws and
    regulations in force.
  </p>
  <p>
    10.8. The Business Customer commits to release and hold harmless Almost
    Level 5 of any fees related to the usage and/or activation of services not
    included in the Planet VG Service fee covered by the Subscription Contract.
  </p>
  <p>
    10.9. The non-performance or late performance of any of its
    obligations arising from the Contract and the General Terms and Conditions
    of Sale shall lead to the Business Customer having to pay damages and
    interest.
  </p>
  <p>
    10.10. The Business Customer commits to not use our Planet VG Services in
    any way or take any action that causes, or may cause, damage to the Planet
    VG Services or impairment of the performance, availability or accessibility
    of the Planet VG Service;
  </p>
  <p>
    10.11. The Business Customer commits to not use our Planet VG Services in
    any way that is unlawful, illegal, fraudulent or harmful, or in connection
    with any unlawful, illegal, fraudulent or harmful purpose or activity;
  </p>
  <p>
    10.12. The Business Customer commits to not use our Planet VG Services to
    copy, store, host, transmit, post, send, use, publish or distribute any
    material which:
  </p>
  <ul>
    <li>
      consists of (or is linked to) any spyware, computer virus, Trojan horse,
      worm, keystroke logger, rootkit or other malicious computer software;
    </li>
    <li>do not comply with the content rules set out in Article 12</li>
  </ul>
  <p>
    10.13. The Business Customer commits to ensure that all the information you
    supply to us through our Planet VG Services, or in relation to our Planet VG
    Services, is true, accurate, current, complete and non-misleading;
  </p>
  <p>
    10.14. The Business Customer will not use any Planet VG Services and/or
    Planet VG Software to send any message, e-mail or other communications which
    violate any applicable regulation, rule, industry protocol or law.
  </p>
  <p>
    10.15. The Business Customer commits to keep their password confidential.
  </p>
  <p>
    10.16. The Business Customer must notify us in writing immediately if they
    become aware of any disclosure of their password.
  </p>
  <h2>11. The Business Customer's responsibility</h2>
  <p>
    11.1. The Business Customer is entirely responsible for the acts, activities
    or omissions of its employees, during and even outside their duties,
    particularly in the case of excessive or abusive use of the Planet VG
    Service by the latter.
  </p>
  <p>
    11.2. The Business Customer must pay damages for any act, activity or
    omission by its employees which contravenes the provisions of the
    Contract, the General Terms and Conditions of Sale and/or the laws and
    regulations in force.
  </p>
  <p>
    11.3. The Business Customer ensures that all fees and bills relating to the
    use of the Planet VG Service are paid, even in the event of excessive use or
    misuse by one of its employees, without being able to raise
    defenses to Almost Level 5 regarding the use of the Planet VG Service by its
    employees, outside their duties, or for purely private purposes.
  </p>
  <p>
    11.4. The Business Customer is entirely responsible for any act or omission
    by its employees which contravenes the copyright attached to the software
    provided to the Business Customer by Almost Level 5.
  </p>
  <p>
    11.15. The Business Customer is responsible for any activity on Planet VG
    Services arising out of any failure to keep their password confidential, and
    may be held liable for any losses arising out of such a failure.
  </p>
  <h2>12. Business Customer's content: rules</h2>
  <p>
    12.1. By using the Planet VG Services, you will have the opportunity to
    post, send, publish and/or submit content to us or the clients. You warrant
    and represent that your content will comply with these terms and conditions.
  </p>
  <p>
    12.2. Your content must not be illegal or unlawful, must not infringe any
    person's legal rights, and must not be capable of giving rise to legal
    action against any person (in each case in any jurisdiction and under any
    applicable law).
  </p>
  <p>
    12.3. Your content, and the use of your content by us in accordance with
    these terms and conditions, must not:
  </p>
  <ul>
    <li>
      contain or show animal suffering or animal produce, e.g. honey, cow’s
      milk, spareribs, eggs;
    </li>
    <li>be libelous or maliciously false;</li>
    <li>be obscene or indecent;</li>
    <li>
      infringe any copyright, moral right, database right, trademark right,
      design right, right in passing off, or other intellectual property right;
    </li>
    <li>
      infringe any right of confidence, right of privacy or right under data
      protection legislation;
    </li>
    <li>constitute negligent advice or contain any negligent statement;</li>
    <li>
      constitute an incitement to commit a crime, instructions for the
      commission of a crime or the promotion of criminal activity;
    </li>
    <li>be in contempt of any court, or in breach of any court order;</li>
    <li>
      be in breach of racial or religious hatred or discrimination legislation;
    </li>
    <li>be blasphemous;</li>
    <li>be in breach of official secrets legislation;</li>
    <li>be in breach of any contractual obligation owed to any person;</li>
    <li>depict violence in an explicit, graphic or gratuitous manner;</li>
    <li>be pornographic, lewd, suggestive or sexually explicit;</li>
    <li>be untrue, false, inaccurate or misleading;</li>
    <li>
      consist of or contain any instructions, advice or other information which
      may be acted upon and could, if acted upon, cause illness, injury or
      death, or any other loss or damage;
    </li>
    <li>constitute spam;</li>
    <li>
      be offensive, deceptive, fraudulent, threatening, abusive, harassing,
      anti-social, menacing, hateful, discriminatory or inflammatory; or
    </li>
    <li>cause annoyance, inconvenience or needless anxiety to any person.</li>
  </ul>
  <p>
    12.4. Almost Level 5 reserves the right to monitor all content submitted by
    you through the use of the Planet VG Services but at no instance Almost
    Level 5 is obliged to do so. You shall be solely responsible for all content
    you submitted to the Planet VG Services.
  </p>
  <h2>13. Excessive use or misuse of the Planet VG Services</h2>
  <p>
    13.1. The Business Customer is solely responsible for the use of the Planet
    VG Service.
  </p>
  <p>
    13.2. The Business Customer commits to use the Planet VG Service in a
    prudent and responsible manner, abstaining from any excessive use or misuse,
    particularly when it takes advantage of offers which include
    so-called "unlimited" options.
  </p>
  <h2>14. Personal Data</h2>
  <p>
    Under the Contract, Almost Level 5 and Business Customer (hereinafter "the
    Party(ies)"), the Parties may obtain, collect and/or have access to personal
    data (“Personal Data”) within the meaning of Regulation (EU) 2016/679 of the
    European Parliament and of the Council of 27 April 2016 (“GDPR”), concerning
    natural persons and, in particular, the employees and clients of the other
    Party and the employees of the other Party’s sub-contractors and/or
    partners.
  </p>
  <p>
    The Parties undertake to process said Personal Data in compliance with the
    GDPR and each of the Parties warrants to the other that it will comply with
    its statutory and regulatory obligations in this regard.
  </p>
  <p>
    The processing that is the subject of this clause shall be carried out by
    the Parties in the capacity of controller, within the meaning of the GDPR.
    The exclusive purpose of this processing of the Personal Data is the
    formation, management and/or performance of the Contract. The Parties thus
    cannot in any way be regarded as being jointly liable for their processing
    or as each other’s processor.
  </p>
  <p>
    Said Personal Data is intended for the in-house departments of the Party
    that will carry out the processing in accordance with the Contract, which
    need said Personal Data for the formation, management and/or performance of
    said Contract. In this connection, said Personal Data may be transferred and
    disclosed to its sub-contractors, partners and service providers. Said
    Personal Data may also be handed over to the relevant authorities, at their
    request, for the purposes of judicial proceedings, judicial investigations
    and fulfilling requests for information by the authorities, or in order to
    comply with other legal obligations. If the Personal Data is transferred
    outside of the European Union, the Parties undertake to sign the European
    Commission’s Standard Contractual Clauses.
  </p>
  <p>
    The Personal Data that is collected will be retained for the time needed to
    fulfil these purposes, or in accordance with the requirements of the
    applicable regulations.
  </p>
  <p>
    In accordance with the Dutch
    <em>Algemene verordening gegevensbescherming</em> of 28 May 2018, the
    successive amendments thereto and the GDPR, the holders of Personal Data
    benefit from a right of access, rectification and erasure for the data that
    concerns them. They can also request the portability of said data, object to
    the processing of their data or request that the processing of their data be
    restricted. Moreover, the holders of said data may issue directives
    concerning the storage, erasure or disclosure of their personal data after
    their death. Each of the Parties that transfers Personal Data to the other
    Party warrants that the holders of the Personal Data have been informed of
    said rights prior to the collection of the Personal Data.
  </p>
  <p>
    The holders of the Personal Data may exercise these rights by sending their
    requests to the persons who are listed as contact persons in the Contract.
  </p>
  <p>
    For all requests for information concerning the protection of personal data,
    as well as for all notifications of security breaches, said party can also:
  </p>
  <ul>
    <li>
      Contact Almost Level 5 at the address
      <a href="mailto:privacy@almostlevel5.com">privacy&#64;almostlevel5.com</a>
    </li>
    <li>
      Consult the Almost Level 5 Privacy Policy, which is available at the URL:
      <a href="/privacy-policy">https://www.planet.vg/privacy-policy/</a>.
    </li>
  </ul>
  <p>
    Almost Level 5 strongly recommends that you review the Privacy Policy
    closely.
  </p>
  <h2>
    15. Changes to these General Terms and Conditions of Sale and the tariffs
    for the Planet VG Services
  </h2>
  <p>
    Almost Level 5 reserves the right to amend all or part of these General
    Terms and Conditions of Sale, as well as the tariffs that apply to the
    Planet VG Service, without paying compensation to the Business Customer. In
    such case Almost Level 5 will inform the Business Customer of such
    amendments in writing with explicit confirmation that the Business Customer
    has the right to terminate the Contract without any indemnity within a
    period of 30 days as from the receipt of the letter confirming such
    amendment.
  </p>
  <h2>16. Changes to the Planet VG services and/or the equipment</h2>
  <p>
    16.1. Due to requirements related to the operation or organization of the
    Planet VG Services, Almost Level 5 may be obliged to alter the content or
    characteristics of the services covered by the Contract, however it may not
    alter its basic characteristics.
  </p>
  <p>
    16.2. These changes made due to technical reasons do not entitle the
    Business Customer to any compensation.
  </p>
  <p>
    16.3. In the same way, following certain technical constraints and/or
    changes, access to the Planet VG Service may, during the performance of the
    Contract, require a change, replacement or purchase of one or several pieces
    of new equipment such as a new model of mobile telephone, etc. This change,
    replacement or purchase does not entitle the Business Customer to any
    compensation.
  </p>
  <h2>17. Customer service</h2>
  <p>
    A customer service is available in order to, where possible, answer all the
    Business Customer’s questions regarding the Planet VG Service.
  </p>
  <h2>18. No guarantee clause</h2>
  <p>
    Almost Level 5 provides no other guarantees than the conformity of the
    Planet VG Services with all applicable laws and regulations.
  </p>
  <h2>19. Exclusion and/or limitation of liability clause</h2>
  <p>
    19.1. The Business Customer expressly acknowledges and agrees that use of
    the Planet VG Services provided is at its sole risk.
  </p>
  <p>
    19.2. The Planet VG Services are provided "as is" and “as available”, with
    all faults and without warranty of any kind. In case of complaints, Almost
    Level 5 will endeavor to remedy such complaints to the furthest extent
    possible. In case of remaining complaints, the Business Customer has the
    right to terminate the Contract without any indemnity begin due within a
    period of 30 days as from the confirmation by Almost Level 5 or the
    observation by the Business Customer that no remedy is possible. Almost
    Level 5 does not warrant that the functions contained in the Planet VG
    Services will meet your requirements, that the operation of the Planet VG
    Services will be uninterrupted or error-free. No oral or written information
    or advice given by us, or our authorized representative shall create a
    warranty.
  </p>
  <p>
    19.3. Almost Level 5 cannot be held liable, particularly in the following
    cases:
  </p>
  <ul>
    <li>
      If the Contract is terminated due to the Business Customer being in breach
      of any of its obligations arising from the Contract or these General Terms
      and Conditions of Sale;
    </li>
    <li>
      In the event of incorrect use, excessive use or misuse of the Planet VG
      Service;
    </li>
    <li>
      If the Planet VG Service is suspended in accordance with Article 23 of
      these General Terms and Conditions of Sale;
    </li>
    <li>
      If there is a malfunction in the operation or use of any equipment not
      covered by a subscription contract and/or sales contract with Almost Level
      5;
    </li>
    <li>
      If inadequate equipment is used, preventing relevant interoperability with
      the Planet VG Services;
    </li>
    <li>
      In the event of illegality and/or errors affecting the content of the
      information, messages or of any other digital content accessible via the
      Planet VG Services;
    </li>
    <li>
      In the event of illegality and/or errors affecting the information
      provided and the content of the services offered by service providers or
      operators other than Almost Level 5;
    </li>
    <li>
      In the event of billing errors attributable to service providers or
      third-party platforms;
    </li>
    <li>
      If fees related to the usage and/or activation of services by the Business
      Customer which are not included in the Planet VG Services fee covered by
      the Subscription Contract, are billed.
    </li>
  </ul>
  <p>
    19.4. Almost Level 5 cannot be held liable for an amount exceeding the
    monthly subscription fees paid by the Business Customer, particularly in the
    following cases:
  </p>
  <ul>
    <li>
      In the event of brief or prolonged disruptions or interruptions in the
      Planet VG Services caused by works to perform maintenance and/or improve
      the Planet VG Service in general;
    </li>
    <li>
      In the event of brief or prolonged disruptions or interruptions to the
      services supplied by third party service providers, operators or
      platforms;
    </li>
    <li>
      In the event of brief or prolonged disruptions to or interruptions of the
      Planet VG Services caused by the presence of obstacles such as buildings,
      vegetation or landscape;
    </li>
    <li>
      In the event of brief or prolonged disruptions to or interruptions of the
      Planet VG Service caused by force majeure;
    </li>
  </ul>
  <h2>20. Transfer and sub-contract</h2>
  <p>
    20.1. The Subscription Contract is a contract concluded intuitu personae for
    Almost Level 5, and the Planet VG Service covered by the Contract is for the
    exclusive use of the Business Customer.
  </p>
  <p>
    20.2. Consequently, the Contract may not under any circumstances be
    transferred, or subcontracted by the Business Customer, without express
    prior written consent from Almost Level 5.
  </p>
  <p>
    20.3. The Business Customer is solely responsible to Almost Level 5 for the
    performance of all its obligations arising from the Contract and from these
    General Terms and Conditions of Sale.
  </p>
  <p>
    20.4. Almost Level 5 shall have the right to fully or partially transfer its
    rights and obligations arising from the Contract without the consent of the
    Business Customer and without the latter being entitled to any compensation
    due to this transfer.
  </p>
  <h2>21. Public order and public decency</h2>
  <p>
    Almost Level 5 reserves the right to restrict or to prevent access, without
    notice or other formalities, to certain services or information, as well as
    to alter certain services or information, if the latter are likely to
    contravene applicable laws, or public order or public decency.
  </p>
  <h2>22. Intellectual property</h2>
  <p>
    22.1. Almost Level 5 or, as the case may be, a third company, retains
    ownership of the software programs and the related documents which may have
    been provided to the Business Customer in the context of the Planet VG
    Services (hereinafter referred to as ‘Planet VG Software ’).
  </p>
  <p>
    22.2. Almost Level 5 is the sole and exclusive owner of all right, title and
    interest in and to any data provided to Almost Level 5 by the customers of
    the Business Customer or any end user to Almost Level 5 (collectively
    hereinafter referred to as “End Customers') in connection with the Planet VG
    Services, including without limitation all e-mail and/or other contact
    information (“Customer Data”). As such, you shall not, unless the applicable
    customer has separately taken all steps necessary to opt in to provide
    contact information to you, be provided with or otherwise have access to the
    Customer Data. Almost Level 5 agrees to provide aggregated and anonymized
    statistical information that can’t be used to identify, contact, or locate a
    single person, or to identify an individual in context. Business Customers
    agree that they shall at all times comply with Almost Level 5’s privacy
    policy and all applicable law, rules and regulations with respect to all
    Customer Data that they might have received. The parties shall fully
    indemnify each other (and its officers, directors, employees and
    consultants) for all breaches of their obligations under this provision and
    the relevant privacy regulations. Customer Data may include: customer's
    name, number of visits, collected stamps, pictures, etc.
  </p>
  <p>
    22.3. The Business Customer receives a non-exclusive and non-transferable
    right to use Planet VG software, in a manner consistent with the objectives
    and limits defined in the context of the contractual relationship between
    Almost Level 5 and the Business Customer relating to the Planet VG Service,
    for the duration of the Contract.
  </p>
  <p>
    22.4. Almost Level 5 shall be entitled, in its sole discretion and without
    notice to any party, to redesign and/or modify all or any portion of the
    Planet VG Software (including without limitation any mobile application
    and/or software) at any time.
  </p>
  <p>
    22.5. The user license stipulated in Article 22.3. is granted to the
    Business Customer in its capacity as an end user and exclusively
    for its personal use. The Business Customer commits not to
    transfer or lease Planet VG software and the related documents and not to
    permit any use of this software or these documents by third parties.
  </p>
  <p>
    22.6. The Business Customer commits not to copy or have copies made of
    Planet VG software and/or the related documents, in particular by its
    employees. It also commits not to subject Planet VG software to
    de-compilation, analysis or reverse engineering processes, not to create
    derived software and not to use the software in a way other than that set
    out in the context of the contractual relationship between Almost Level 5
    and the Business Customer.
  </p>
  <p>
    22.7. The Business Customer is fully responsible for the use, loss, theft or
    damage of the Planet VG software and/or the related documents, regardless of
    the cause.
  </p>
  <p>
    22.8. When the Contract expires, the Business Customer must return the
    Planet VG software and related documents.
  </p>
  <p>
    22.9 Planet VG is a registered trademark filed at BOIP under 1441250 owned
    by Simulacra and licensed to Almost Level 5.
  </p>
  <h2>23. Exception of non-performance: suspension of the Contract</h2>
  <p>
    23.1. In the event of full or partial non-payment or late payment of a bill,
    Almost Level 5 may suspend the Planet VG Service without prior formal
    notice, without the Business Customer being entitled to any compensation of
    damages due to this suspension.
  </p>
  <p>
    23.2. More generally, in the event of non-performance, improper performance
    or delay in the performance of any of its obligations under this Contract,
    Almost Level 5 may suspend the provision of the service if the Business
    Customer does not perform after having been notified of its improper
    performance, does not remedy such improper performance. In such a case, the
    remaining sums due shall immediately become payable.
  </p>
  <p>
    23.3. Almost Level 5 may also suspend the Planet VG Service in the event of
    proof of fraud or strong suspicion of fraud.
  </p>
  <p>
    23.4. If the thresholds limits are exceeded for Contracts entitling the
    Business Customer to a specific quantity of a Planet VG Service on a monthly
    basis, or if there is an abnormally excessive increase in the quantity
    and/or amount of the Business Customer’s usage, Almost Level 5 reserves the
    right to suspend the Planet VG Service.
  </p>
  <p>
    23.5. Other than the case of force majeure, Almost Level 5 also reserves the
    right to suspend the Planet VG Service without notice or compensation, due
    to technical operating requirements.
  </p>
  <p>
    23.6. In the suspension cases referred to in Article 23 of these General
    Terms and Conditions of Sale, the suspension and reconnection fee for the
    Planet VG Service shall be borne by the Business Customer.
  </p>
  <p>
    23.7. If the Planet VG Service is suspended, due to non-performance,
    improper performance or delay in the performance of any of its obligations
    under this Contract, the Business Customer must still meet all its
    obligations under the Contract. In particular, the Business Customer must
    still pay Almost Level 5 the full amount of the subscription fees that
    become due, until the end of the Contract.
  </p>
  <p>
    23.8. Almost Level 5 reserves the right to automatically terminate the
    Contract, without notice or compensation, ten days after the date of
    suspension if such suspension was based on a lack of payment by the Business
    Customer, if the Business Customer still cannot provide proof that
    it has paid all amounts that are due and payable under the
    Contract.
  </p>
  <h2>24. Termination</h2>
  <p>24.1. Termination by Almost Level 5</p>
  <p>
    Almost Level 5 is entitled to terminate the Contract without any
    compensation or reduction in fees for the Business Customer if the latter is
    in breach of any of its obligations under the Contract, in
    particular:
  </p>
  <ul>
    <li>In the event of non-payment or late-payment of a bill;</li>
    <li>
      If the Contract is suspended, if the Business Customer has failed to
      supply proof as provided in art. 23 within a period of ten days after the
      date of suspension;
    </li>
    <li>
      In the event of misuse, excessive use or fraudulent use of the Planet VG
      Service;
    </li>
    <li>
      If the Business Customer provides false information when signing up for
      the subscription, or fails to provide Almost Level 5 with any changes
      applicable to this information.
    </li>
    <li>
      If the Business Customer fails to pay the guarantee deposit referred to in
      Article 9 of these General Terms and Conditions of Sale;
    </li>
  </ul>
  <p>
    24.2. The Contract shall be terminated automatically by virtue of law if the
    Business Customer files for bankruptcy, liquidation or dissolution.
  </p>
  <p>
    24.3. The Contract shall end automatically by virtue of law when the
    operating authorizations granted to Almost Level 5 by the Dutch authorities
    expire. Any pre-payments and guarantees will be returned to the Business
    Customer after having set-off all the Business Customer's due and payable
    debts.
  </p>
  <p>24.4. Termination by the Business Customer</p>
  <p>
    If the Business Customer terminates the contract before the end of the
    Contract period, as stipulated in the Contract and in accordance with the
    provisions of Article 6 of these General Terms and Conditions of Sale,
    it must pay Almost Level 5 the full subscription fees that become
    due, until the end of the Contract period.
  </p>
  <h2>25. Damages</h2>
  <p>
    25.1. Any excessive use and/or misuse of the Planet VG Service may lead to
    the Business Customer having to pay damages, without prejudice to the
    provisions of Articles 23 and 24 of these General Terms and Conditions of
    Sale.
  </p>
  <p>
    25.2. Any termination and/or suspension of the Contract due to the Business
    Customer being in breach of any of its obligations arising from
    this Contract or these General Terms and Conditions of Sale, the Business
    Customer may be liable to pay damages.
  </p>
  <p>
    25.3. If the Business Customer is in breach of any of its
    obligations arising from the Contract and the General Terms and Conditions
    of Sale, the Business Customer may be liable to pay damages and interest.
  </p>
  <h2>26. Indemnification</h2>
  <p>
    Business Customer agrees to indemnify and hold harmless Almost Level 5, its contractors,
    and its licensors, and their respective directors, officers, employees and
    agents from and against any and all claims and expenses, including
    attorneys' fees, arising out of its use of the Planet VG Services,
    including but not limited to its violation of this Agreement.
  </p>
  <h2>27. Invalidity</h2>
  <p>
    The invalidity of one or several clauses of these General Terms and
    Conditions of Sale will not lead to the invalidity of the other clauses,
    which remain valid and perfectly enforceable.
  </p>
  <h2>28. Penalty clause</h2>
  <p>
    In the event of gross and willful negligence attributable to Almost Level 5,
    Almost Level 5 will fully guarantee the Business Customer for any damages
    the latter may need to pay to any third parties and/or authorities as a
    consequence thereof. Without prejudice to this full guarantee, in the event
    of gross and willful negligence attributable to Almost Level 5, on top of
    such guarantee, the latter shall not be liable to pay a sum of more than one
    year worth of fees in damages to the Business Customer.
  </p>
  <h2>29. Choice of forum clause</h2>
  <p>
    Any dispute concerning the existence, interpretation or performance of the
    Contract and these General Terms and Conditions of Sale shall be subject to
    the exclusive jurisdiction of the courts of The Hague, the Netherlands.
  </p>
  <h2>30. Applicable Law</h2>
  <p>
    Dutch law applies exclusively to the Contract and to these General Terms and
    Conditions of Sale.
  </p>
  <p>
    The Business Customer expressly agrees that it was in a position to
    be aware of the General Terms and Conditions of Sale and accepts them
    without reserve or limitation.
  </p>
</div>
<app-bottom-menu></app-bottom-menu>