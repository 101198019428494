import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';
import { environment } from './../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AccountEmailVerifyService {
  constructor(private http: HttpClient) { }

  checkAccountEmailTaken(accountEmail: string): Observable<boolean> {
    var url = environment.apiUrl + '/api/users/email/' + accountEmail;
    return this.http.get(url, { observe: 'response' }).pipe(
      mergeMap(response => {
        if (response.status == 200) {
          return of(true);
        }
        return of(false);
      }),
      catchError(this.handleError)
    );
  }

  handleError(error) {
    if (error.status == 404) {
      return of(false);
    }
    else {
      let errorMessage = '';
      if (error.error instanceof ErrorEvent) {
        // client-side error
        errorMessage = `Error: ${error.error.message}`;
      } else {
        // server-side error
        errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
      }
      window.alert(errorMessage);
      return throwError(() => errorMessage);
    }
  }
}
