import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { InvoiceModel } from '../models/invoice-model';
import { Observable, catchError, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Page } from '../models/paging/page-model';

@Injectable({
  providedIn: 'root'
})
export class InvoiceService {

  constructor(private http: HttpClient) { }

  getUnpaidInvoices(companyId: string): Observable<Page<InvoiceModel>> {
    let httpParams = new HttpParams();
    httpParams = httpParams.append("status", "OPEN");
    httpParams = httpParams.append("page", 0);
    httpParams = httpParams.append("size", 100);

    const httpOptions = {
      headers: new HttpHeaders({
        ContentType: 'application/json',
      }),
      params: httpParams
    };

    const url = environment.apiUrl + '/api/partner/companies/' + companyId + '/invoices';
    const result = this.http.get<Page<InvoiceModel>>(url, httpOptions);
    return result.pipe(
      catchError(
        this.handleError<Page<InvoiceModel>>('getUnpaidInvoices', null)
      )
    );
  }

  getLatestInvoice(companyId: string): Observable<InvoiceModel> {
    const httpOptions = {
      headers: new HttpHeaders({
        ContentType: 'application/json',
      }),
    };

    const url = environment.apiUrl + '/api/partner/companies/' + companyId + '/invoices/latest';
    const result = this.http.get<InvoiceModel>(url, httpOptions);
    return result.pipe(
      catchError(
        this.handleError<InvoiceModel>('getLatestInvoice', null)
      )
    );
  }

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      console.error(error); // log to console instead
      return of(result);
    };
  }
}
