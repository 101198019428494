import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Store } from '@ngrx/store';
import { Observable, Subject, filter, of, takeUntil } from 'rxjs';
import { BusinessAccountModel } from 'src/app/models/business-account-model';
import { CompanyPromotionSummaryModel } from 'src/app/models/company-promotion-summary-model';
import { StatsActivePromotionChartsComponentStore } from 'src/app/stores/components/stats-active-promotion-charts/stats-active-promotion-charts.component-store';
import { selectBusinessAccount } from 'src/app/stores/global/app.selectors';

@Component({
  selector: 'app-stats-active-promotion-charts',
  templateUrl: './stats-active-promotion-charts.component.html',
  styleUrls: ['./stats-active-promotion-charts.component.scss']
})
export class StatsActivePromotionChartsComponent {
  businessAccount$: Observable<BusinessAccountModel> = this.store.select(selectBusinessAccount);
  companyPromotionSummaries$ : Observable<CompanyPromotionSummaryModel[]>;
  ngUnsubscribeState = new Subject<void>();
  
  isLoading = false;

  private _isLarge: boolean
  private _isMedium: boolean;
  private _isSmall: boolean;
  private _isXSmall: boolean;

  constructor(
    private store: Store<any>,
    private statsActivePromotionChartsComponentStore : StatsActivePromotionChartsComponentStore,
    private snackBar: MatSnackBar,
    private responsive: BreakpointObserver,
    ) { }

  ngOnInit(): void {
    this.store.select(selectBusinessAccount).pipe(takeUntil(this.ngUnsubscribeState), filter(result => Boolean(result))).subscribe(
      businessAccount => {
        this.statsActivePromotionChartsComponentStore.getCompanyActivePromotionSummaries({companyId: businessAccount.company.id});
      });

    this.statsActivePromotionChartsComponentStore.loading$.pipe(takeUntil(this.ngUnsubscribeState)).subscribe(loading => {
      this.isLoading = loading;
    });

    this.statsActivePromotionChartsComponentStore.loaded$.pipe(takeUntil(this.ngUnsubscribeState)).subscribe(loaded => {
      this.isLoading = !loaded;
    });

    this.statsActivePromotionChartsComponentStore.companyPromotionSummaries$.pipe(takeUntil(this.ngUnsubscribeState), filter(result => Boolean(result))).subscribe(companyPromotionSummaries => {
      this.companyPromotionSummaries$ = of(companyPromotionSummaries);
    });

    this.statsActivePromotionChartsComponentStore.errorMessage$.pipe(takeUntil(this.ngUnsubscribeState)).subscribe((errorMessage) => {
      if (errorMessage !== null && errorMessage !== undefined) {
        this.statsActivePromotionChartsComponentStore.setInitial();
        this.snackBar.open(errorMessage, "Dismiss");
      }
    });

    this.responsive.observe([Breakpoints.XSmall, Breakpoints.Small, Breakpoints.Medium, Breakpoints.Large]).subscribe(
      result => {
        const breakpoints = result.breakpoints;

        this._isLarge = false;
        this._isMedium = false;
        this._isSmall = false;
        this._isXSmall = false;

        if (breakpoints[Breakpoints.Medium]) {
          this._isMedium = true;
        }
        else if (breakpoints[Breakpoints.Small]) {
          this._isSmall = true;
        }
        else if (breakpoints[Breakpoints.XSmall]) {
          this._isXSmall = true;
        }
        else {
          this._isLarge = true;
        }
      }
    );
  }

  ngOnDestroy() {
    this.ngUnsubscribeState.next();
    this.ngUnsubscribeState.complete();
  }

  isLarge(): boolean {
    return this._isLarge;
  }

  isMedium(): boolean {
    return this._isMedium;
  }
  isSmall(): boolean {
    return this._isSmall;
  }
  isXSmall(): boolean {
    return this._isXSmall;
  }
}
