<div class="container" [formGroup]="repeatRuleFormGroup">
  <span>Repeat every</span>
  <mat-form-field appearance="outline">
    <input type="number" matInput formControlName="recurrenceRepeatRuleInterval"/>
  </mat-form-field>
  <mat-form-field appearance="outline">
    <mat-label>Frequency</mat-label>
    <mat-select formControlName="recurrenceRepeatRuleFrequency" panelClass="mat-app-background" [(value)]="selectedValue">
      <mat-option
        *ngFor="let recurrenceFrequency of recurrenceFrequencies"
        [value]="recurrenceFrequency.value"
      >
        {{ recurrenceFrequency.viewValue }}
      </mat-option>
    </mat-select>
  </mat-form-field>
</div>
<div *ngIf="selectedValue === 'yearly'" class="container-repeat-rule">
    <app-yearly-repeat-rule [yearlyRepeatRuleFormGroup]="repeatRuleFormGroup" [rrule]="rrule"></app-yearly-repeat-rule>
</div>

<div *ngIf="selectedValue === 'monthly'" class="container-repeat-rule">
    <app-monthly-repeat-rule [monthlyRepeatRuleFormGroup]="repeatRuleFormGroup" [rrule]="rrule"></app-monthly-repeat-rule>
</div>

<div *ngIf="selectedValue === 'weekly'" class="container-repeat-rule">
    <app-weekly-repeat-rule [weeklyRepeatRuleFormGroup]="repeatRuleFormGroup" [rrule]="rrule"></app-weekly-repeat-rule>
</div>
