export const CategoryBagelShop = "Bagel Shop";
export const CategoryBakery = "Bakery";
export const CategoryBar = "Bar";
export const CategoryBarberShop = "Barber Shop";
export const CategoryBedAndBreakfast = "Bed & Breakfast";
export const CategoryBodyPieringShop = "Body Piercing Shop";
export const CategoryCafeCoffeeAndTeaHouse = "Cafe Coffee & Tea House";
export const CategoryCafeteria = "Cafeteria";
export const CategoryDryCleaner = "Dry Cleaner";
export const CategoryJuiceBar = "Juice Bar";
export const CategoryFarmersMarket = "Farmers Market";
export const CategoryFastFoodRestaurant = "Fast Food Restaurant";
export const CategoryFitnessCenter = "Fitness Center";
export const CategoryFoodStand = "Food Stand";
export const CategoryFoodTruck = "Food Truck";
export const CategoryFruitAndVegetableStore = "Fruit & Vegetable Store";
export const CategoryGroceryStore = "Grocery Store";
export const CategoryHairSalon = "Hair Salon";
export const CategoryHealthFoodStore = "Health Food Store";
export const CategoryHotel = "Hotel";
export const CategoryIceCreamParlor = "Ice Cream Parlor";
export const CategoryMassageClinic = "Massage Clinic";
export const CategoryNailSalon = "Nail Salon";
export const CategoryPetService = "Pet Service";
export const CategoryRestaurant = "Restaurant";
export const CategoryRetailStore = "Retail Store";
export const CategorySkinCareClinic = "Skin Care Clinic";
export const CategorySpa = "Spa";
export const CategoryTattooParlor = "Tattoo Parlor";
export const CategoryYogaStudio = "Yoga Studio";

export enum StoreLocationCategory {
    BAGEL_SHOP = "BAGEL_SHOP",
    BAKERY = "BAKERY",
    BAR = "BAR",
    BARBER_SHOP = "BARBER_SHOP",
    BED_AND_BREAKFAST = "BED_AND_BREAKFAST",
    BODY_PIERCING_SHOP = "BODY_PIERCING_SHOP",
    CAFE_COFFEE_AND_TEA_HOUSE = "CAFE_COFFEE_AND_TEA_HOUSE",
    CAFETERIA = "CAFETERIA",
    DRY_CLEANER = "DRY_CLEANER",
    JUICE_BAR = "JUICE_BAR",
    FARMERS_MARKET = "FARMERS_MARKET",
    FAST_FOOD_RESTAURANT = "FAST_FOOD_RESTAURANT",
    FITNESS_CENTER = "FITNESS_CENTER",
    FOOD_STAND = "FOOD_STAND",
    FOOD_TRUCK = "FOOD_TRUCK",
    FRUIT_AND_VEGETABLE_STORE = "FRUIT_AND_VEGETABLE_STORE",
    GROCERY_STORE = "GROCERY_STORE",
    HAIR_SALON = "HAIR_SALON",
    HEALTH_FOOD_STORE = "HEALTH_FOOD_STORE",
    HOTEL = "HOTEL",
    ICE_CREAM_PARLOR = "ICE_CREAM_PARLOR",
    MASSAGE_CLINIC = "MASSAGE_CLINIC",
    NAIL_SALON = "NAIL_SALON",
    PET_SERVICE = "PET_SERVICE",
    RESTAURANT = "RESTAURANT",
    RETAIL_STORE = "RETAIL_STORE",
    SKIN_CARE_CLINIC = "SKIN_CARE_CLINIC",
    SPA = "SPA",
    TATTOO_PARLOR = "TATTOO_PARLOR",
    YOGA_STUDIO = "YOGA_STUDIO",
}