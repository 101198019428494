import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BillingDetailsModel } from 'src/app/models/billing-details-model';

@Component({
  selector: 'app-billing-details',
  templateUrl: './billing-details.component.html',
  styleUrls: ['./billing-details.component.scss']
})
export class BillingDetailsComponent implements OnInit {
  @Input()
  billingDetails : BillingDetailsModel;
  
  constructor(
    private readonly router : Router, 
  ){
    // Do nothing
  }
  
  ngOnInit(): void {
    // Empty
  }

  public goToPaymentProviderCustomerPortal() : void {
    this.router.navigate(['/billing/portal']);
  }
}
