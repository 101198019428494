import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map, Observable, of, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { PhotoModel } from '../models/photo-model';
import { UploadPhotoModel } from '../models/upload-photo-model';
import { ValidationException } from '../core/exceptions/validation-exception';
import { UnknownErrorException } from '../core/exceptions/unknown-error-exception';

class UploadPhotoApiModel {
  fileName : string;
  fileContentBase64 : string;
  title: string;
}

@Injectable({
  providedIn: 'root'
})
export class CampaignLogoService {

  constructor(private http: HttpClient) { 
    // Do nothing
  }

  getCampaignLogo(companyId: string, campaignId: number) : Observable<PhotoModel> {
    const httpOptions = {
      headers: new HttpHeaders({
          ContentType: 'application/json',
      }),
  };

    const url = environment.apiUrl + '/api/partner/companies/' + companyId + '/campaigns/' + campaignId + '/logo';
    const result = this.http.get<PhotoModel>(url, httpOptions);
    return result.pipe(
      map(data => data),
      catchError(
        this.handleError<PhotoModel>('getCampaignLogo', null)
      )
    );
  }

  uploadCampaignLogo(companyId: string, campaignId: number, uploadPhoto: UploadPhotoModel) : Observable<PhotoModel> {
    const httpOptions = {
      headers: new HttpHeaders({
          ContentType: 'application/json',
      }),
  };

    const uploadPhotoApiModel = new UploadPhotoApiModel();
    uploadPhotoApiModel.title = uploadPhoto.title;
    uploadPhotoApiModel.fileContentBase64 = uploadPhoto.fileContentBase64;
    uploadPhotoApiModel.fileName = uploadPhoto.fileName;

    const url = environment.apiUrl + '/api/partner/companies/' + companyId + '/campaigns/' + campaignId + '/logo';
    const result = this.http.post<PhotoModel>(url, uploadPhoto, httpOptions);
    return result.pipe(
      map(data => data),
      catchError((response: HttpErrorResponse) => {
        if (response.status == 400) {
          if(response.error.violations){
            const validationException = new ValidationException(response.error.violations);
            return throwError(() => validationException);
          }
          return throwError(() => new UnknownErrorException());
        }
        return throwError(() => this.handleError('uploadCampaignLogo', null));
      })
    );
  }

  deleteCampaignLogo(companyId : string, campaignId: number) {
    const httpOptions = {
      headers: new HttpHeaders({
          ContentType: 'application/json',
      }),
  };

    const url = environment.apiUrl + '/api/partner/companies/' + companyId + '/campaigns/' + campaignId + '/logo';
    const result = this.http.delete(url, httpOptions);
    return result.pipe(
      catchError((response: HttpErrorResponse) => {
        if (response.status == 400) {
          if(response.error.violations){
            const validationException = new ValidationException(response.error.violations);
            return throwError(() => validationException);
          }
          return throwError(() => new UnknownErrorException());
        }
        return throwError(() => this.handleError('deleteCampaignLogo', null));
      })
    );
  }
  
  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param _operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
   private handleError<T>(_operation = 'operation', result?: T) {
    return (_error: any): Observable<T> => {
      return of(result);
    };
  }
}
