import { Component } from '@angular/core';
import { OidcSecurityService } from 'angular-auth-oidc-client';

@Component({
  selector: 'app-help-center-page',
  templateUrl: './help-center-page.component.html',
  styleUrls: ['./help-center-page.component.scss']
})
export class HelpCenterPageComponent {
  isAuthenticated$ = this.oidcSecurityService.isAuthenticated$;

  constructor(private oidcSecurityService: OidcSecurityService,) {
    // Do nothing
  }
}
