<div class="container" [formGroup]="campaignDetailsFormGroup">
  <mat-card
    appearance="outlined"
    class="mat-component-background mat-elevation-z2"
  >
    <mat-card-header>
      <mat-card-title> Edit Stamp Card </mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <div class="container-content">
        <div class="container-column">
          <mat-form-field appearance="outline">
            <mat-label>Name</mat-label>
            <input
              matInput
              placeholder="Ex. My stamp card"
              formControlName="name"
            />
            <mat-error
              *ngIf="
                campaignDetailsFormGroup.get('name').hasError('required') &&
                campaignDetailsFormGroup.get('name').touched
              "
            >
              <span>Stamp card name is required</span>
            </mat-error>
            <mat-error
              *ngIf="
                campaignDetailsFormGroup.get('name').hasError('minlength') &&
                campaignDetailsFormGroup.get('name').touched
              "
            >
              <span
                >Stamp card name should have a minimum length of
                {{ minNameLength }}</span
              >
            </mat-error>
            <mat-error
              *ngIf="
                campaignDetailsFormGroup.get('name').hasError('maxlength') &&
                campaignDetailsFormGroup.get('name').touched
              "
            >
              <span
                >Stamp card name can have a maximum length of
                {{ maxNameLength }}</span
              >
            </mat-error>
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>Description</mat-label>
            <textarea
              matInput
              placeholder="Ex. My stamp card description"
              formControlName="description"
            ></textarea>
            <mat-error
              *ngIf="
                campaignDetailsFormGroup
                  .get('description')
                  .hasError('required') &&
                campaignDetailsFormGroup.get('description').touched
              "
            >
              <span>Description is required</span>
            </mat-error>
            <mat-error
              *ngIf="
                campaignDetailsFormGroup
                  .get('description')
                  .hasError('minlength') &&
                campaignDetailsFormGroup.get('description').touched
              "
            >
              <span
                >Description should have a minimum length of
                {{ minDescriptionLength }}</span
              >
            </mat-error>
            <mat-error
              *ngIf="
                campaignDetailsFormGroup
                  .get('description')
                  .hasError('maxlength') &&
                campaignDetailsFormGroup.get('description').touched
              "
            >
              <span
                >Description can have a maximum length of
                {{ maxDescriptionLength }}</span
              >
            </mat-error>
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>Start Date</mat-label>
            <input
              class="ng-trim-ignore"
              matInput
              [mtxDatetimepicker]="startDatePicker"
              formControlName="startDate"
            />
            <mtx-datetimepicker-toggle
              matSuffix
              [for]="startDatePicker"
            ></mtx-datetimepicker-toggle>
            <mtx-datetimepicker
              #startDatePicker
              [twelvehour]="false"
              [timeInput]="false"
              [mode]="'auto'"
              [type]="'datetime'"
              [timeInterval]="1"
            ></mtx-datetimepicker>
            <mat-error
              *ngIf="
                campaignDetailsFormGroup
                  .get('startDate')
                  .hasError('required') &&
                campaignDetailsFormGroup.get('startDate').touched
              "
            >
              <span>Start date is required</span>
            </mat-error>
            <mat-error
              *ngIf="
                campaignDetailsFormGroup
                  .get('startDate')
                  .hasError('EndDateBeforeStartDate') &&
                campaignDetailsFormGroup.get('startDate').touched
              "
            >
              <span>Start date must lie before end date</span>
            </mat-error>
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>End Date</mat-label>
            <input
              class="ng-trim-ignore"
              matInput
              [mtxDatetimepicker]="endDatePicker"
              formControlName="endDate"
            />
            <mtx-datetimepicker-toggle
              matSuffix
              [for]="endDatePicker"
            ></mtx-datetimepicker-toggle>
            <mtx-datetimepicker
              #endDatePicker
              [twelvehour]="false"
              [timeInput]="false"
              [mode]="'auto'"
              [type]="'datetime'"
              [timeInterval]="1"
            ></mtx-datetimepicker>
            <mat-error
              *ngIf="
                campaignDetailsFormGroup.get('endDate').hasError('required') &&
                campaignDetailsFormGroup.get('endDate').touched
              "
            >
              <span>End date is required</span>
            </mat-error>
            <mat-error
              *ngIf="
                campaignDetailsFormGroup
                  .get('endDate')
                  .hasError('EndDateBeforeStartDate') &&
                campaignDetailsFormGroup.get('endDate').touched
              "
            >
              <span>End date must lie after start date</span>
            </mat-error>
          </mat-form-field>

          <app-recurrence-field [recurrencePattern]="campaign.recurrencePattern"
            (onRecurrencePatternChanged)="onRecurrencePatternChanged($event)"
          ></app-recurrence-field>
        </div>
        <div class="container-column">
          <mat-form-field appearance="outline">
            <mat-label>Stamps Objective</mat-label>
            <div class="container-stamps">
              <mat-slider
                thumbLabel
                aria-label="units"
                [max]="10"
                [min]="1"
                [step]="1"
                #ngSlider
                ><input
                  matSliderThumb
                  [value]="
                    campaignDetailsFormGroup.get('stampsObjective').value
                  "
                  (change)="
                    onStampsSliderChanged({
                      source: ngSliderThumb,
                      parent: ngSlider,
                      value: ngSliderThumb.value
                    })
                  "
                  #ngSliderThumb="matSliderThumb"
                />
              </mat-slider>
              <input
                type="stampsObjective"
                matInput
                readonly
                formControlName="stampsObjective"
              />
            </div>

            <mat-error
              *ngIf="
                campaignDetailsFormGroup
                  .get('stampsObjective')
                  .hasError('required') &&
                campaignDetailsFormGroup.get('stampsObjective').touched
              "
            >
              <span>Stamps Objective is required</span>
            </mat-error>
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>Reward Name</mat-label>
            <input
              matInput
              placeholder="Ex. Free Cup of Coffee"
              formControlName="campaignRewardName"
            />
            <mat-error
              *ngIf="
                campaignDetailsFormGroup
                  .get('campaignRewardName')
                  .hasError('required') &&
                campaignDetailsFormGroup.get('campaignRewardName').touched
              "
            >
              <span>Reward Name is required</span>
            </mat-error>
            <mat-error
              *ngIf="
                campaignDetailsFormGroup
                  .get('campaignRewardName')
                  .hasError('minlength') &&
                campaignDetailsFormGroup.get('campaignRewardName').touched
              "
            >
              <span
                >Reward Name should have a minimum length of
                {{ minNameLength }}</span
              >
            </mat-error>
            <mat-error
              *ngIf="
                campaignDetailsFormGroup
                  .get('campaignRewardName')
                  .hasError('maxlength') &&
                campaignDetailsFormGroup.get('campaignRewardName').touched
              "
            >
              <span
                >Reward Name can have a maximum length of
                {{ maxNameLength }}</span
              >
            </mat-error>
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>Reward Description</mat-label>
            <textarea
              matInput
              placeholder="Ex. Thank you for supporting us."
              formControlName="campaignRewardDescription"
            ></textarea>
            <mat-error
              *ngIf="
                campaignDetailsFormGroup
                  .get('campaignRewardDescription')
                  .hasError('required') &&
                campaignDetailsFormGroup.get('campaignRewardDescription')
                  .touched
              "
            >
              <span>Reward description is required</span>
            </mat-error>
            <mat-error
              *ngIf="
                campaignDetailsFormGroup
                  .get('campaignRewardDescription')
                  .hasError('minlength') &&
                campaignDetailsFormGroup.get('campaignRewardDescription')
                  .touched
              "
            >
              <span
                >Reward description should have a minimum length of
                {{ minDescriptionLength }}</span
              >
            </mat-error>
            <mat-error
              *ngIf="
                campaignDetailsFormGroup
                  .get('campaignRewardDescription')
                  .hasError('maxlength') &&
                campaignDetailsFormGroup.get('campaignRewardDescription')
                  .touched
              "
            >
              <span
                >Reward description can have a maximum length of
                {{ maxDescriptionLength }}</span
              >
            </mat-error>
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>Voucher Expiration</mat-label>
            <mat-select
              formControlName="voucherDuration"
              panelClass="mat-app-background"
            >
              <mat-option
                *ngFor="let voucherDuration of voucherDurations"
                [value]="voucherDuration.value"
              >
                {{ voucherDuration.viewValue }}
              </mat-option>
            </mat-select>
            <mat-error
              *ngIf="
                campaignDetailsFormGroup
                  .get('voucherDuration')
                  .hasError('required') &&
                campaignDetailsFormGroup.get('voucherDuration').touched
              "
            >
              <span>Voucher duration is required</span>
            </mat-error>
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>Status</mat-label>
            <input matInput readonly value="{{ campaign?.campaignStatus }}" />
          </mat-form-field>
        </div>
      </div>
    </mat-card-content>
    <mat-card-actions>
      <div class="container-actions">
        <button mat-icon-button (click)="cancel()">
          <mat-icon>cancel</mat-icon>
        </button>
        <button mat-icon-button (click)="save()">
          <mat-icon>save</mat-icon>
        </button>
      </div>
    </mat-card-actions>
  </mat-card>
</div>
