import { StoreLocationCategory } from "./store-location-category-model";
import { StoreLocationOption } from "./store-location-option-model";
import { StoreLocationType } from "./store-location-type-model";

export class UpdateStoreLocationModel {
    id: string;
    name: string;
    description: string;
    addressLine1: string;
    addressLine2: string;
    postalCode: string;
    city: string;
    country: string;
    phoneNumber: string;
    storeLocationStatus: string;
    storeLocationType : StoreLocationType;
    storeLocationCategory: StoreLocationCategory;
    storeLocationOptions: StoreLocationOption[];
}