import { createFeatureSelector, createSelector } from "@ngrx/store";
import { APP_REDUCER_KEY } from "./app.reducer";
import { AppState } from "./app.state";

export const selectBusinessAccountFeature = createFeatureSelector<AppState>(
    APP_REDUCER_KEY
);

export const selectBusinessAccount = createSelector(
    selectBusinessAccountFeature,
    (appState: AppState) => appState.businessAccount,
);

export const selectSubscriptionPlanOrderId = createSelector(
    selectBusinessAccountFeature, 
    (appState: AppState) => appState.subscriptionPlanOrderId
);

export const selectHasSubscriptionPlanOrder = createSelector(
    selectBusinessAccountFeature, 
    (appState: AppState) => appState.hasSubscriptionPlanOrder
);

export const selectHasUnpaidInvoice = createSelector(
    selectBusinessAccountFeature, 
    (appState: AppState) => appState.hasUnpaidInvoice
);

export const selectUnpaidInvoice = createSelector(
    selectBusinessAccountFeature, 
    (appState: AppState) => appState.unpaidInvoice
);

export const selectSessionClear = createSelector(
    selectBusinessAccountFeature,
    (appState: AppState) => appState
);