import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { BillingDetailsModel } from 'src/app/models/billing-details-model';

@Component({
  selector: 'app-billing-details-edit',
  templateUrl: './billing-details-edit.component.html',
  styleUrls: ['./billing-details-edit.component.scss']
})
export class BillingDetailsEditComponent implements OnInit {
  @Input()
  billingDetails: BillingDetailsModel;

  @Input()
  billingDetailsFormGroup: FormGroup;

  @Output()
  onCancelPressed = new EventEmitter();

  @Output()
  onSavePressed = new EventEmitter();

  ngOnInit(): void {
    if(this.billingDetails !== null){
      this.billingDetailsFormGroup.patchValue({'id': this.billingDetails.externalId});
      this.billingDetailsFormGroup.patchValue({'phoneNumber': this.billingDetails.phoneNumber});
      this.billingDetailsFormGroup.patchValue({'email': this.billingDetails.email});
      this.billingDetailsFormGroup.patchValue({'addressLine1': this.billingDetails.addressLine1});
      this.billingDetailsFormGroup.patchValue({'addressLine2': this.billingDetails.addressLine2});
      this.billingDetailsFormGroup.patchValue({'postalCode': this.billingDetails.postalCode});
      this.billingDetailsFormGroup.patchValue({'city': this.billingDetails.city});
      this.billingDetailsFormGroup.patchValue({'country': this.billingDetails.country});
      this.billingDetailsFormGroup.patchValue({'vatNumber': this.billingDetails.vatNumber})
    }
  }

  cancel() {
    this.onCancelPressed.emit(null);
  }

  save() {
    this.onSavePressed.emit(null);
  }
}
