import { Injectable } from "@angular/core";
import { ComponentStore } from "@ngrx/component-store";
import { Observable } from "rxjs";
import { catchError, map, switchMap } from "rxjs/operators";
import { PromotionsState } from "./promotions.state";
import { PromotionModel } from "src/app/models/promotion-model";
import { PromotionService } from "src/app/services/promotion.service";

const initialState: PromotionsState = {
    loading: false,
    loaded: false,
    success: false,
    errorMessage: null,
    promotions: [],
    currentPage: 0,
    totalElements: 0,
};

@Injectable({
    providedIn: 'root'
})
export class PromotionsComponentStore extends ComponentStore<PromotionsState> {

    constructor(private readonly promotionService: PromotionService) {
        super(initialState);
    }

    readonly promotions$: Observable<PromotionModel[]> = this.select(state => state.promotions);
    readonly currentPage$: Observable<number> = this.select(state => state.currentPage);
    readonly totalElements$: Observable<number> = this.select(state => state.totalElements);
    readonly loading$: Observable<boolean> = this.select(state => state.loading);
    readonly loaded$: Observable<boolean> = this.select(state => state.loaded);
    readonly success$: Observable<boolean> = this.select(state => state.success);
    readonly errorMessage$: Observable<any> = this.select(state => state.errorMessage);

    readonly viewModel$ = this.select(
        this.promotions$,
        this.currentPage$,
        this.totalElements$,
        this.loaded$,
        this.loading$,
        this.success$,
        this.errorMessage$,
        (promotions, currentPage, totalElements, loaded, loading, success, errorMessage) => ({
            promotions, currentPage, totalElements, loaded, loading, success, errorMessage
        })
    );

    readonly setInitial = this.updater(( _ : PromotionsState) => {
        return {
            ...initialState
        };
    });

    readonly setLoading = this.updater((state: PromotionsState) => {
        return {
            ...state,
            loading: true,
            loaded: false
        };
    });

    readonly setLoaded = this.updater((state: PromotionsState) => {
        return {
            ...state,
            loading: false,
            loaded: true,
        };
    });

    readonly updatePromotions = this.updater((state: PromotionsState, value: {
        content: PromotionModel[],
        currentPage: number,
        totalElements: number
    }) => {
        return {
            ...state,
            loading: false,
            loaded: true,
            success: true,
            promotions: value.content, // TODO Find out why the ... operator doesn't work here [...state.promotions, value.content]
            currentPage: value.currentPage,
            totalElements: value.totalElements
        };
    });


    readonly setError = this.updater((state: PromotionsState, value: {
        errorMessage: string
    }) => {
        return {
            ...state,
            loading: false,
            loaded: true,
            success: false,
            errorMessage: value.errorMessage,
        };
    });

    readonly getPromotions = this.effect((promotionsParams$: Observable<{ companyId: string, currentPage: number, pageSize: number }>) => {
        this.setLoading();
        return promotionsParams$.pipe(
            switchMap((params => this.promotionService.getPromotions(params.companyId, params.currentPage, params.pageSize).pipe(
                map((page) => {
                    this.updatePromotions({ content: page.content, currentPage: page.number, totalElements: page.totalElements })
                    this.setLoaded();
                }),
                catchError(async (error) => {
                    this.setError({ errorMessage: error })
                    this.setLoaded()
                })
            ))
            )
        );
    });
}