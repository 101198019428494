import { PhotoModel } from "./photo-model";

export class CompanyModel {
    id  : string;
    name : string;
    description : string;
    logo : PhotoModel;
    email : string;
    url : string;

    constructor(id: string, name: string, description : string, logo: PhotoModel, email: string, url: string){
        this.id = id;
        this.name = name;
        this.description = description;
        this.logo = logo;
        this.email = email;
        this.url= url;
    }
}