import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    Router,
    RouterStateSnapshot,
} from '@angular/router';
import { AutoLoginPartialRoutesGuard } from 'angular-auth-oidc-client';
import { Observable, map, of } from 'rxjs';
import { AuthorizationGuardService } from 'src/app/services/authorization-guard.service';

@Injectable({ providedIn: 'root' })
export class RegistrationRoutesGuard {
    constructor(
        private readonly autoLoginPartialRoutesGuard: AutoLoginPartialRoutesGuard,
        private readonly authorizationService: AuthorizationGuardService,
        private readonly router: Router,
    ) { }

    canLoad(): Observable<boolean> {
        return this.autoLoginPartialRoutesGuard.canLoad();
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | Observable<boolean>> {
        return this.authorizationService.isAuthenticated$.pipe(    
            map(isAuthenticated => {
                if (route.routeConfig.path == 'register' && !isAuthenticated) {
                    return of(true);
                }
                else if (route.routeConfig.path == 'register' && isAuthenticated) {
                    this.router.navigate(['/pricing']);
                    return this.autoLoginPartialRoutesGuard.canActivate(route, state);
                }
                return this.autoLoginPartialRoutesGuard.canActivate(route, state);
            }),
        );
    }

    canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        return this.autoLoginPartialRoutesGuard.canActivateChild(route, state)
    }
}