import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { StoreLocationModel } from 'src/app/models/store-location-model';

@Component({
  selector: 'app-store-location',
  templateUrl: './store-location.component.html',
  styleUrls: ['./store-location.component.scss']
})
export class StoreLocationComponent implements OnInit {
  @Input()
  storeLocation: StoreLocationModel;

  @Output()
  onEditPressed = new EventEmitter();

  @Output()
  onDeletePressed = new EventEmitter();

  @Output()
  onActivatePressed = new EventEmitter();

  @Output()
  onDeactivatePressed = new EventEmitter();
  
  ngOnInit(): void {
    // Do nothing
  }

  edit(){
    this.onEditPressed.emit(null);
  }

  delete(){
    this.onDeletePressed.emit(null);
  }

  canShowActions() : boolean {
    let result = this.storeLocation?.isActive();
    return result;
  }

  activate(){
    this.onActivatePressed.emit(null);
  }

  deactivate(){
    this.onDeactivatePressed.emit(null);
  }
}
