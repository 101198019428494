import { createAction, props } from "@ngrx/store";
import { SubscriptionPlanModel } from "src/app/models/subscription-plan-model";

export const SUBSCRIPTIONPLANS_GET = '[SubscriptionPlanGet] Get';
export const SUBSCRIPTIONPLANS_GET_SUCCESS = '[SubscriptionPlanGet] Get Success';
export const SUBSCRIPTIONPLANS_GET_FAILURE = '[SubscriptionPlanGet] Get Failure';

export const subscriptionPlansGetAction = createAction(SUBSCRIPTIONPLANS_GET);

export const subscriptionPlansGetActionSuccess = createAction(SUBSCRIPTIONPLANS_GET_SUCCESS, props<{ subscriptionPlans: SubscriptionPlanModel[] }>());

export const subscriptionPlansGetActionFailure = createAction(SUBSCRIPTIONPLANS_GET_FAILURE, props<{ error : any }>());