import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { BillingPortalSessionModel } from '../models/billing-portal-session-model';

@Injectable({
  providedIn: 'root'
})
export class BillingPortalSessionService {

  constructor(private http: HttpClient) { }

  getBillingPortalSession(companyId: string): Observable<BillingPortalSessionModel> {
    const httpOptions = {
      headers: new HttpHeaders({

        ContentType: 'application/json',
      }),
    };

    const url = environment.apiUrl + '/api/partner/companies/' + companyId + '/billingportal';
    const result = this.http.get<BillingPortalSessionModel>(url, httpOptions);
    return result.pipe(catchError(this.handleError<BillingPortalSessionModel>('getBillingPortalSession', null)));
  }

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      console.error(error); // log to console instead
      // Let the app keep running by returning an empty result.
      return of(result);
    };
  }
}
