import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
} from '@angular/router';
import { AutoLoginPartialRoutesGuard } from 'angular-auth-oidc-client';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class CheckoutSuccessRoutesGuard {
    constructor(
        private readonly autoLoginPartialRoutesGuard: AutoLoginPartialRoutesGuard
    ) { }

    canLoad(): Observable<boolean> {
        return this.autoLoginPartialRoutesGuard.canLoad();
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | Observable<boolean>> {
        return this.autoLoginPartialRoutesGuard.canActivate(route, state);
    }

    canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        return this.autoLoginPartialRoutesGuard.canActivateChild(route, state)
    }
}