import { Component } from '@angular/core';

@Component({
  selector: 'app-climate-pledge',
  templateUrl: './climate-pledge.component.html',
  styleUrls: ['./climate-pledge.component.scss']
})
export class ClimatePledgeComponent {

}
