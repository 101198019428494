<div class="container">
  <div [ngClass]="{
    'container-item-card-xlarge': isXLarge(),
    'container-item-card-large': isLarge(),
    'container-item-card-medium': isMedium(),
    'container-item-card-small': isSmall(),
    'container-item-card-xsmall': isXSmall()
  }">
    <mat-card appearance="outlined" class="column-card mat-component-background mat-elevation-z2">
      <mat-card-header>
        <mat-card-title-group>
          <mat-card-title>Partnership</mat-card-title>
          <mat-card-subtitle>Let's start saving</mat-card-subtitle>
        </mat-card-title-group>
      </mat-card-header>
      <div [ngClass]="{
        'container-product-banner-image-xlarge': isXLarge(),
        'container-product-banner-image-large': isLarge(),
        'container-product-banner-image-medium': isMedium(),
        'container-product-banner-image-small': isSmall(),
        'container-product-banner-image-xsmall': isXSmall()
      }">
        <img src="/assets/img/banners/xlarge/product-banner-lets-start-saving.webp"
          alt="lets-start-saving-become-a-partner" />
      </div>
      <mat-card-actions class="container-card-actions">
        <button mat-raised-button color="primary" [routerLink]="['/register']">
          <mat-icon>app_registration</mat-icon>
          Become a Partner
        </button>
      </mat-card-actions>
    </mat-card>
  </div>

  <div [ngClass]="{
    'container-item-xlarge': isXLarge(),
    'container-item-large': isLarge(),
    'container-item-medium': isMedium(),
    'container-item-small': isSmall(),
    'container-item-xsmall': isXSmall()
  }">
    <h2 class="item-title">Together</h2>
    <h3 class="item-subtitle">Become a partner. With our vegan loyalty platform, we can make the world a better place for humans and animals.</h3>
    <mat-list>
      <mat-list-item [ngClass]="{
        'list-item-xsmall': isXSmall()
      }">
        <span matListItemIcon>
          <mat-icon color="primary">eco</mat-icon>
        </span>
        <div matListItemTitle class="list-item-title">Empower your customers with their animal rights ambitions.</div>
      </mat-list-item>
      <mat-list-item [ngClass]="{
      'list-item-xsmall': isXSmall()
    }">
        <span matListItemIcon>
          <mat-icon color="primary">eco</mat-icon>
        </span>
        <div matListItemTitle class="list-item-title">Empower your customers with their climate ambitions.</div>
      </mat-list-item>
      <mat-list-item [ngClass]="{
    'list-item-xsmall': isXSmall()
  }">
        <span matListItemIcon>
          <mat-icon color="primary">eco</mat-icon>
        </span>
        <div matListItemTitle class="list-item-title">Our servers run on renewable sustainable energy.</div>
      </mat-list-item>
    </mat-list>
  </div>
</div>