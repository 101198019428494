<div class="container">
  <mat-card appearance="outlined" class="mat-component-background mat-elevation-z2">
    <mat-card-header>
      <mat-card-title>
        {{ subscriptionPlanViewModel.title }}
      </mat-card-title>
      <mat-card-subtitle>
        {{ subscriptionPlanViewModel.description }}
      </mat-card-subtitle>
    </mat-card-header>
    <mat-card-content class="container-card-content">
      <div class="content-subscription-price">
        <h1>
          {{ priceViewModel.currency }}
          {{ priceViewModel.amount | number : "1.2-2"
          }}<small> / {{ priceViewModel.interval }}*</small>
        </h1>
        <div *ngIf="isAnnually()" class="content-subscription-save-annually">
          <h3>Save 16%</h3>
        </div>
      </div>
      <div class="content-amuse-bouche">
        <h3>
          Amuse-Bouche<br /><em><small>free 90-day trial</small></em>
        </h3>
      </div>
      <mat-divider style="width: 100px"></mat-divider>
      <div class="feature-content">
        <mat-divider inset="true"></mat-divider>
        <div *ngIf="isBasicPlan() else standardPlanPropositionTemplate">
          <p>
            Everything you need to start a basic loyalty campaign.  
          </p>
        </div>
        <mat-divider inset="true"></mat-divider>
      </div>
      <div *ngIf="isAnnually(); else subscriptionMonthlyBilled">
        <div class="content-subscription-billing">
          <mat-divider style="width: 100px"></mat-divider>
          <p>
            Billed Annually
            <br />
            <small><em>* VAT may apply</em></small>
          </p>
        </div>
      </div>
    </mat-card-content>
    <mat-card-actions class="container-card-actions">
      <div *ngIf="isAuthenticated && isCheckoutEnabled(); else noAuth">
        <button mat-button color="primary" (click)="scrollToComparePlans()">
          Compare plans
        </button>
        <button mat-raised-button color="primary" (click)="onSubmit()">
          <mat-icon>shopping_basket</mat-icon>
          Checkout
        </button>
      </div>
    </mat-card-actions>
  </mat-card>
</div>


<ng-template #noAuth>
  <div *ngIf="isCheckoutEnabled();">
    <button mat-button color="primary" (click)="scrollToComparePlans()">
      Compare plans
    </button>
    <button mat-raised-button color="primary" [routerLink]="['/register']">
      <mat-icon>app_registration</mat-icon>
      Sign Up
    </button>
  </div>
</ng-template>

<ng-template #subscriptionMonthlyBilled>
  <div class="content-subscription-billing">
    <mat-divider style="width: 100px"></mat-divider>
    <p>
      Billed Monthly
      <br />
      <small><em>* VAT may apply</em></small>
    </p>
  </div>
</ng-template>

<ng-template #standardPlanPropositionTemplate>
  <p>
    This plan is perfect for small businesses who want to scale up.<br/>Run different programs at the same time.  
  </p>
</ng-template>