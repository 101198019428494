<div class="container">
    <table mat-table [dataSource]="dataSource" class="mat-component-background mat-elevation-z2">
        <ng-container matColumnDef="storelocations">
          <th mat-header-cell *matHeaderCellDef> Store Locations </th>
          <td mat-cell *matCellDef="let element"> {{element.storelocations}} </td>
        </ng-container>
      
        <ng-container matColumnDef="promotions">
          <th mat-header-cell *matHeaderCellDef> Promotions </th>
          <td mat-cell *matCellDef="let element"> {{element.promotions }} </td>
        </ng-container>
      
        <ng-container matColumnDef="stampCards">
          <th mat-header-cell *matHeaderCellDef> Stamp Cards </th>
          <td mat-cell *matCellDef="let element"> {{element.stampCards}} </td>
        </ng-container>
      
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>      
</div>
