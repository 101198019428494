import { Injectable } from '@angular/core';
import { LoginResponse, OidcSecurityService } from 'angular-auth-oidc-client';
import { Observable, ReplaySubject, first, map, switchMap, tap } from 'rxjs';

/**
 * This authorization guard service is intended for the AppComponent checkAuth() only.
 * Other components should use the regular OidcSecurityService instead. This is because
 * the Router Guard needs to skip the first emitted authenticated value, since this one 
 * is a pending value, which is always false. The second emitted value is the one the 
 * Router Guard needs to use.
 */
@Injectable({
  providedIn: 'root'
})
export class AuthorizationGuardService {
  private _checkAuthCompleted$ = new ReplaySubject(1);
  private _businessAccountId : number = null;
  private _subscriptionPlanOrderId: number = null;

  constructor(private readonly oidcSecurityService: OidcSecurityService) { }

  public get isAuthenticated$(): Observable<boolean> {
    return this._checkAuthCompleted$.pipe(
      first(),
      switchMap((_) => this.oidcSecurityService.isAuthenticated$.pipe(map(_ => _.isAuthenticated)))
    );
  }

  /**
   * Should only be called ONCE in the entire app.
   * @returns LoginResponse
   */
  public checkAuth(): Observable<LoginResponse> {
    return this.oidcSecurityService.checkAuth().pipe(
      tap((_) => this._checkAuthCompleted$.next(_))
    );
  }

  public set businessAccountId(id : number){
    this._businessAccountId = id;
  }

  public get businessAccountId() : number {
    return this._businessAccountId;
  }

  public get hasBusinessAccount() : boolean {
    const result = Boolean(this._businessAccountId);
    return result;
  }

  public set subscriptionPlanOrderId(id : number){
    this._subscriptionPlanOrderId = id;
  }

  public get subscriptionPlanOrderId() : number {
    return this._subscriptionPlanOrderId;
  }

  public get hasSubscriptionPlanOrder(): boolean {
    const result =  Boolean(this._subscriptionPlanOrderId);
    return result;
  }

  public reset() : void {
    this._businessAccountId = null;
    this._subscriptionPlanOrderId = null;
  }
}
