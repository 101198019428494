import { AbstractControl, AsyncValidatorFn, ValidationErrors } from "@angular/forms";
import { Observable, of } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { AccountEmailVerifyService } from "src/app/services/account-email-verify.service";

export class AccountEmailNotTakenValidator {
    static createValidator(accountEmailVerifyService: AccountEmailVerifyService): AsyncValidatorFn {
        return (control: AbstractControl) : Observable<ValidationErrors | null> => {
            return accountEmailVerifyService.checkAccountEmailTaken(control.value).pipe(
                map(result => {
                    return result ? { accountEmailTaken: true } : null;
                }),
                catchError(() => of(null))
            );
        }
    }   
}