<div class="container">
  <mat-card class="mat-component-background mat-elevation-z2">
    <mat-card-header>
      <mat-card-title>Unpaid Invoice</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      You have an unpaid invoice. Please pay the invoice.<br />
      Otherwise your subscription plan will be terminated and you will lose all
      your members.
    </mat-card-content>
    <mat-card-actions>
      <button mat-raised-button (click)="payInvoice()">
        <mat-icon>payments</mat-icon>
        <mat-icon>open_in_new</mat-icon>
        Pay Invoice
      </button>
    </mat-card-actions>
  </mat-card>
</div>
