import { Injectable } from "@angular/core";
import { ComponentStore } from "@ngrx/component-store";
import { Observable } from "rxjs";
import { catchError, switchMap, tap } from "rxjs/operators";
import { StoreLocationService } from "src/app/services/store-location.service";
import { DeleteStoreLocationState } from "./delete-store-locations.state";

const initialState: DeleteStoreLocationState = {
    loading: false,
    loaded: false,
    success: false,
    errorMessage: null,
};

@Injectable({
    providedIn: 'root'
})
export class DeleteStoreLocationComponentStore extends ComponentStore<DeleteStoreLocationState> {

    constructor(private readonly storeLocationService: StoreLocationService) {
        super(initialState);
    }

    readonly loading$: Observable<boolean> = this.select(state => state.loading);
    readonly loaded$: Observable<boolean> = this.select(state => state.loaded);
    readonly success$: Observable<boolean> = this.select(state => state.success);
    readonly errorMessage$: Observable<any> = this.select(state => state.errorMessage);

    readonly viewModel$ = this.select(
        this.loaded$,
        this.loading$,
        this.success$,
        this.errorMessage$,
        (loaded, loading, success, errorMessage) => ({
            loaded, loading, success, errorMessage
        })
    );

    readonly setInitial = this.updater(( _ : DeleteStoreLocationState) => {
        return {
            ...initialState
        };
    });

    readonly setLoading = this.updater((state: DeleteStoreLocationState) => {
        return {
            ...state,
            loading: true,
            loaded: false
        };
    });

    readonly setLoaded = this.updater((state: DeleteStoreLocationState) => {
        return {
            ...state,
            loading: false,
            loaded: true,
        };
    });

    readonly updateDeleteStoreLocationState = this.updater((state: DeleteStoreLocationState) => {
        return {
            ...state,
            loading: false,
            loaded: true,
            success: true,        };
    });

    readonly setError = this.updater((state: DeleteStoreLocationState, value: {
        errorMessage: String
    }) => {
        return {
            ...state,
            loading: false,
            loaded: true,
            success: false,
            errorMessage: value.errorMessage,
        };
    });

    readonly deleteStoreLocation = this.effect((deleteStoreLocationParams$: Observable<{ companyId: string, storeLocationId: number }>) => {
        this.setLoading();
        return deleteStoreLocationParams$.pipe(
            switchMap((params => this.storeLocationService.deleteStoreLocation(params.companyId, params.storeLocationId).pipe(
                tap({
                    next: () => {
                        this.updateDeleteStoreLocationState();
                        this.setLoaded();
                        this.setInitial();
                    },
                    error: (e) => { 
                        throw e;
                    }
                }),
                catchError(async (error) => {
                    this.setError({ errorMessage: error });
                    this.setLoaded();
                    this.setInitial();
                })
            ))
            )
        );
    });
}