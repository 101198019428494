<mat-form-field appearance="outline" [formGroup]="formGroup">
  <mat-label>{{ label }}</mat-label>
  <mat-select [formControlName]="countryControlName">
    
    <mat-option *ngFor="let country of countries" [value]="country.code">
      {{ country.name }}
    </mat-option>
  </mat-select>
  <mat-error
    *ngIf="
      formGroup.get(countryControlName).hasError('required') &&
      formGroup.get(countryControlName).touched">
    <span>{{ requiredErrorMessage }}</span>
  </mat-error>
</mat-form-field>
