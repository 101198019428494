import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subject, combineLatest, filter } from 'rxjs';

import { selectBusinessAccount, selectHasSubscriptionPlanOrder, selectUnpaidInvoice } from 'src/app/stores/global/app.selectors';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent {
  ngUnsubscribeState = new Subject<void>();

  unpaidInvoice$ = this.store.select(selectUnpaidInvoice);
  showPricingBanner : boolean;

  private _isLarge: boolean
  private _isMedium: boolean;
  private _isSmall: boolean;
  private _isXSmall : boolean;

  constructor(
    private router: Router,
    private store: Store,
    private responsive: BreakpointObserver
  ) {
    // Do nothing
  }

  ngOnInit(): void {
    // Redirect to pricing checkout if the business account has no active subscription plan order
    // Redirect to getting started if the business account has an active subscription plan order, but no company details.
    combineLatest([
      this.store.select(selectBusinessAccount).pipe(filter(result => Boolean(result))),
      this.store.select(selectHasSubscriptionPlanOrder).pipe(),
    ]).pipe().subscribe(combinedData => {
      const businessAccount = combinedData[0];
      const hasSubscriptionPlanOrder = combinedData[1];
    
      this.showPricingBanner = !hasSubscriptionPlanOrder;

      if (hasSubscriptionPlanOrder) {
        const gettingStartedCompanyDetails: boolean = !businessAccount.company.name || businessAccount.company.name === '';
        const gettingStartedCompanyLogo: boolean = !businessAccount.company.logo;
        if (gettingStartedCompanyDetails || gettingStartedCompanyLogo) {
          this.router.navigate(['/getting-started']);
        }
      }
    });

    this.responsive.observe([Breakpoints.XSmall, Breakpoints.Small, Breakpoints.Medium, Breakpoints.Large]).subscribe(
      result => {
        const breakpoints = result.breakpoints;

        this._isLarge = false;
        this._isMedium = false;
        this._isSmall = false;
        this._isXSmall = false;

        if (breakpoints[Breakpoints.Medium]) {
          this._isMedium = true;
        }
        else if (breakpoints[Breakpoints.Small]) {
          this._isSmall = true;
        }
        else if (breakpoints[Breakpoints.XSmall]) {
          this._isXSmall = true;
        }
        else {
          this._isLarge = true;
        }
      }
    );
  }

  ngOnDestroy() {
    this.ngUnsubscribeState.next();
    this.ngUnsubscribeState.complete();
  }

  isLarge(): boolean {
    return this._isLarge;
  }

  isMedium(): boolean {
    return this._isMedium;
  }
  isSmall(): boolean{
    return this._isSmall;
  }
  isXSmall(): boolean{
    return this._isXSmall;
  }
}
