import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { CompanyModel } from '../models/company-model';
import { UpdateCompanyModel } from '../models/update-company-model';
import { ValidationException } from '../core/exceptions/validation-exception';
import { UnknownErrorException } from '../core/exceptions/unknown-error-exception';

class UpdateCompanyApiModel {
  name: string;
  description: string;
  email: string;
  url: string;
}

@Injectable({
  providedIn: 'root'
})
export class CompanyService {

  constructor(private http: HttpClient) {
    // Do nothing
  }

  updateCompany(updateCompany: UpdateCompanyModel): Observable<CompanyModel> {
    const httpOptions = {
      headers: new HttpHeaders({
        ContentType: 'application/json',
      }),
    };

    const updateCompanyApiModel = new UpdateCompanyApiModel();
    updateCompanyApiModel.name = updateCompany.name;
    updateCompanyApiModel.description = updateCompany.description;
    updateCompanyApiModel.email = updateCompany.email;
    updateCompanyApiModel.url = updateCompany.url;

    const url = environment.apiUrl + '/api/partner/companies/' + updateCompany.id;
    const result = this.http.put<CompanyModel>(url, updateCompanyApiModel, httpOptions);
    return result.pipe(
      catchError((response: HttpErrorResponse) => {
        if (response.status == 400) {
          if (response.error.violations) {
            const validationException = new ValidationException(response.error.violations);
            return throwError(() => validationException);
          }
          return throwError(() => new UnknownErrorException());
        }
        return throwError(() => this.handleError('updateCompany', null));
      })
    );
  }

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      // this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result);
    };
  }
}
