import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Frequency, RRule } from 'rrule';

interface RecurrenceFrequency {
  value: string;
  viewValue: string;
}


@Component({
  selector: 'app-repeat-rule',
  templateUrl: './repeat-rule.component.html',
  styleUrls: ['./repeat-rule.component.scss']
})
export class RepeatRuleComponent {
  @Input()
  repeatRuleFormGroup: FormGroup;

  @Input()
  rrule: RRule;

  selectedValue: string;

  recurrenceFrequencies: RecurrenceFrequency[] = [
    { value: 'daily', viewValue: 'Day' },
    { value: 'weekly', viewValue: 'Week' },
    { value: 'monthly', viewValue: 'Month' },
    { value: 'yearly', viewValue: 'Year' },
  ];

  ngOnInit(): void {
    if (this.rrule) {
      this.repeatRuleFormGroup.patchValue({ 'recurrenceRepeatRuleInterval': this.rrule.options.interval });
      this.selectedValue = this._getFrequency();
      this.repeatRuleFormGroup.patchValue({ 'recurrenceRepeatRuleFrequency': this.selectedValue });
    }
    else {
      this.selectedValue = 'daily';
      this.repeatRuleFormGroup.patchValue({ 'recurrenceRepeatRuleInterval': 1 });
      this.repeatRuleFormGroup.patchValue({ 'recurrenceRepeatRuleFrequency':  this.selectedValue });
    }
  }

  private _getFrequency(): string {
    switch (this.rrule.options.freq) {
      case Frequency.YEARLY:
        return 'yearly';
      case Frequency.MONTHLY:
        return 'monthly';
      case Frequency.WEEKLY:
        return 'weekly';
      case Frequency.DAILY:
        return 'daily';
    }
    throw new RangeError("Unsupported RRule frequency.");
  }
}
