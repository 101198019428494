import { Component } from '@angular/core';

@Component({
  selector: 'app-partner-terms',
  templateUrl: './partner-terms.component.html',
  styleUrls: ['./partner-terms.component.scss']
})
export class PartnerTermsComponent {

}
