<div [ngClass]="{
  'container-xlarge': isXLarge(),
  'container-large': isLarge(),
  'container-medium': isMedium(),
  'container-small': isSmall(),
  'container-xsmall': isXSmall()
}">
  <div [ngClass]="{
    'container-content-xlarge': isXLarge(),
    'container-content-large': isLarge(),
    'container-content-medium': isMedium(),
    'container-content-small': isSmall(),
    'container-content-xsmall': isXSmall()
  }">
    <div [ngClass]="{
      'content-xlarge': isXLarge(),
      'content-large': isLarge(),
      'content-medium': isMedium(),
      'content-small': isSmall(),
      'content-xsmall': isXSmall()
    }">
      <h2 class="item-title">Made for Humans and Animals</h2>
      <h3 class="item-subtitle">Our mission is to empower your business to build stronger and lasting connections with your customers by providing you a vegan loyalty platform.</h3>
    </div>
  </div>
</div>