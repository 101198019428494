import { Injectable } from "@angular/core";
import { ComponentStore } from "@ngrx/component-store";
import { Store } from "@ngrx/store";
import { Observable } from "rxjs";
import { catchError, map, switchMap, tap } from "rxjs/operators";
import { CampaignModel } from "src/app/models/campaign-model";
import { CampaignService } from "src/app/services/campaign.service";
import { CampaignDetailState } from "./campaign-detail.state";

const initialState: CampaignDetailState = {
    loading: false,
    loaded: false,
    success: false,
    errorMessage: null,
    campaign: null
};

@Injectable({
    providedIn: 'root'
})
export class CampaignDetailComponentStore extends ComponentStore<CampaignDetailState> {

    constructor(private store: Store<any>, private readonly campaignService: CampaignService) {
        super(initialState);
    }

    readonly campaign$: Observable<CampaignModel> = this.select(state => state.campaign);
    readonly loading$: Observable<boolean> = this.select(state => state.loading);
    readonly loaded$: Observable<boolean> = this.select(state => state.loaded);
    readonly success$: Observable<boolean> = this.select(state => state.success);
    readonly errorMessage$: Observable<any> = this.select(state => state.errorMessage);

    readonly viewModel$ = this.select(
        this.campaign$,
        this.loaded$,
        this.loading$,
        this.success$,
        this.errorMessage$,
        (campaign, loaded, loading, success, errorMessage) => ({
            campaign, loaded, loading, success, errorMessage
        })
    );

    readonly setInitial = this.updater(( _ : CampaignDetailState) => {
        return {
            ...initialState
        };
    });

    readonly setLoading = this.updater((state: CampaignDetailState) => {
        return {
            ...state,
            loading: true,
            loaded: false,
        };
    });

    readonly setLoaded = this.updater((state: CampaignDetailState) => {
        return {
            ...state,
            loading: false,
            loaded: true,
        };
    });

    readonly updateDetailState = this.updater((state: CampaignDetailState, value: {
        campaign: CampaignModel
    }) => {
        return {
            ...state,
            loading: false,
            loaded: true,
            success: true,
            campaign: value.campaign
        };
    });

    readonly setError = this.updater((state: CampaignDetailState, value: {
        errorMessage: String
    }) => {
        return {
            ...state,
            loading: false,
            loaded: true,
            success: false,
            errorMessage: value.errorMessage,
        };
    });

    readonly getCampaign = this.effect((detailParams$: Observable<{ companyId: string, campaignId: number }>) => {
        this.setLoading();
        return detailParams$.pipe(
            switchMap((params => this.campaignService.getCampaign(params.companyId, params.campaignId).pipe(
                map((campaign) => {
                    this.updateDetailState({ campaign: campaign });
                    this.setLoaded();
                }
                ),
                catchError(async (error) => {
                    this.setError({ errorMessage: error });
                    this.setLoaded();
                })
            ))
            )
        );
    });
}