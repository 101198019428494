import { createAction, props } from "@ngrx/store";
import { BusinessAccountModel } from "src/app/models/business-account-model";
import { InvoiceModel } from "src/app/models/invoice-model";

export const BUSINESS_ACCOUNT_GET = '[BusinessAccount] Get';
export const BUSINESS_ACCOUNT_GET_SUCCESS = '[BusinessAccount] Get Success';
export const BUSINESS_ACCOUNT_GET_FAILURE = '[BusinessAccount] Get Failure';

export const businessAccountGetAction = createAction(BUSINESS_ACCOUNT_GET);
export const businessAccountGetActionSuccess = createAction(BUSINESS_ACCOUNT_GET_SUCCESS, props<{ businessAccount: BusinessAccountModel, businessAccountId: string, companyId: string, hasBusinessAccount: boolean }>());
export const businessAccountGetActionFailure = createAction(BUSINESS_ACCOUNT_GET_FAILURE, props<{ error: any }>());

export const SUBSCRIPTION_PLAN_ORDER_CHANGED = '[SubscriptionPlanOrder] Changed';

export const subscriptionPlanChangedAction = createAction(SUBSCRIPTION_PLAN_ORDER_CHANGED, props<{hasSubscriptionPlanOrder: boolean, subscriptionPlanOrderId : number}>());

export const UNPAID_INVOICE_GET = '[Unpaid Invoice] Get';
export const UNPAID_INVOICE_GET_SUCCESS = '[Unpaid Invoice] Get Success';
export const UNPAID_INVOICE_GET_FAILURE = '[Unpaid Invoice] Get Failure';

export const unpaidInvoiceGetAction = createAction(UNPAID_INVOICE_GET, props<{ companyId: string }>());
export const unpaidInvoiceGetActionSuccess = createAction(UNPAID_INVOICE_GET_SUCCESS, props<{ hasUnpaidInvoice: boolean, unpaidInvoice : InvoiceModel }>());
export const unpaidInvoiceGetActionFailure = createAction(UNPAID_INVOICE_GET_FAILURE, props<{ error: any }>());

export const SESSION_CLEAR = '[Session] Clear';
export const clearSessionAction = createAction(SESSION_CLEAR);