<div class="container" [formGroup]="monthlyRepeatRuleFormGroup">
  <mat-radio-group aria-label="which day" formControlName="onMonthlyChoice" class="container-choice">
    <mat-radio-button value="on-day">
      <div class="container-row">
        <mat-form-field appearance="outline">
          <mat-label>On Day</mat-label>
          <mat-select formControlName="recurrenceRepeatRuleMonthlyOnDay" [(value)]="selectedOnDayValue">
            <mat-option *ngFor="let val of dayRange()" [value]="val.toString()"
              >{{ val }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </mat-radio-button>
    <mat-radio-button value="on-which-day">
      <div class="container-row">
        <mat-form-field appearance="outline">
          <mat-label>On The</mat-label>
          <mat-select
            formControlName="recurrenceRepeatRuleMonthlyOnWhichDayPosition"
          >
            <mat-option
              *ngFor="let val of recurrenceMonthlyOnWhichPositions"
              [value]="val.value"
              >{{ val.viewValue }}</mat-option
            >
          </mat-select>
        </mat-form-field>

        <mat-form-field appearance="outline">
          <mat-label>Day</mat-label>
          <mat-select
            formControlName="recurrenceRepeatRuleMonthlyOnWhichDayDay"
          >
            <mat-option
              *ngFor="let val of recurrenceMonthlyOnWhichDays"
              [value]="val.value"
              >{{ val.viewValue }}</mat-option
            >
          </mat-select>
        </mat-form-field>
      </div>
    </mat-radio-button>
  </mat-radio-group>
</div>
