import { Component, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { RRule } from 'rrule';

interface RecurrenceWeeklyRule {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'app-weekly-repeat-rule',
  templateUrl: './weekly-repeat-rule.component.html',
  styleUrls: ['./weekly-repeat-rule.component.scss']
})
export class WeeklyRepeatRuleComponent {
  @Input()
  weeklyRepeatRuleFormGroup: UntypedFormGroup;

  @Input()
  rrule : RRule;
  
  selectedValues: string[];
  
  recurrenceFrequencies: RecurrenceWeeklyRule[] = [
    {value: 'monday', viewValue: 'Monday'},
    {value: 'tuesday', viewValue: 'Tuesday'},
    {value: 'wednesday', viewValue: 'Wednesday'},
    {value: 'thursday', viewValue: 'Thursday'},
    {value: 'friday', viewValue: 'Friday'},
    {value: 'saturday', viewValue: 'Saturday'},
    {value: 'sunday', viewValue: 'Sunday'},
  ];

  ngOnInit() : void {
    if(this.rrule){
      this.selectedValues = this._getWeeklyDays();
      this.weeklyRepeatRuleFormGroup.patchValue({'recurrenceRepeatRuleWeeklyDays' : this.selectedValues});
    }
  }

  private _getWeeklyDays() : string[] {
    let weeklyDays = [];
    this.rrule.options.byweekday.forEach(element => {
        weeklyDays.push(this.recurrenceFrequencies[element].value);
    });
    return weeklyDays;
  }
}
