<div class="container" [formGroup]="storeLocationDetailsFormGroup">
  <h4>What does your store provide:</h4>
  <mat-checkbox formControlName="optionVeganOptions" [checked]="storeLocationDetailsFormGroup.value.optionVeganOptions">Vegan Options</mat-checkbox>
  <mat-checkbox formControlName="optionVegetarianOptions" [checked]="storeLocationDetailsFormGroup.value.optionVegetarianOptions">Vegetarian Options</mat-checkbox>
  <mat-checkbox formControlName="optionGlutenFreeOptions" [checked]="storeLocationDetailsFormGroup.value.optionGlutenFreeOptions">Gluten Free Options</mat-checkbox>
  <mat-checkbox formControlName="optionBioOrganic" [checked]="storeLocationDetailsFormGroup.value.optionBioOrganic">Bio Organic</mat-checkbox>
  <mat-checkbox formControlName="optionBioDynamic" [checked]="storeLocationDetailsFormGroup.value.optionBioDynamic">Bio Dynamic</mat-checkbox>
  <mat-checkbox formControlName="optionZeroWaste" [checked]="storeLocationDetailsFormGroup.value.optionZeroWaste">Zero Waste</mat-checkbox>
  <mat-checkbox formControlName="optionPlasticFree" [checked]="storeLocationDetailsFormGroup.value.optionPlasticFree">Plastic Free</mat-checkbox>
  <mat-checkbox formControlName="optionPalmOilFree" [checked]="storeLocationDetailsFormGroup.value.optionPalmOilFree">Palm Oil Free</mat-checkbox>
</div>
