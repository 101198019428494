<div
  [ngClass]="{
    'container-large': isLarge(),
    'container-medium': isMedium(),
    'container-small': isSmall(),
    'container-xsmall': isXSmall()
  }"
>
  <div *ngIf="isLoading; else showActivePromotionSummaryCharts">
    <div class="spinner-container">
      <mat-spinner diameter="80"></mat-spinner>
    </div>
  </div>
</div>

<ng-template #showActivePromotionSummaryCharts>
  <div
    *ngFor="
      let companyPromotionSummary of companyPromotionSummaries$ | async;
      index as i
    "
  >
    <app-stats-active-promotion-chart
      [companyPromotionSummary]="companyPromotionSummary"
    ></app-stats-active-promotion-chart>
  </div>
</ng-template>
