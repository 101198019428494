<div class="container">
    <mat-card class="mat-component-background mat-elevation-z2">
      <mat-card-header>
        <mat-card-title> Billing Information </mat-card-title>
      </mat-card-header>
      <mat-card-content>
          <div class="container-card-content">
            <p>To update your billing information. Go to the Billing Portal.</p>
            <mat-form-field appearance="outline">
              <mat-label>Address Line 1</mat-label>
              <input
                matInput
                readonly
                value="{{ billingDetails?.addressLine1 }}"
              />
            </mat-form-field>
            <mat-form-field appearance="outline">
              <mat-label>Address Line 2</mat-label>
              <input
                matInput
                readonly
                value="{{ billingDetails?.addressLine2 }}"
              />
            </mat-form-field>
            <mat-form-field appearance="outline">
              <mat-label>Postal Code</mat-label>
              <input
                matInput
                readonly
                value="{{ billingDetails?.postalCode }}"
              />
            </mat-form-field>
            <mat-form-field appearance="outline">
              <mat-label>City</mat-label>
              <input
                matInput
                readonly
                value="{{ billingDetails?.city }}"
              />
            </mat-form-field>
            <mat-form-field appearance="outline">
              <mat-label>Country</mat-label>
              <input
                matInput
                readonly
                value="{{ billingDetails?.country }}"
              />
            </mat-form-field>
            <mat-form-field appearance="outline">
              <mat-label>Phone Number</mat-label>
              <input
                matInput
                readonly
                value="{{ billingDetails?.phoneNumber }}"
              />
            </mat-form-field>
            <mat-form-field appearance="outline">
              <mat-label>Tax Identifier</mat-label>
              <input
                matInput
                readonly
                value="{{ billingDetails?.vatNumber }}"
              />
          </mat-form-field>
          </div>
      </mat-card-content>
      <mat-card-actions>
        <div class="container-card-actions">
          <button
            mat-button
            (click)="goToPaymentProviderCustomerPortal()"
            color="primary"
          >
            <mat-icon>edit</mat-icon><mat-icon>open_in_new</mat-icon>Billing Portal
          </button>
        </div>
      </mat-card-actions>
    </mat-card>
  </div>
  