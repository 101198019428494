import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, map, of } from 'rxjs';
import { CompanyMemberSummaryModel } from '../models/company-member-summary-model';
import { environment } from 'src/environments/environment';
import { CompanyCampaignSummaryModel } from '../models/company-campaign-summary-model';
import { CompanyPromotionSummaryModel } from '../models/company-promotion-summary-model';
import { CompanyStoreLocationSummaryModel } from '../models/company-store-location-summary-model';

@Injectable({
  providedIn: 'root'
})
export class CompanySummariesService {

  constructor(private http: HttpClient) {
    // Do nothing
  }

  getCompanyMemberSummary(companyId: string): Observable<CompanyMemberSummaryModel> {
    const httpOptions = {
      headers: new HttpHeaders({
        ContentType: 'application/json',
      }),
    };

    const url = environment.apiUrl + '/api/partner/companies/' + companyId + '/statistics/members';
    const result = this.http.get<CompanyMemberSummaryModel>(url, httpOptions);
    return result.pipe(
      map(data => data),
      catchError(
        this.handleError<CompanyMemberSummaryModel>('getCompanyMemberSummary', null)
      )
    );
  }

  getCompanyActiveCampaignSummaries(companyId: string): Observable<CompanyCampaignSummaryModel[]> {
    const httpOptions = {
      headers: new HttpHeaders({
        ContentType: 'application/json',
      }),
    };

    const url = environment.apiUrl + '/api/partner/companies/' + companyId + '/statistics/activecampaigns';
    const result = this.http.get<CompanyCampaignSummaryModel[]>(url, httpOptions);
    return result.pipe(
      map(data => data),
      catchError(
        this.handleError<CompanyCampaignSummaryModel[]>('getCompanyActiveCampaignSummaries', null)
      )
    );
  }


  getCompanyActivePromotionSummaries(companyId: string): Observable<CompanyPromotionSummaryModel[]> {
    const httpOptions = {
      headers: new HttpHeaders({
        ContentType: 'application/json',
      }),
    };

    const url = environment.apiUrl + '/api/partner/companies/' + companyId + '/statistics/activepromotions';
    const result = this.http.get<CompanyPromotionSummaryModel[]>(url, httpOptions);
    return result.pipe(
      map(data => data),
      catchError(
        this.handleError<CompanyPromotionSummaryModel[]>('getCompanyActivePromotionSummaries', null)
      )
    );
  }

  getCompanyStoreLocationSummary(companyId: string): Observable<CompanyStoreLocationSummaryModel> {
    const httpOptions = {
      headers: new HttpHeaders({
        ContentType: 'application/json',
      }),
    };

    const url = environment.apiUrl + '/api/partner/companies/' + companyId + '/statistics/storelocations';
    const result = this.http.get<CompanyStoreLocationSummaryModel>(url, httpOptions);
    return result.pipe(
      map(data => data),
      catchError(
        this.handleError<CompanyStoreLocationSummaryModel>('getCompanyStoreLocationSummary', null)
      )
    );
  }

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      console.error(error);
      // Let the app keep running by returning an empty result.
      return of(result);
    };
  }
}
