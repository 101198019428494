import { Action, createReducer, on } from "@ngrx/store";
import {
    businessAccountGetAction,
    businessAccountGetActionFailure,
    businessAccountGetActionSuccess,
    clearSessionAction,
    subscriptionPlanChangedAction,
    unpaidInvoiceGetAction,
    unpaidInvoiceGetActionFailure,
    unpaidInvoiceGetActionSuccess
} from "./app.actions";
import { AppState } from "./app.state";

export const APP_REDUCER_KEY = "appReducerKey";

export const initialBusinessAccountState: AppState = {
    businessAccount: null,
    businessAccountId: null,
    companyId: null,
    hasBusinessAccount: false,
    subscriptionPlanOrderId: null,
    hasSubscriptionPlanOrder: false,
    hasUnpaidInvoice: false,
    unpaidInvoice: null,
    loading: false,
    loaded: false,
    success: false,
    errorMessage: null
};

export const appReducer = createReducer(
    initialBusinessAccountState,
    on(
        businessAccountGetAction, (state: AppState) => ({
            ...state,
            loading: true,
            loaded: false,
            success: false,
            errorMessage: null,
        })
    ),
    on(
        businessAccountGetActionSuccess, (state: AppState, { businessAccount, businessAccountId, companyId, hasBusinessAccount }) => ({
            ...state,
            businessAccount: businessAccount,
            businessAccountId: businessAccountId,
            companyId: companyId,
            hasBusinessAccount: hasBusinessAccount,
            loading: false,
            loaded: true,
            success: true,
        })
    ),
    on(
        businessAccountGetActionFailure, (state: AppState, { error }) => ({
            ...state,
            loading: false,
            loaded: true,
            success: false,
            errorMessage: error
        })
    ),
    on(
        subscriptionPlanChangedAction, (state: AppState, { hasSubscriptionPlanOrder, subscriptionPlanOrderId }) => ({
            ...state,
            hasSubscriptionPlanOrder: hasSubscriptionPlanOrder,
            subscriptionPlanOrderId: subscriptionPlanOrderId
        })
    ),
    on(
        unpaidInvoiceGetAction, (state: AppState) => ({
            ...state,
            loading: true,
            loaded: false,
            success: false,
            errorMessage: null,
        })
    ),
    on(
        unpaidInvoiceGetActionSuccess, (state: AppState, { hasUnpaidInvoice, unpaidInvoice }) => ({
            ...state,
            hasUnpaidInvoice: hasUnpaidInvoice,
            unpaidInvoice: unpaidInvoice,
            loading: false,
            loaded: true,
            success: true,
        })
    ),
    on(
        unpaidInvoiceGetActionFailure, (state: AppState, { error }) => ({
            ...state,
            hasUnpaidInvoice: false,
            unpaidInvoice: null,
            loading: false,
            loaded: true,
            success: false,
            errorMessage: error
        })
    ),
    on(
        clearSessionAction, (state: AppState) => ({
            ...state,
            businessAccount: null,
            businessAccountId: null,
            companyId: null,
            hasBusinessAccount: false,
            subscriptionPlanOrderId: null,
            hasSubscriptionPlanOrder: false,
            hasUnpaidInvoice: false,
            unpaidInvoice: null,
            loading: false,
            loaded: false,
            success: false,
            errorMessage: null,
        })
    ),
);

export function appReducerExport(state: AppState | undefined, action: Action) {
    return appReducer(state, action);
}