<div class="container" [formGroup]="storeLocationDetailsFormGroup">
  <mat-card
    appearance="outlined"
    class="mat-component-background mat-elevation-z2"
  >
    <mat-card-header>
      <mat-card-title> Editing Store Location Details </mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <div class="container-content">
        <div class="container-column">
          <mat-form-field appearance="outline">
            <mat-label>Name</mat-label>
            <input
              matInput
              placeholder="Ex. My StoreLocation"
              formControlName="name"
            />
            <mat-error
              *ngIf="
                storeLocationDetailsFormGroup
                  .get('name')
                  .hasError('required') &&
                storeLocationDetailsFormGroup.get('name').touched
              "
            >
              <span>Store location name is required</span>
            </mat-error>
            <mat-error
              *ngIf="
                storeLocationDetailsFormGroup
                  .get('name')
                  .hasError('minlength') &&
                storeLocationDetailsFormGroup.get('name').touched
              "
            >
              <span
                >Store location name should have a minimum length of
                {{ minNameLength }}</span
              >
            </mat-error>
            <mat-error
              *ngIf="
                storeLocationDetailsFormGroup
                  .get('name')
                  .hasError('maxlength') &&
                storeLocationDetailsFormGroup.get('name').touched
              "
            >
              <span
                >Store location name can have a maximum length of
                {{ maxNameLength }}</span
              >
            </mat-error>
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>Description</mat-label>
            <input
              matInput
              placeholder="Ex. My store location description"
              formControlName="description"
            />
            <mat-error
              *ngIf="
                storeLocationDetailsFormGroup
                  .get('description')
                  .hasError('required') &&
                storeLocationDetailsFormGroup.get('description').touched
              "
            >
              <span>Description is required</span>
            </mat-error>
            <mat-error
              *ngIf="
                storeLocationDetailsFormGroup
                  .get('description')
                  .hasError('minlength') &&
                storeLocationDetailsFormGroup.get('description').touched
              "
            >
              <span
                >Description should have a minimum length of
                {{ minDescriptionLength }}</span
              >
            </mat-error>
            <mat-error
              *ngIf="
                storeLocationDetailsFormGroup
                  .get('description')
                  .hasError('maxlength') &&
                storeLocationDetailsFormGroup.get('description').touched
              "
            >
              <span
                >Description can have a maximum length of
                {{ maxDescriptionLength }}</span
              >
            </mat-error>
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>Type</mat-label>
            <mat-select
              formControlName="type"
              panelClass="mat-app-background"
              [(value)]="storeLocation.storeLocationType"
            >
              <mat-option
                *ngFor="let storeLocationType of storeLocationTypes"
                [value]="storeLocationType.value"
              >
                {{ storeLocationType.viewValue }}
              </mat-option>
            </mat-select>
            <mat-error
              *ngIf="
                storeLocationDetailsFormGroup
                  .get('type')
                  .hasError('required') &&
                storeLocationDetailsFormGroup.get('type').touched
              "
              ><span>Please choose a type</span>
            </mat-error>
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>Category</mat-label>
            <mat-select
              formControlName="category"
              panelClass="mat-app-background"
              [(value)]="storeLocation.storeLocationCategory"
            >
              <mat-option
                *ngFor="let storeLocationCategory of storeLocationCategories"
                [value]="storeLocationCategory.value"
              >
                {{ storeLocationCategory.viewValue }}
              </mat-option>
            </mat-select>
            <mat-error
              *ngIf="
                storeLocationDetailsFormGroup
                  .get('category')
                  .hasError('required') &&
                storeLocationDetailsFormGroup.get('category').touched
              "
              ><span>Please choose a category</span>
            </mat-error>
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>Phone Number</mat-label>
            <ngx-mat-intl-tel-input
              [preferredCountries]="['nl', 'be', 'lu']"
              [enablePlaceholder]="true"
              [enableSearch]="true"
              inputPlaceholder="Ex. 020 1234567"
              name="phone"
              describedBy="phoneInput"
              formControlName="phoneNumber"
            >
            </ngx-mat-intl-tel-input>
            <mat-error
              *ngIf="
                storeLocationDetailsFormGroup
                  .get('phoneNumber')
                  .hasError('required') &&
                storeLocationDetailsFormGroup.get('phoneNumber').touched
              "
              ><span>Phone number is required</span>
            </mat-error>
            <mat-error
              *ngIf="
                storeLocationDetailsFormGroup
                  .get('phoneNumber')
                  .hasError('validatePhoneNumber') &&
                storeLocationDetailsFormGroup.get('phoneNumber').touched
              "
              ><span>Invalid phone number</span></mat-error
            >
            <mat-error
              *ngIf="
                storeLocationDetailsFormGroup
                  .get('phoneNumber')
                  .hasError('minlength') &&
                storeLocationDetailsFormGroup.get('phoneNumber').touched
              "
            >
              <span
                >Phone number should have a minimum length of
                {{ minDescriptionLength }}</span
              >
            </mat-error>
            <mat-error
              *ngIf="
                storeLocationDetailsFormGroup
                  .get('phoneNumber')
                  .hasError('maxlength') &&
                storeLocationDetailsFormGroup.get('phoneNumber').touched
              "
            >
              <span
                >Phone number can have a maximum length of
                {{ maxDescriptionLength }}</span
              >
            </mat-error>
          </mat-form-field>
        </div>
        <div class="container-column">
          <mat-form-field appearance="outline">
            <mat-label>Address Line 1</mat-label>
            <input
              matInput
              placeholder="Ex. Broadway 12345"
              formControlName="addressLine1"
            />
            <mat-error
              *ngIf="
                storeLocationDetailsFormGroup
                  .get('addressLine1')
                  .hasError('required') &&
                storeLocationDetailsFormGroup.get('addressLine1').touched
              "
            >
              <span>Address Line 1 is required</span>
            </mat-error>
            <mat-error
              *ngIf="
                storeLocationDetailsFormGroup
                  .get('addressLine1')
                  .hasError('minlength') &&
                storeLocationDetailsFormGroup.get('addressLine1').touched
              "
            >
              <span
                >Address line 1 should have a minimum length of
                {{ minAddressLineLength }}</span
              >
            </mat-error>
            <mat-error
              *ngIf="
                storeLocationDetailsFormGroup
                  .get('addressLine1')
                  .hasError('maxlength') &&
                storeLocationDetailsFormGroup.get('addressLine1').touched
              "
            >
              <span
                >Address line 1 can have a maximum length of
                {{ maxAddressLineLength }}</span
              >
            </mat-error>
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>Address Line 2</mat-label>
            <input
              matInput
              placeholder="Ex. office no."
              formControlName="addressLine2"
            />
            <mat-error
              *ngIf="
                storeLocationDetailsFormGroup
                  .get('addressLine2')
                  .hasError('minlength') &&
                storeLocationDetailsFormGroup.get('addressLine2').touched
              "
            >
              <span
                >Address line 2 should have a minimum length of
                {{ minAddressLineLength }}</span
              >
            </mat-error>
            <mat-error
              *ngIf="
                storeLocationDetailsFormGroup
                  .get('addressLine2')
                  .hasError('maxlength') &&
                storeLocationDetailsFormGroup.get('addressLine2').touched
              "
            >
              <span
                >Address line 2 can have a maximum length of
                {{ maxAddressLineLength }}</span
              >
            </mat-error>
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>Postal Code</mat-label>
            <input
              matInput
              placeholder="Ex. 1234AB"
              formControlName="postalCode"
            />
            <mat-error
              *ngIf="
                storeLocationDetailsFormGroup
                  .get('postalCode')
                  .hasError('required') &&
                storeLocationDetailsFormGroup.get('postalCode').touched
              "
            >
              <span>Postal code is required</span>
            </mat-error>
            <mat-error
              *ngIf="
                storeLocationDetailsFormGroup
                  .get('postalCode')
                  .hasError('minlength') &&
                storeLocationDetailsFormGroup.get('postalCode').touched
              "
            >
              <span
                >Postal code should have a minimum length of
                {{ minPostalCodeLength }}</span
              >
            </mat-error>
            <mat-error
              *ngIf="
                storeLocationDetailsFormGroup
                  .get('postalCode')
                  .hasError('maxlength') &&
                storeLocationDetailsFormGroup.get('postalCode').touched
              "
            >
              <span
                >Postal code can have a maximum length of
                {{ maxPostalCodeLength }}</span
              >
            </mat-error>
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>City</mat-label>
            <input
              matInput
              placeholder="Ex. New York City"
              formControlName="city"
            />
            <mat-error
              *ngIf="
                storeLocationDetailsFormGroup
                  .get('city')
                  .hasError('required') &&
                storeLocationDetailsFormGroup.get('city').touched
              "
            >
              <span>City is required</span>
            </mat-error>
            <mat-error
              *ngIf="
                storeLocationDetailsFormGroup
                  .get('city')
                  .hasError('minlength') &&
                storeLocationDetailsFormGroup.get('city').touched
              "
            >
              <span
                >City should have a minimum length of
                {{ minCityLength }}</span
              >
            </mat-error>
            <mat-error
              *ngIf="
                storeLocationDetailsFormGroup
                  .get('city')
                  .hasError('maxlength') &&
                storeLocationDetailsFormGroup.get('city').touched
              "
            >
              <span
                >City can have a maximum length of
                {{ maxCityLength }}</span
              >
            </mat-error>
          </mat-form-field>

          <app-country-selector
            label="Country"
            selectedCountry="{{
              storeLocationDetailsFormGroup.get('country').value
            }}"
            countryControlName="country"
            requiredErrorMessage="Please select a country"
            [formGroup]="storeLocationDetailsFormGroup"
          ></app-country-selector>
        </div>
        <div class="container-column">
          <mat-form-field appearance="outline">
            <mat-label>Status</mat-label>
            <input
              matInput
              readonly
              value="{{ storeLocation?.storeLocationStatus }}"
            />
          </mat-form-field>
          <app-store-location-options-edit
            class="container-column"
            [storeLocationDetailsFormGroup]="storeLocationDetailsFormGroup"
            [storeLocationOptions]="storeLocation?.storeLocationOptions"
          ></app-store-location-options-edit>
        </div>
      </div>
    </mat-card-content>
    <mat-card-actions>
      <div class="container-actions">
        <button mat-icon-button (click)="cancel()">
          <mat-icon>cancel</mat-icon>
        </button>
        <button mat-icon-button (click)="save()">
          <mat-icon>save</mat-icon>
        </button>
      </div>
    </mat-card-actions>
  </mat-card>
</div>
