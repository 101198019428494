<div class="container" [formGroup]="yearlyRepeatRuleFormGroup">
  <mat-radio-group class="container-choice" aria-label="which month" formControlName="onYearlyChoice">
    <mat-radio-button value="on-day-of-month">
      <mat-form-field appearance="outline">
        <mat-label>On</mat-label>
        <mat-select
          formControlName="recurrenceRepeatRuleYearlyOnMonthMonth"
          panelClass="mat-app-background"
        >
          <mat-option
            *ngFor="let recurrenceYearlyOnMonth of recurrenceYearlyOnMonths"
            [value]="recurrenceYearlyOnMonth.value"
          >
            {{ recurrenceYearlyOnMonth.viewValue }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>The</mat-label>
        <mat-select
          formControlName="recurrenceRepeatRuleYearlyOnMonthDay"
          panelClass="mat-app-background"
        >
          <mat-option *ngFor="let val of dayRange()" [value]="val.toString()"
            >{{ val }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </mat-radio-button>

    <mat-radio-button value="on-which-position-day-of-month">
      <mat-form-field appearance="outline">
        <mat-label>On The</mat-label>
        <mat-select
          formControlName="recurrenceRepeatRuleYearlyOnWhichDayPositionMonth"
          panelClass="mat-app-background"
        >
          <mat-option
            *ngFor="let val of recurrenceYearlyOnMonthWhichPositions"
            [value]="val.value"
            >{{ val.viewValue }}</mat-option
          >
        </mat-select>
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>Day</mat-label>
        <mat-select
          formControlName="recurrenceRepeatRuleYearlyOnWhichDayMonth"
          panelClass="mat-app-background"
        >
          <mat-option
            *ngFor="let val of recurrenceYearlyOnMonthWhichDays"
            [value]="val.value"
            >{{ val.viewValue }}</mat-option
          >
        </mat-select>
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>Of</mat-label>
        <mat-select
          formControlName="recurrenceRepeatRuleYearlyOnWhichMonthMonth"
          panelClass="mat-app-background"
        >
          <mat-option
            *ngFor="let recurrenceYearlyOnMonth of recurrenceYearlyOnMonths"
            [value]="recurrenceYearlyOnMonth.value"
          >
            {{ recurrenceYearlyOnMonth.viewValue }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </mat-radio-button>
  </mat-radio-group>
</div>
