import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Store } from '@ngrx/store';
import { Observable, Subject, of } from 'rxjs';
import { filter, last, takeUntil } from 'rxjs/operators';
import { SpinnerDialogComponent } from 'src/app/core/components/dialogs/spinner-dialog/spinner-dialog.component';
import { HasUnpaidInvoiceException } from 'src/app/core/exceptions/has-unpaid-invoice-exception';
import { NoActiveSubscriptionException } from 'src/app/core/exceptions/no-active-subscription-exception';
import { ValidationException } from 'src/app/core/exceptions/validation-exception';
import { ActiveSubscriptionPlanOrderModel } from 'src/app/models/active-subscription-plan-order-model';
import { BusinessAccountModel } from 'src/app/models/business-account-model';
import { UpdateBillingDetailsModel } from 'src/app/models/update-billing-details-model';
import { SpinnerService } from 'src/app/services/spinner.service';
import { BillingDetailsComponentStore } from 'src/app/stores/components/billing-details/billing-details.component-store';
import { BillingComponentStore } from 'src/app/stores/components/billing/billing.component-store';
import { BusinessAccountDetailComponentStore } from 'src/app/stores/components/business-account-detail/business-account-detail.component-store';
import { businessAccountGetAction } from 'src/app/stores/global/app.actions';
import { selectBusinessAccount, selectHasSubscriptionPlanOrder, selectUnpaidInvoice } from 'src/app/stores/global/app.selectors';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-billing-page',
  templateUrl: './billing-page.component.html',
  styleUrls: ['./billing-page.component.scss']
})
export class BillingPageComponent implements OnInit {
  timeLeft: number = 30;
  timerInterval: any;

  accountPortalUrl: string = environment.accountPortalUrl;
  businessAccount$ = this.store.select(selectBusinessAccount);
  billingDetails$ = this.billingDetailsComponentStore.billingDetails$;
  unpaidInvoice$ = this.store.select(selectUnpaidInvoice);
  readMode: boolean = true;
  showPricingBanner: boolean;
  currentActiveSubscriptionPlanOrder$: Observable<ActiveSubscriptionPlanOrderModel>;

  billingDetailsFormGroup: FormGroup;
  ngUnsubscribeState = new Subject<void>();

  private loadingSpinnerDialogRef: MatDialogRef<SpinnerDialogComponent>;
  private businessAccount: BusinessAccountModel;

  constructor(
    private businessAccountDetailComponentStore: BusinessAccountDetailComponentStore,
    private billingDetailsComponentStore: BillingDetailsComponentStore,
    private billingComponentStore: BillingComponentStore,
    private snackBar: MatSnackBar,
    private spinnerService: SpinnerService,
    private formBuilder: FormBuilder,
    private store: Store,
  ) { }

  ngOnInit(): void {
    this.billingDetailsFormGroup = this.formBuilder.group({
      id: ['', Validators.required],
      addressLine1: ['', Validators.required],
      addressLine2: [''],
      postalCode: ['', Validators.required],
      city: ['', Validators.required],
      country: ['', Validators.required],
      phoneNumber: ['', [Validators.required]],
      vatNumber: ['']
    });

    this.store.select(selectBusinessAccount).pipe(takeUntil(this.ngUnsubscribeState), filter(result => Boolean(result))).subscribe(businessAccount => {
      this.businessAccount = businessAccount;
      this.billingDetailsComponentStore.getBillingDetails({ businessAccountId: businessAccount.id });
      this.billingComponentStore.getActiveSubscriptionPlanOrders({ companyId: businessAccount.company.id, currentPage: 0, pageSize: 1000 });
    });

    this.store.select(selectHasSubscriptionPlanOrder).pipe(takeUntil(this.ngUnsubscribeState)).subscribe((hasSubscriptionPlanOrder) => {
      if (hasSubscriptionPlanOrder) {
        this.showPricingBanner = false;
      }
      else {
        this.showPricingBanner = true;
        this.currentActiveSubscriptionPlanOrder$ = of(null);
      }
    });

    this.businessAccountDetailComponentStore.updateSuccess$.pipe(takeUntil(this.ngUnsubscribeState), filter(result => Boolean(result))).subscribe((result) => {
      if (result) {
        this.businessAccountDetailComponentStore.setInitialAfterUpdate();
        this.snackBar.open("Account billing details updated successfully", "Dismiss");
      }
    });

    this.billingDetailsComponentStore.updateSuccess$.pipe(takeUntil(this.ngUnsubscribeState), filter(result => Boolean(result))).subscribe((result) => {
      this.hideSpinner(true);
      if (result) {
        this.snackBar.open("Account billing details updated successfully", "Dismiss");
        this.onCancelBillingDetails(null);
      }
    });

    this.billingDetailsComponentStore.loaded$.pipe(takeUntil(this.ngUnsubscribeState)).subscribe((loaded) => {
      this.hideSpinner(loaded);
    });

    this.billingDetailsComponentStore.errorMessage$.pipe(takeUntil(this.ngUnsubscribeState)).subscribe((errorMessage) => {
      if (errorMessage !== null && errorMessage !== undefined) {
        if (errorMessage instanceof ValidationException) {
          const validationViolationMessage = errorMessage.violations.map(v => v.message).join("\n");
          this.snackBar.open(validationViolationMessage, "Dismiss");
        }
        else {
          this.snackBar.open(errorMessage, "Dismiss");
        }
      }
    });

    this.billingComponentStore.loaded$.pipe(takeUntil(this.ngUnsubscribeState), filter(result => result)).subscribe(loaded => this.hideSpinner(loaded));

    this.billingComponentStore.activeSubscriptionPlanOrders$.pipe(takeUntil(this.ngUnsubscribeState), filter(result => Boolean(result) && result.length > 0)).subscribe(
      activeSubscriptionPlanOrders => {
        this.currentActiveSubscriptionPlanOrder$ = of(activeSubscriptionPlanOrders[0]);
      }
    );

    this.billingComponentStore.errorMessage$.pipe(takeUntil(this.ngUnsubscribeState)).subscribe((errorMessage) => {
      if (errorMessage !== null && errorMessage !== undefined) {
        if (errorMessage instanceof NoActiveSubscriptionException) {
          this.snackBar.open("You have no active subscription plan order.", "Dismiss");
        }
        else if (errorMessage instanceof HasUnpaidInvoiceException) {
          this.snackBar.open("You have an unpaid invoice. Please pay the invoice first before cancelling your subscription plan order.", "Dismiss");
        }
        else {
          this.snackBar.open(errorMessage, "Dismiss");
        }
      }
    });

    this.billingComponentStore.subscriptionCancellationSuccess$.pipe(takeUntil(this.ngUnsubscribeState)).subscribe(result => {
      if (result) {
        this.hideSpinner(true);
        this.snackBar.open("Your subscription plan order is cancelled. Sorry to see you leave.", "Dismiss");
        this.showPricingBanner = true;
        this.currentActiveSubscriptionPlanOrder$ = of(null);
        setTimeout(_ => this.store.dispatch(businessAccountGetAction()), 5000);
      }
    });
  }

  ngOnDestroy() {
    this.ngUnsubscribeState.next();
    this.ngUnsubscribeState.complete();
  }

  onEditBillingDetails(event: any) {
    this.readMode = false;
  }

  onCancelBillingDetails(event: any) {
    this.readMode = true;
    // Reset the form data
  }

  onSaveBillingDetails(event: any) {
    // Dispatch the save action
    if (this.billingDetailsFormGroup.valid) {
      this.showSpinner();

      const updatedBillingDetails = new UpdateBillingDetailsModel()
      updatedBillingDetails.addressLine1 = this.billingDetailsFormGroup.value.addressLine1;
      updatedBillingDetails.addressLine2 = this.billingDetailsFormGroup.value.addressLine2;
      updatedBillingDetails.postalCode = this.billingDetailsFormGroup.value.postalCode;
      updatedBillingDetails.city = this.billingDetailsFormGroup.value.city;
      updatedBillingDetails.country = this.billingDetailsFormGroup.value.country;
      updatedBillingDetails.phoneNumber = this.billingDetailsFormGroup.value.phoneNumber;
      updatedBillingDetails.vatNumber = this.billingDetailsFormGroup.value.vatNumber;

      this.billingDetailsComponentStore.updateBillingDetails({ businessAccountId: this.businessAccount.id, updateBillingDetails: updatedBillingDetails });
    }
  }

 

  onCancelSubscriptionPlanOrder(): void {
    this.currentActiveSubscriptionPlanOrder$.pipe(takeUntil(this.ngUnsubscribeState), last()).subscribe(subscriptiptionPlanOrder => {
      this.showSpinner();
      const companyId = this.businessAccount.company.id;
      const subscriptiptionPlanOrderId = subscriptiptionPlanOrder.id;
      this.billingComponentStore.cancelSubscriptionPlanOrder({ companyId: companyId, subscriptionPlanOrderId: subscriptiptionPlanOrderId });
    });
  }

  private showSpinner() {
    this.loadingSpinnerDialogRef = this.spinnerService.show();
  }

  private hideSpinner(loaded: boolean) {
    if (loaded && this.loadingSpinnerDialogRef !== null && this.loadingSpinnerDialogRef !== undefined) {
      this.spinnerService.hide(this.loadingSpinnerDialogRef);
      this.loadingSpinnerDialogRef = null;
    }
  }
}
