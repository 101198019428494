<div class="container">
  <div [ngClass]="{
    'container-banner-xlarge': isXLarge(),
    'container-banner-large': isLarge(),
    'container-banner-medium': isMedium(),
    'container-banner-small': isSmall(),
    'container-banner-xsmall': isXSmall()
  }">
    <h1>Choose the plan that's right for you</h1>
    <h3>
      Our chefs have prepared the right plan for you.<br />
      Each plan gives the best promotion and loyalty experience to your customers.
    </h3>
  </div>
  <div class="container-divider">
    <img src="/assets/img/planetvg_icon.png" alt="planetvg-logo" />
  </div>
</div>