import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map, Observable, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { PhotoModel } from '../models/photo-model';
import { UploadPhotoModel } from '../models/upload-photo-model';

class UploadPhotoApiModel {
  fileName: string;
  fileContentBase64: string;
  title: string;
}

@Injectable({
  providedIn: 'root'
})
export class PromotionLogoService {

  constructor(private http: HttpClient) {
    // Do nothing
  }

  getPromotionLogo(companyId: string, promotionId: number): Observable<PhotoModel> {
    const httpOptions = {
      headers: new HttpHeaders({
        ContentType: 'application/json',
      }),
    };

    const url = environment.apiUrl + '/api/partner/companies/' + companyId + '/promotions/' + promotionId + '/logo';
    const result = this.http.get<PhotoModel>(url, httpOptions);
    return result.pipe(
      map(data => data),
      catchError(
        this.handleError<PhotoModel>('getPromotionLogo', null)
      )
    );
  }

  uploadPromotionLogo(companyId: string, promotionId: number, uploadPhoto: UploadPhotoModel): Observable<PhotoModel> {
    const httpOptions = {
      headers: new HttpHeaders({
        ContentType: 'application/json',
      }),
    };

    const uploadPhotoApiModel = new UploadPhotoApiModel();
    uploadPhotoApiModel.title = uploadPhoto.title;
    uploadPhotoApiModel.fileContentBase64 = uploadPhoto.fileContentBase64;
    uploadPhotoApiModel.fileName = uploadPhoto.fileName;

    const url = environment.apiUrl + '/api/partner/companies/' + companyId + '/promotions/' + promotionId + '/logo';
    const result = this.http.post<PhotoModel>(url, uploadPhoto, httpOptions);
    return result.pipe(
      map(data => data),
      catchError(
        this.handleError<PhotoModel>('uploadPromotionLogo', null)
      )
    );
  }

  deletePromotionLogo(companyId: string, promotionId: number) {
    const httpOptions = {
      headers: new HttpHeaders({
        ContentType: 'application/json',
      }),
    };

    const url = environment.apiUrl + '/api/partner/companies/' + companyId + '/promotions/' + promotionId + '/logo';
    const result = this.http.delete(url, httpOptions);
    return result.pipe(catchError(this.handleError('deletePromotionLogo', null)));
  }

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param _operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T>(_operation = 'operation', result?: T) {
    return (_error: any): Observable<T> => {
      return of(result);
    };
  }
}
