import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-buy-subscription-plan-banner',
  templateUrl: './buy-subscription-plan-banner.component.html',
  styleUrls: ['./buy-subscription-plan-banner.component.scss']
})
export class BuySubscriptionPlanBannerComponent {
  
  constructor(private router: Router){}

  buySubscriptionPlan() {
    this.router.navigate(['/pricing']);
  }
}
