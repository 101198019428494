import { Component } from '@angular/core';

@Component({
  selector: 'app-planet-b-page',
  templateUrl: './planet-b-page.component.html',
  styleUrls: ['./planet-b-page.component.scss']
})
export class PlanetBPageComponent {

}
