<div class="container">
  <div [ngClass]="{
    'container-item-xlarge': isXLarge(),
    'container-item-large': isLarge(),
    'container-item-medium': isMedium(),
    'container-item-small': isSmall(),
    'container-item-xsmall': isXSmall()
  }">
    <h2 class="item-title">Advertise</h2>
    <h3 class="item-subtitle">Give away vouchers to promote your new menu, products or other services to your customers, whether they are new or existing.</h3>
    <div >
      <mat-list>
        <mat-list-item [ngClass]="{
          'list-item-xsmall': isXSmall()
        }">
          <span matListItemIcon>
            <mat-icon color="primary">eco</mat-icon>
          </span>
          <div matListItemTitle class="list-item-title">Generate traffic to your business.</div>
        </mat-list-item>
        <mat-list-item [ngClass]="{
          'list-item-xsmall': isXSmall()
        }">
          <span matListItemIcon>
            <mat-icon color="primary">eco</mat-icon>
          </span>
          <div matListItemTitle class="list-item-title">Increase your client base.</div>
        </mat-list-item>
        <mat-list-item [ngClass]="{
          'list-item-xsmall': isXSmall()
        }">
          <span matListItemIcon>
            <mat-icon color="primary">eco</mat-icon>
          </span>
          <div matListItemTitle class="list-item-title">Engage with new members.</div>
        </mat-list-item>
      </mat-list>
    </div>
  </div>

  <div [ngClass]="{
    'container-item-card-xlarge': isXLarge(),
    'container-item-card-large': isLarge(),
    'container-item-card-medium': isMedium(),
    'container-item-card-small': isSmall(),
    'container-item-card-xsmall': isXSmall()
  }">
    <mat-card appearance="outlined" class="mat-component-background mat-elevation-z2">
      <mat-card-header>
        <mat-card-title>Promotions</mat-card-title>
        <mat-card-subtitle>Breakfast : 7h - 12h </mat-card-subtitle>
      </mat-card-header>
      <div [ngClass]="{
        'container-product-banner-image-xlarge': isXLarge(),
        'container-product-banner-image-large': isLarge(),
        'container-product-banner-image-medium': isMedium(),
        'container-product-banner-image-small': isSmall(),
        'container-product-banner-image-xsmall': isXSmall()
      }">
        <img src="/assets/img/banners/xlarge/product-banner-breakfast-promotions.webp" alt="breakfast-bring-in-new-customers" />
      </div>
      <mat-card-actions></mat-card-actions>
    </mat-card>
  </div>
</div>