<div class="container">
  <div *ngIf="(viewModel$ | async)?.loading; else tryAgain">
    <div class="container-banner">
      <p>You will be redirected to a stripe billing portal.</p>
      <p> If it not this url: https://billing.stripe.com, please contact support.</p>
    </div>
    <div class="spinner-container">
      <mat-spinner diameter="80"></mat-spinner>
    </div>
  </div>
</div>

<ng-template #tryAgain>
  <div *ngIf="(viewModel$ | async)?.loaded && !(viewModel$ | async)?.success">
    <p>something went wrong with creating a session for you. Please try again.</p>>
  </div>
</ng-template>