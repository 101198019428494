import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { countryList } from './country-list';
import { Country } from './country-model';

@Component({
  selector: 'app-country-selector',
  templateUrl: './country-selector.component.html',
  styleUrls: ['./country-selector.component.scss']
})
export class CountrySelectorComponent implements OnInit {
  @Input()
  countryControlName: string;

  @Input()
  formGroup: UntypedFormGroup;

  @Input()
  label : string;

  @Input()
  selectedCountry: string;

  @Input()
  requiredErrorMessage: string;

  @Output()
  onCountrySelected = new EventEmitter();

  countries : Country[] = countryList;

  constructor(){
    // Do nothing
  }

  ngOnInit(): void {
    const countryDefault = this.countries.find(c => c.name.startsWith(this.selectedCountry));
    if(countryDefault){
      this.formGroup.get(this.countryControlName).patchValue(countryDefault?.code);
    }
  }  
}
