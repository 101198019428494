import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Store } from '@ngrx/store';
import { EChartsOption } from 'echarts';
import { Observable, Subject, filter, takeUntil } from 'rxjs';
import { BusinessAccountModel } from 'src/app/models/business-account-model';
import { StatsTotalMembersChartComponentStore } from 'src/app/stores/components/stats-total-members-chart/stats-total-members-chart.component-store';
import { selectBusinessAccount } from 'src/app/stores/global/app.selectors';

@Component({
  selector: 'app-stats-total-members-chart',
  templateUrl: './stats-total-members-chart.component.html',
  styleUrls: ['./stats-total-members-chart.component.scss']
})
export class StatsTotalMembersChartComponent {
  businessAccount$: Observable<BusinessAccountModel> = this.store.select(selectBusinessAccount);
  ngUnsubscribeState = new Subject<void>();
  options: EChartsOption;
  isLoading = true;

  private _isLarge: boolean
  private _isMedium: boolean;
  private _isSmall: boolean;
  private _isXSmall: boolean;

  constructor(
    private store: Store<any>,
    private statsTotalMembersChartComponentStore: StatsTotalMembersChartComponentStore,
    private snackBar: MatSnackBar,
    private responsive: BreakpointObserver
  ) { }

  ngOnInit(): void {
    this.businessAccount$.pipe(takeUntil(this.ngUnsubscribeState), filter(result => Boolean(result))).subscribe(
      businessAccount => {
        this.statsTotalMembersChartComponentStore.getCompanyMemberSummary({ companyId: businessAccount.company.id });
      });

    this.statsTotalMembersChartComponentStore.loading$.pipe(takeUntil(this.ngUnsubscribeState)).subscribe(loading => {
      this.isLoading = loading;
    });

    this.statsTotalMembersChartComponentStore.loaded$.pipe(takeUntil(this.ngUnsubscribeState)).subscribe(loaded => {
      this.isLoading = !loaded;
    });

    this.statsTotalMembersChartComponentStore.companyMemberSummary$.pipe(takeUntil(this.ngUnsubscribeState), filter(result => Boolean(result))).subscribe(companyMemberSummary => {
      const maxValue = this._calculateMaxValue(companyMemberSummary.totalMembers);
      this._initializeChart(companyMemberSummary.totalMembers, maxValue);
    });

    this.statsTotalMembersChartComponentStore.errorMessage$.pipe(takeUntil(this.ngUnsubscribeState)).subscribe((errorMessage) => {
      if (errorMessage !== null && errorMessage !== undefined) {
        this.statsTotalMembersChartComponentStore.setInitial();
        this.snackBar.open(errorMessage, "Dismiss");
      }
    });

    this.responsive.observe([Breakpoints.XSmall, Breakpoints.Small, Breakpoints.Medium, Breakpoints.Large]).subscribe(
      result => {
        const breakpoints = result.breakpoints;

        this._isLarge = false;
        this._isMedium = false;
        this._isSmall = false;
        this._isXSmall = false;

        if (breakpoints[Breakpoints.Medium]) {
          this._isMedium = true;
        }
        else if (breakpoints[Breakpoints.Small]) {
          this._isSmall = true;
        }
        else if (breakpoints[Breakpoints.XSmall]) {
          this._isXSmall = true;
        }
        else {
          this._isLarge = true;
        }
      }
    );
  }

  ngOnDestroy() {
    this.ngUnsubscribeState.next();
    this.ngUnsubscribeState.complete();
  }

  isLarge(): boolean {
    return this._isLarge;
  }

  isMedium(): boolean {
    return this._isMedium;
  }
  isSmall(): boolean {
    return this._isSmall;
  }
  isXSmall(): boolean {
    return this._isXSmall;
  }

  _initializeChart(value: number, maxValue: number) {
    this.options = {
      textStyle: { color: '#383838' },
      tooltip: {
        formatter: '{b} <br/> {c}'
      },
      series: [
        {
          name: 'Members',
          type: 'gauge',
          detail: {
            formatter: '{value}',
            color: '#383838'
          },
          itemStyle: {
            color: '#d86caa',
          },
          progress: {
            show: true,
            roundCap: false,
            width: 10
          },
          axisLine: {
            lineStyle: {
              width: 10,
              color: [
                [1, '#383838']
              ]
            }
          },
          max: maxValue,
          data: [
            {
              value: value,
              name: 'Members'
            }
          ]
        }
      ]
    };
  }

  _calculateMaxValue(value: number): number {
    if (value <= 10) {
      return 10;
    }
    else if (value < 50) {
      return 50;
    }
    else if (value < 100) {
      return 100;
    }
    else if (value < 500) {
      return 500;
    }
    else if (value < 1000) {
      return 1000;
    }
    else if (value < 5000) {
      return 5000;
    }
    else if (value < 10000) {
      return 10000;
    }
    else if (value < 50000) {
      return 50000;
    }
    else if (value < 100000) {
      return 100000;
    }
    else if (value < 500000) {
      return 500000;
    }
    return 1000000;
  }
}
