import { Injectable } from "@angular/core";
import { ComponentStore } from "@ngrx/component-store";
import { catchError, map, Observable } from "rxjs";
import { PaymentRequestModel } from "src/app/models/payment-request-model";
import { CheckoutService } from "src/app/services/checkout.service";
import { CheckoutFormState } from "./checkout.state";

const initialState: CheckoutFormState = {
    loading: false,
    loaded: false,
    success: false,
    errorMessage: null,
    paymentRequestData : null,
};

@Injectable({ providedIn: 'root' })
export class CheckoutFormComponentStore extends ComponentStore<CheckoutFormState> {
    constructor(private readonly checkoutService: CheckoutService) {
        super(initialState)
    }

    readonly loading$: Observable<boolean> = this.select(state => state.loading);
    readonly loaded$: Observable<boolean> = this.select(state => state.loaded);
    readonly success$: Observable<boolean> = this.select(state => state.success);
    readonly errorMessage$: Observable<any> = this.select(state => state.errorMessage);

    readonly setInitial = this.updater(( _ : CheckoutFormState) => {
        return {
            ...initialState
        };
    });

    readonly setLoading = this.updater((state: CheckoutFormState) => {
        return {
            ...state,
            loading: true,
            loaded: false
        };
    });

    readonly setLoaded = this.updater((state: CheckoutFormState) => {
        return {
            ...state,
            loading: false,
            loaded: true
        };
    });

    readonly setError = this.updater((state: CheckoutFormState, value: { errorMessage: string }) => {
        return {
            ...state,
            loading: false,
            loaded: false,
            success: false,
            errorMessage: value.errorMessage
        };
    });

    readonly setSuccess = this.updater((state: CheckoutFormState) => {
        return {
            ...state,
            loading: false,
            loaded: false,
            success: true,
            errorMessage: null
        };
    });

    readonly doCheckout = this.effect((checkoutParams$: Observable<{ paymentRequestData: PaymentRequestModel }>) => {
        this.setLoading();
        return checkoutParams$.pipe(
            map(async params => {
                await this.checkoutService.checkoutShoppingCart(params.paymentRequestData);
                this.setLoaded();
                this.setInitial();
            }),
            catchError(async (error) => {
                this.setError({errorMessage: error});
                this.setLoaded();
                this.setInitial();
            })
        )
        }
    );
}