import { Action, createReducer, on } from "@ngrx/store";
import { subscriptionPlansGetAction, subscriptionPlansGetActionFailure, subscriptionPlansGetActionSuccess } from "./subscriptionplans.actions";
import { SubscriptionPlansState } from "./subscriptionplans.state";

export const SUBSCRIPTIONPLANS_GET_REDUCER_KEY = "subscriptionPlansGetReducerKey";

export const initialSubscriptionPlansGetState: SubscriptionPlansState = {
    subscriptionPlans: null,
    loading: false,
    loaded: false,
    success: false,
    errorMessage: null,
};

export const subscriptionPlansGetReducer = createReducer(
    initialSubscriptionPlansGetState,
    on(
        subscriptionPlansGetAction, (state: SubscriptionPlansState) => ({
            ...state,
            loading: true
        })
    ),
    on(
        subscriptionPlansGetActionSuccess, (state: SubscriptionPlansState, {subscriptionPlans }) => ({ ...state, loading: false, success: true, loaded: true, subscriptionPlans: subscriptionPlans })
    ),
    on(
        subscriptionPlansGetActionFailure, (state: SubscriptionPlansState, { error }) => ({ ...state, loading: false, success: false, loaded: true, errorMessage: error })
    )
);

export function subscriptionPlansGetReducerExport(state: SubscriptionPlansState | undefined, action: Action) {
    return subscriptionPlansGetReducer(state, action);
}