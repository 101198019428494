import { Component, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { RRule } from 'rrule';

interface EndRecurrenceRule {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'app-end-rule',
  templateUrl: './end-rule.component.html',
  styleUrls: ['./end-rule.component.scss']
})
export class EndRuleComponent {
  @Input()
  endDateRuleFormGroup: UntypedFormGroup;

  @Input()
  rrule: RRule;

  selectedValue: string;

  endRecurrenceRules: EndRecurrenceRule[] = [
    { value: 'never', viewValue: 'Never' },
    { value: 'after', viewValue: 'After' },
    { value: 'on-date', viewValue: 'On Date' },
  ];

  ngOnInit(): void {
    if (this.rrule) {
      if (this.rrule.options.count) {
        this.selectedValue = 'after';
        this.endDateRuleFormGroup.patchValue({ 'recurrenceEndRule': this.selectedValue });
        this.endDateRuleFormGroup.patchValue({ 'recurrenceEndRuleOccurrences': this.rrule.options.count });
      }
      else if (this.rrule.options.until) {
        this.selectedValue = 'on-date';
        this.endDateRuleFormGroup.patchValue({ 'recurrenceEndRule': this.selectedValue });
        this.endDateRuleFormGroup.patchValue({ 'recurrenceEndRuleEndDate': new Date(this.rrule.options.until) });
      }
      else {
        this.selectedValue = 'never';
        this.endDateRuleFormGroup.patchValue({ 'recurrenceEndRule': this.selectedValue });
      }
    }
    else {
      this.selectedValue = 'never';
      this.endDateRuleFormGroup.patchValue({ 'recurrenceEndRule': this.selectedValue });
    }
  }
}
