import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { MaximumActiveStoreLocationsExceededException } from '../core/exceptions/maximum-active-store-locations-exceeded-exception';
import { NoActiveSubscriptionException } from '../core/exceptions/no-active-subscription-exception';
import { UnknownErrorException } from '../core/exceptions/unknown-error-exception';
import { CompanyModel } from '../models/company-model';
import { CreateStoreLocationModel } from '../models/create-store-location-model';
import { Page } from '../models/paging/page-model';
import { StoreLocationModel } from '../models/store-location-model';
import { UpdateStoreLocationModel } from '../models/update-store-location-model';
import { ValidationException } from '../core/exceptions/validation-exception';
import { StoreLocationCategory } from '../models/store-location-category-model';
import { StoreLocationOption } from '../models/store-location-option-model';
import { StoreLocationType } from '../models/store-location-type-model';

class CreateStoreLocationApiModel {
  name: string;
  description: string;
  addressLine1: string;
  addressLine2: string;
  postalCode: string;
  city: string;
  country: string;
  phoneNumber: string;
  storeLocationType : StoreLocationType;
  storeLocationCategory: StoreLocationCategory;
  storeLocationOptions: StoreLocationOption[];
}

class UpdateStoreLocationApiModel {
  name: string;
  description: string;
  addressLine1: string;
  addressLine2: string;
  postalCode: string;
  city: string;
  country: string;
  phoneNumber: string;
  storeLocationStatus: string;
  storeLocationType : StoreLocationType;
  storeLocationCategory: StoreLocationCategory;
  storeLocationOptions: StoreLocationOption[];
}

@Injectable({
  providedIn: 'root'
})
export class StoreLocationService {
  constructor(private http: HttpClient) { }

  getStoreLocations(companyId: string, currentPage: number, pageSize: number): Observable<Page<StoreLocationModel>> {
    let httpParams = new HttpParams();
    httpParams = httpParams.append("page", currentPage.toString());
    httpParams = httpParams.append("size", pageSize.toString());

    const httpOptions = {
      headers: new HttpHeaders({
        ContentType: 'application/json',
      }),
      params: httpParams
    };

    const url = environment.apiUrl + '/api/partner/companies/' + companyId + '/storelocations';
    const result = this.http.get<Page<StoreLocationModel>>(url, httpOptions);
    return result.pipe(
      catchError(
        this.handleError<Page<StoreLocationModel>>('getStoreLocations', null)
      )
    );
  }

  getStoreLocation(companyId: string, storeLocationId: string): Observable<StoreLocationModel> {
    const httpOptions = {
      headers: new HttpHeaders({
        ContentType: 'application/json',
      })
    };

    const url = environment.apiUrl + '/api/partner/companies/' + companyId + '/storelocations/' + storeLocationId;
    const result = this.http.get<StoreLocationModel>(url, httpOptions);

    return result.pipe(
      map(data => this.mapFromData(data)),
      catchError(
        this.handleError<StoreLocationModel>('getStoreLocation', null)
      )
    );
  }

  updateStoreLocation(companyId: string, updateStoreLocation: UpdateStoreLocationModel): Observable<StoreLocationModel> {
    const httpOptions = {
      headers: new HttpHeaders({
        ContentType: 'application/json',
      }),
    };

    const updateStoreLocationApiModel = new UpdateStoreLocationApiModel();
    updateStoreLocationApiModel.name = updateStoreLocation.name;
    updateStoreLocationApiModel.description = updateStoreLocation.description;
    updateStoreLocationApiModel.addressLine1 = updateStoreLocation.addressLine1;
    updateStoreLocationApiModel.addressLine2 = updateStoreLocation.addressLine2;
    updateStoreLocationApiModel.postalCode = updateStoreLocation.postalCode;
    updateStoreLocationApiModel.city = updateStoreLocation.city;
    updateStoreLocationApiModel.country = updateStoreLocation.country;
    updateStoreLocationApiModel.phoneNumber = updateStoreLocation.phoneNumber;
    updateStoreLocationApiModel.storeLocationStatus = updateStoreLocation.storeLocationStatus;
    updateStoreLocationApiModel.storeLocationType = updateStoreLocation.storeLocationType;
    updateStoreLocationApiModel.storeLocationCategory = updateStoreLocation.storeLocationCategory;
    updateStoreLocationApiModel.storeLocationOptions = updateStoreLocation.storeLocationOptions;

    const url = environment.apiUrl + '/api/partner/companies/' + companyId + '/storelocations/' + updateStoreLocation.id;
    const result = this.http.put<StoreLocationModel>(url, updateStoreLocationApiModel, httpOptions);
    return result.pipe(
      map(data => this.mapFromData(data)),
      catchError((response: HttpErrorResponse) => {

        if (response.status == 400) {
          if (response.error.violations) {
            const validationException = new ValidationException(response.error.violations);
            return throwError(() => validationException);
          }
          return throwError(() => new UnknownErrorException());
        }

        if (response.status == 409) {
          if (response.error == 'No active subscriptionplan order found') {
            return throwError(() => new NoActiveSubscriptionException());
          }
          else if (response.error == 'Maximum active store locations exceeded') {
            return throwError(() => new MaximumActiveStoreLocationsExceededException());
          }
          else {
            return throwError(() => new UnknownErrorException());
          }
        }
        return throwError(() => this.handleError('updateStoreLocation', null));
      })
    );
  }

  deleteStoreLocation(companyId: string, storeLocationId: number) {
    const httpOptions = {
      headers: new HttpHeaders({
        ContentType: 'application/json',
      }),
    };

    const url = environment.apiUrl + '/api/partner/companies/' + companyId + '/storelocations/' + storeLocationId;
    const result = this.http.delete(url, httpOptions);
    return result.pipe(catchError(this.handleError('deleteStoreLocation', null)));
  }

  createStoreLocation(companyId: string, createStoreLocation: CreateStoreLocationModel): Observable<StoreLocationModel> {
    const httpOptions = {
      headers: new HttpHeaders({
        ContentType: 'application/json',
      }),
    };

    const createStoreLocationApiModel = new CreateStoreLocationApiModel();
    createStoreLocationApiModel.name = createStoreLocation.name;
    createStoreLocationApiModel.description = createStoreLocation.description;
    createStoreLocationApiModel.addressLine1 = createStoreLocation.addressLine1;
    createStoreLocationApiModel.addressLine2 = createStoreLocation.addressLine2;
    createStoreLocationApiModel.postalCode = createStoreLocation.postalCode;
    createStoreLocationApiModel.city = createStoreLocation.city;
    createStoreLocationApiModel.country = createStoreLocation.country;
    createStoreLocationApiModel.phoneNumber = createStoreLocation.phoneNumber;
    createStoreLocationApiModel.storeLocationType = createStoreLocation.storeLocationType;
    createStoreLocationApiModel.storeLocationCategory = createStoreLocation.storeLocationCategory;
    createStoreLocationApiModel.storeLocationOptions = createStoreLocation.storeLocationOptions;

    const url = environment.apiUrl + '/api/partner/companies/' + companyId + '/storelocations';
    const result = this.http.post<StoreLocationModel>(url, createStoreLocationApiModel, httpOptions);
    return result.pipe(
      map(data => this.mapFromData(data)),
      catchError((response: HttpErrorResponse) => {
        if (response.status == 400) {
          if (response.error.violations) {
            const validationException = new ValidationException(response.error.violations);
            return throwError(() => validationException);
          }
          return throwError(() => new UnknownErrorException());
        }
        if (response.status == 403) {
          if (response.error.title === 'Maximum active store locations exceeded.') {
            return throwError(() => new MaximumActiveStoreLocationsExceededException());
          }
        }
        return throwError(() => this.handleError('createStoreLocation', null));
      })
    );
  }

  /**
     * Handle Http operation that failed.
     * Let the app continue.
     * @param operation - name of the operation that failed
     * @param result - optional value to return as the observable result
     */
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      console.error(error); // log to console instead
      // Let the app keep running by returning an empty result.
      return of(result);
    };
  }

  private mapFromData(data: StoreLocationModel): StoreLocationModel {
    const storeLocation = new StoreLocationModel();
    storeLocation.id = data.id;
    storeLocation.name = data.name;
    storeLocation.description = data.description;
    storeLocation.phoneNumber = data.phoneNumber;
    storeLocation.storeLocationStatus = data.storeLocationStatus;

    storeLocation.addressLine1 = data.addressLine1;
    storeLocation.addressLine2 = data.addressLine2;
    storeLocation.postalCode = data.postalCode;
    storeLocation.city = data.city;
    storeLocation.country = data.country;
    
    storeLocation.storeLocationType = data.storeLocationType;
    storeLocation.storeLocationCategory = data.storeLocationCategory;
    storeLocation.storeLocationOptions = data.storeLocationOptions;

    storeLocation.company = new CompanyModel(
      data.company.id,
      data.company.name,
      data.company.description,
      data.company.logo,
      data.company.email,
      data.company.url);

    return storeLocation;
  }
}
