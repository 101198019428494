import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { BusinessAccountModel } from 'src/app/models/business-account-model';

@Component({
  selector: 'app-company',
  templateUrl: './company.component.html',
  styleUrls: ['./company.component.scss']
})
export class CompanyComponent implements OnInit {
  @Input()
  businessAccount : BusinessAccountModel;

  @Output()
  onEditPressed = new EventEmitter();


  constructor() { 
   // Do nothing
}

  ngOnInit(): void {
  }

  edit(){
    this.onEditPressed.emit(null);
  }
}
