import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, map, of, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ActiveSubscriptionPlanOrderModel } from '../models/active-subscription-plan-order-model';
import { Page } from '../models/paging/page-model';
import { NoActiveSubscriptionException } from '../core/exceptions/no-active-subscription-exception';
import { HasUnpaidInvoiceException } from '../core/exceptions/has-unpaid-invoice-exception';
import { ForbiddenException } from '../core/exceptions/forbidden-exception';
import { CancellationSubscriptionPlanOrderException } from '../core/exceptions/cancellation-subscription-plan-order-exception';

@Injectable({
  providedIn: 'root'
})
export class SubscriptionPlanOrderService {

  constructor(private http: HttpClient) { }

  getActiveSubscriptionPlanOrder(companyId: string): Observable<Page<ActiveSubscriptionPlanOrderModel>> {
    let httpParams = new HttpParams();
    httpParams = httpParams.append("page", 0);
    httpParams = httpParams.append("size", 100);

    const httpOptions = {
      headers: new HttpHeaders({
        ContentType: 'application/json',
      }),
      params: httpParams
    };

    const url = environment.apiUrl + '/api/partner/companies/' + companyId + '/orders/subscriptionplans/active';
    const result = this.http.get<Page<ActiveSubscriptionPlanOrderModel>>(url, httpOptions);
    return result.pipe(catchError(this.handleError<Page<ActiveSubscriptionPlanOrderModel>>('getActiveSubscriptionPlanOrder', null)));
  }

  cancelSubscriptionPlanOrder(companyId: string, subscriptionPlanOrderId: string): Observable<boolean> {
    const httpOptions = {
      headers: new HttpHeaders({
        ContentType: 'application/json',
      }),
    };

    const url = environment.apiUrl + '/api/partner/companies/' + companyId + '/orders/subscriptionplans/' + subscriptionPlanOrderId;
    const result = this.http.delete(url, httpOptions);
    return result.pipe(
      map(_ => true),
      catchError((response: HttpErrorResponse) => {
        if (response.status == 403) {
          if (response.message.toLowerCase().includes("no active subscription plan order found")) {
            return throwError(() => new NoActiveSubscriptionException());
          }
          else if (response.message.toLowerCase().includes("has unpaid invoice")) {
            return throwError(() => new HasUnpaidInvoiceException());
          }
          else {
            return throwError(() => new ForbiddenException());
          }
        }
        else if (response.status == 409) {
          return throwError(() => new CancellationSubscriptionPlanOrderException());
        }
        return throwError(() => this.handleError('cancelSubscriptionPlanOrder', null));
      })

    );
  }


  /**
 * Handle Http operation that failed.
 * Let the app continue.
 * @param operation - name of the operation that failed
 * @param result - optional value to return as the observable result
 */
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      console.error(error); // log to console instead
      // Let the app keep running by returning an empty result.
      return of(result);
    };
  }
}
