import { Injectable } from "@angular/core";
import { ComponentStore } from "@ngrx/component-store";
import { Store } from "@ngrx/store";
import { Observable } from "rxjs";
import { catchError, map, switchMap, tap } from "rxjs/operators";
import { StoreLocationModel } from "src/app/models/store-location-model";
import { StoreLocationService } from "src/app/services/store-location.service";
import { StoreLocationDetailState } from "./store-location-detail.state";

const initialState: StoreLocationDetailState = {
    loading: false,
    loaded: false,
    success: false,
    errorMessage: null,
    storeLocation: null
};

@Injectable({
    providedIn: 'root'
})
export class StoreLocationDetailComponentStore extends ComponentStore<StoreLocationDetailState> {

    constructor(private store: Store<any>, private readonly storeLocationService: StoreLocationService) {
        super(initialState);
    }

    readonly storeLocation$: Observable<StoreLocationModel> = this.select(state => state.storeLocation);
    readonly loading$: Observable<boolean> = this.select(state => state.loading);
    readonly loaded$: Observable<boolean> = this.select(state => state.loaded);
    readonly success$: Observable<boolean> = this.select(state => state.success);
    readonly errorMessage$: Observable<any> = this.select(state => state.errorMessage);

    readonly viewModel$ = this.select(
        this.storeLocation$,
        this.loaded$,
        this.loading$,
        this.success$,
        this.errorMessage$,
        (storeLocation, loaded, loading, success, errorMessage) => ({
            storeLocation, loaded, loading, success, errorMessage
        })
    );

    readonly setInitial = this.updater(( _ : StoreLocationDetailState) => {
        return {
            ...initialState
        };
    });

    readonly setLoading = this.updater((state: StoreLocationDetailState) => {
        return {
            ...state,
            loading: true,
            loaded: false,
        };
    });

    readonly setLoaded = this.updater((state: StoreLocationDetailState) => {
        return {
            ...state,
            loading: false,
            loaded: true,
        };
    });

    readonly updateStoreLocationDetailState = this.updater((state: StoreLocationDetailState, value: {
        storeLocation: StoreLocationModel
    }) => {
        return {
            ...state,
            loading: false,
            loaded: true,
            success: true,
            storeLocation: value.storeLocation
        };
    });

    readonly setError = this.updater((state: StoreLocationDetailState, value: {
        errorMessage: String
    }) => {
        return {
            ...state,
            loading: false,
            loaded: true,
            success: false,
            errorMessage: value.errorMessage,
        };
    });

    readonly getStoreLocation = this.effect((storeLocationDetailParams$: Observable<{ companyId: string, storeLocationId: string }>) => {
        this.setLoading();
        return storeLocationDetailParams$.pipe(
            switchMap((params => this.storeLocationService.getStoreLocation(params.companyId, params.storeLocationId).pipe(
                map((storeLocation) => {
                    this.updateStoreLocationDetailState({ storeLocation: storeLocation });
                    this.setLoaded();
                }),
                catchError(async (error) => {
                    this.setError({ errorMessage: error });
                    this.setLoaded();
                    this.setInitial();
                })
            )
            ))
        );
    });
}