<div class="container">
  <mat-card appearance="outlined" class="mat-component-background mat-elevation-z2">
    <mat-card-header>
    <mat-card-title>
        <h1>What's next on the menu?</h1>
    </mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <div class="container-content">
            <mat-list>
              <mat-list-item [ngClass]="{
                'list-item-xsmall': isXSmall()
              }">
                <span matListItemIcon>
                  <mat-icon color="primary">eco</mat-icon>
                </span>
                <div matListItemTitle class="list-item-title">Sign in to the Planet VG Partner Portal.</div>
              </mat-list-item>
              <mat-list-item [ngClass]="{
                'list-item-xsmall': isXSmall()
              }">
                <span matListItemIcon>
                  <mat-icon color="primary">eco</mat-icon>
                </span>
                <div matListItemTitle class="list-item-title">Pick the right subscription plan for your business.</div>
              </mat-list-item>
              <mat-list-item [ngClass]="{
                'list-item-xsmall': isXSmall()
              }">
                <span matListItemIcon>
                  <mat-icon color="primary">eco</mat-icon>
                </span>
                <div matListItemTitle class="list-item-title">Use the emailed openings promo-code during checkout. </div>
              </mat-list-item>
              <mat-list-item [ngClass]="{
                'list-item-xsmall': isXSmall()
              }">
                <span matListItemIcon>
                  <mat-icon color="primary">eco</mat-icon>
                </span>
                <div matListItemTitle class="list-item-title">Receive an amuse-bouche, a <s>free 90-day trial</s> <span class="primary-title"> free 1 year</span> subscription. </div>
              </mat-list-item>
            </mat-list>
        </div>
    </mat-card-content>
    <mat-card-actions class="container-buttons">
        <button mat-raised-button (click)="signIn()">
            <mat-icon>login</mat-icon
            >Sign In
          </button>
    </mat-card-actions>
  </mat-card>
  
</div>
