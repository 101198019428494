<div [ngClass]="{
  'container-xlarge': isXLarge(),
  'container-large': isLarge(),
  'container-medium': isMedium(),
  'container-small': isSmall(),
  'container-xsmall': isXSmall()
}">
  <h1>Oh no, your payment failed.</h1>
  <img src="/assets/img/planetvg_icon.png" alt="planetvg-logo" />

  <mat-card appearance="outlined" class="mat-component-background mat-elevation-z2">
    <mat-card-header>
      <mat-card-title>
        <h1>Don't worry</h1>
      </mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <div class="container-content">
        <mat-list>
          <mat-list-item [ngClass]="{
                  'list-item-xsmall': isXSmall()
                }">
            <span matListItemIcon>
              <mat-icon color="primary">eco</mat-icon>
            </span>
            <div matListItemTitle class="list-item-title">You can always try again later.</div>
          </mat-list-item>
        </mat-list>
      </div>
      <div>
        <p>You will be automatically redirected to the pricing page in {{ timeLeft }} seconds.</p>
      </div>
    </mat-card-content>
    <mat-card-actions class="container-buttons">
        <button mat-raised-button color="primary" (click)="navigateManually()" cdkFocusInitial>
          <mat-icon>shopping_basket</mat-icon>
          Pricing
        </button>
    </mat-card-actions>
  </mat-card>
</div>
<app-bottom-menu></app-bottom-menu>