import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { of } from "rxjs";
import { catchError, map, mergeMap } from "rxjs/operators";
import { BusinessAccountService } from "src/app/services/business-account.service";
import { businessAccountGetAction, businessAccountGetActionFailure, businessAccountGetActionSuccess, subscriptionPlanChangedAction, unpaidInvoiceGetAction, unpaidInvoiceGetActionSuccess } from "./app.actions";
import { SubscriptionPlanOrderService } from "src/app/services/subscription-plan-order.service";
import { AuthorizationGuardService } from "src/app/services/authorization-guard.service";
import { InvoiceService } from "src/app/services/invoice.service";

@Injectable()
export class BusinessAccountEffects {
    constructor(
        private actions$: Actions,
        private businessAccountService: BusinessAccountService,
        private subscriptionPlanOrderService: SubscriptionPlanOrderService,
        private invoiceService : InvoiceService,
        private authorizationService: AuthorizationGuardService
    ) { }

    businessAccountGetEffect$ = createEffect(() =>
        this.actions$.pipe(
            ofType(businessAccountGetAction),
            mergeMap(() => {
                return this.businessAccountService.getBusinessAccount().pipe(
                    map(data => businessAccountGetActionSuccess({
                        businessAccount: data,
                        businessAccountId: data.id,
                        companyId: data.company.id,
                        hasBusinessAccount: true
                    })
                    )
                )
            }),
            catchError(error => {
                var errorMessage = this.errorMessage(error);
                return of(businessAccountGetActionFailure({ error: errorMessage }))
            })
        )
    );

    activeSubscriptionPlanOrderGetEffect$ = createEffect(() =>
        this.actions$.pipe(
            ofType(businessAccountGetActionSuccess),
            mergeMap((actionData) => {
                
                return this.subscriptionPlanOrderService.getActiveSubscriptionPlanOrder(actionData.companyId).pipe(
                    map(data => {
                        let hasSubscriptionPlanOrder = false;
                        let subscriptionPlanOrderId = null;
                        if(!data.empty){
                            // Grab the first subscription plan order
                            hasSubscriptionPlanOrder = true;
                            subscriptionPlanOrderId = data.content[0].id
                        }

                        this.authorizationService.subscriptionPlanOrderId = subscriptionPlanOrderId;
                        
                        return subscriptionPlanChangedAction({
                            hasSubscriptionPlanOrder: hasSubscriptionPlanOrder,
                            subscriptionPlanOrderId: subscriptionPlanOrderId
                        })
                    })
                )
            })
        )
    );

    unpaidInvoiceGetEffect$ = createEffect(() =>
        this.actions$.pipe(
            ofType(unpaidInvoiceGetAction),
            mergeMap((actionData) => {
                
                return this.invoiceService.getUnpaidInvoices(actionData.companyId).pipe(
                    map(data => {
                        let hasUnpaidInvoice = false;
                        let unpaidInvoice = null;
                        if(!data.empty){
                            // Grab the first unpaid invoice
                            hasUnpaidInvoice = true;
                            unpaidInvoice = data.content[0]
                        }

                        return unpaidInvoiceGetActionSuccess({
                            hasUnpaidInvoice: hasUnpaidInvoice,
                            unpaidInvoice: unpaidInvoice
                        })
                    })
                )
            })
        )
    );

    private errorMessage(error: any): String {
        if (error.status == 404) {
            return error.error;
        }
        return error.error;
    }
}
