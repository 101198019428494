<div class="container">
  <div *ngIf="readMode; else editMode">
    <div class="container-store-location">
      <app-store-location [storeLocation]="(viewModel$ | async)?.storeLocation" (onEditPressed)="onEdit($event)"
        (onDeletePressed)="onDelete($event)" (onActivatePressed)="onActivate($event)"
        (onDeactivatePressed)="onDeactivate($event)"></app-store-location>
    </div>
  </div>
    <div class="container-store-user" *ngIf="showStoreUser">
      <app-store-user-details (onGenerateNewPassword)="onGenerateNewPassword($event)"
        [storeUser]="(storeUserViewModel$ | async)?.storeUser"></app-store-user-details>
    </div>
</div>

<ng-template #editMode>
  <div class="container-store-location">
    <app-store-location-edit [minNameLength]="minNameLength" [maxNameLength]="maxNameLength"
      [minDescriptionLength]="minDescriptionLength" [maxDescriptionLength]="maxDescriptionLength"
      [maxAddressLineLength]="maxAddressLineLength" [minPostalCodeLength]="minPostalCodeLength"
      [maxPostalCodeLength]="maxPostalCodeLength" [minCityLength]="minCityLength" [maxCityLength]="maxCityLength"
      [minPhoneNumberLength]="minPhoneNumberLength" [maxPhoneNumberLength]="maxPhoneNumberLength"
      [storeLocation]="(viewModel$ | async)?.storeLocation"
      [storeLocationDetailsFormGroup]="storeLocationDetailsFormGroup" (onCancelPressed)="onCancel($event)"
      (onSavePressed)="onSave($event)"></app-store-location-edit>
  </div>
</ng-template>