import { Injectable } from "@angular/core";
import { ComponentStore } from "@ngrx/component-store";
import { Observable } from "rxjs";
import { catchError, switchMap, tap } from "rxjs/operators";
import { StoreLocationModel } from "src/app/models/store-location-model";
import { StoreLocationService } from "src/app/services/store-location.service";
import { StoreLocationsState } from "./store-locations.state";

const initialState: StoreLocationsState = {
    loading: false,
    loaded: false,
    success: false,
    errorMessage: null,
    storeLocations: [],
    currentPage: 0,
    totalElements: 0,
};

@Injectable({
    providedIn: 'root'
})
export class StoreLocationsComponentStore extends ComponentStore<StoreLocationsState> {

    constructor(private readonly storeLocationService: StoreLocationService) {
        super(initialState);
    }

    readonly storeLocations$: Observable<StoreLocationModel[]> = this.select(state => state.storeLocations);
    readonly currentPage$: Observable<number> = this.select(state => state.currentPage);
    readonly totalElements$: Observable<number> = this.select(state => state.totalElements);
    readonly loading$: Observable<boolean> = this.select(state => state.loading);
    readonly loaded$: Observable<boolean> = this.select(state => state.loaded);
    readonly success$: Observable<boolean> = this.select(state => state.success);
    readonly errorMessage$: Observable<any> = this.select(state => state.errorMessage);

    readonly viewModel$ = this.select(
        this.storeLocations$,
        this.currentPage$,
        this.totalElements$,
        this.loaded$,
        this.loading$,
        this.success$,
        this.errorMessage$,
        (storeLocations, currentPage, totalElements, loaded, loading, success, errorMessage) => ({
            storeLocations, currentPage, totalElements, loaded, loading, success, errorMessage
        })
    );

    readonly setInitial = this.updater(( _ : StoreLocationsState) => {
        return {
            ...initialState
        };
    });

    readonly setLoading = this.updater((state: StoreLocationsState) => {
        return {
            ...state,
            loading: true,
            loaded:false,
        };
    });

    readonly setLoaded = this.updater((state: StoreLocationsState) => {
        return {
            ...state,
            loading: false,
            loaded: true,
        };
    });

    readonly updateStoreLocations = this.updater((state: StoreLocationsState, value: {
        content: StoreLocationModel[],
        currentPage: number,
        totalElements: number
    }) => {
        return {
            ...state,
            loading: false,
            loaded: true,
            success: true,
            storeLocations: value.content, // TODO Find out why the ... operator doesn't work here [...state.storeLocations, value.content]
            currentPage: value.currentPage,
            totalElements: value.totalElements
        };
    });

    readonly setError = this.updater((state: StoreLocationsState, value: {
        errorMessage: String
    }) => {
        return {
            ...state,
            loading: false,
            loaded: true,
            success: false,
            errorMessage: value.errorMessage,
        };
    });

    readonly getStoreLocations = this.effect((storeLocations$: Observable<{ companyId: string, currentPage: number, pageSize: number }>) => {
        this.setLoading();
        return storeLocations$.pipe(
            switchMap((params => this.storeLocationService.getStoreLocations(params.companyId, params.currentPage, params.pageSize).pipe(
                tap({
                    next: (page) => {
                        this.updateStoreLocations({ content: page.content, currentPage: page.number, totalElements: page.totalElements })
                        this.setLoaded()
                    },
                    error: (e) => { }
                }),
                catchError(async (error) => {
                    this.setError({ errorMessage: error })
                    this.setLoaded()
                })
            ))
            )
        );
    });


}