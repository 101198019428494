import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { BusinessAccountModel } from 'src/app/models/business-account-model';
import { SpinnerService } from 'src/app/services/spinner.service';
import { BillingPortalComponentStore } from 'src/app/stores/components/billing-portal/billing-portal.component-store';
import { selectBusinessAccount } from 'src/app/stores/global/app.selectors';
import { SpinnerDialogComponent } from '../dialogs/spinner-dialog/spinner-dialog.component';

@Component({
  selector: 'app-billing-portal',
  templateUrl: './billing-portal.component.html',
  styleUrls: ['./billing-portal.component.scss']
})
export class BillingPortalComponent implements OnInit {
  businessAccount$: Observable<BusinessAccountModel> = this.store.select(selectBusinessAccount);
  ngUnsubscribeState = new Subject<void>();
  private loadingSpinnerDialogRef : MatDialogRef<SpinnerDialogComponent>;
  
  viewModel$ = this.billingPortalComponentStore.viewModel$;

  constructor(
    private store: Store<any>,
    private billingPortalComponentStore: BillingPortalComponentStore,
    private snackBar: MatSnackBar,
    private spinnerService: SpinnerService
  ) { }

  ngOnInit(): void {
    this.businessAccount$.pipe(takeUntil(this.ngUnsubscribeState)).subscribe(businessAccount => {
      this.showSpinner();
      this.billingPortalComponentStore.getBillingPortalSession({ companyId: businessAccount.company.id });

      this.billingPortalComponentStore.viewModel$
        .pipe(
          takeUntil(this.ngUnsubscribeState))
        .subscribe((result) => {
          if (result.success) {
            window.location.href = result.billingPortalSession.portalSessionUrl;
          }
          else if (result.loaded && result.success) {
            this.snackBar.open('Error creating session. Please try again.', 'Dismiss');
          }
        });
    });

    this.billingPortalComponentStore.loaded$.pipe(takeUntil(this.ngUnsubscribeState)).subscribe((loaded) => {
      this.hideSpinner(loaded);
    });
  }

  private showSpinner(){
    this.loadingSpinnerDialogRef = this.spinnerService.show();
  }

  private hideSpinner(loaded : boolean){
    if(loaded && this.loadingSpinnerDialogRef !== null){
      this.spinnerService.hide(this.loadingSpinnerDialogRef);
      this.loadingSpinnerDialogRef = null;
    }
  }

}
