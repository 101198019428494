import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    Router,
    RouterStateSnapshot,
} from '@angular/router';
import { AutoLoginPartialRoutesGuard } from 'angular-auth-oidc-client';
import { Observable, map, of } from 'rxjs';
import { AuthorizationGuardService } from 'src/app/services/authorization-guard.service';

@Injectable({ providedIn: 'root' })
export class PortalRoutesGuard {

    portalAllowedRoutes: string[] = [
        "dashboard",
        "account",
        "billing",
    ];


    constructor(
        private readonly autoLoginPartialRoutesGuard: AutoLoginPartialRoutesGuard,
        private readonly authorizationService: AuthorizationGuardService,
        private readonly router: Router,
    ) { }

    canLoad(): boolean | Observable<boolean> {
        return this.autoLoginPartialRoutesGuard.canLoad();
    }

    canActivate(route: ActivatedRouteSnapshot, _state: RouterStateSnapshot): Observable<boolean | Observable<boolean>> {
        return this.authorizationService.isAuthenticated$.pipe(
            map(isAuthenticated => {
                if (isAuthenticated
                    && this.authorizationService.hasBusinessAccount) {
                    return true;
                }
                this.router.navigate(['/home']);
                return of(false);
            }),
        );
    }

    canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> {
        return this.autoLoginPartialRoutesGuard.canActivateChild(route, state)
    }

    _portalAllowedRoutes(targetRoute: string): boolean {
        if (targetRoute) {
            return this.portalAllowedRoutes.includes(targetRoute.toLowerCase());
        }
        return false;
    }
}