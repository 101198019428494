<div class="container">
  <div *ngIf="unpaidInvoice$ | async">
    <app-unpaid-invoice-banner
      [unpaidInvoice]="unpaidInvoice$ | async"
    ></app-unpaid-invoice-banner>
  </div>

  <div *ngIf="showAdd; else showShop">
    <div class="container-add">
      <button mat-raised-button (click)="create()" color="primary">
        <mat-icon>post_add</mat-icon>
        New
      </button>
    </div>
    <div class="container-grid">
      <table
        description="Overview of stamp cards"
        mat-table
        [dataSource]="(viewModel$ | async)?.campaigns"
        class="mat-component-background mat-elevation-z2"
      >
        <!--- Note that these columns can be defined in any order.
            The actual rendered columns are set as a property on the row definition" -->

        <!-- Id Column -->
        <ng-container matColumnDef="id">
          <th mat-header-cell *matHeaderCellDef>Id</th>
          <td mat-cell *matCellDef="let element">{{ element.id }}</td>
        </ng-container>

        <!-- Name Column -->
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef>Name</th>
          <td mat-cell *matCellDef="let element">{{ element.name }}</td>
        </ng-container>

        <!-- Start Date Column -->
        <ng-container matColumnDef="startDate">
          <th mat-header-cell *matHeaderCellDef>Start Date</th>
          <td mat-cell *matCellDef="let element">
            {{ element.startDate | date : "EEEE, MMMM d, y, HH:mm" }}
          </td>
        </ng-container>

        <!-- End Date Column -->
        <ng-container matColumnDef="endDate">
          <th mat-header-cell *matHeaderCellDef>End Date</th>
          <td mat-cell *matCellDef="let element">
            {{ element.endDate | date : "EEEE, MMMM d, y, HH:mm" }}
          </td>
        </ng-container>

        <!-- Recurrence Pattern Column -->
        <ng-container matColumnDef="recurrencePattern">
          <th mat-header-cell *matHeaderCellDef>Recurrence</th>
          <td mat-cell *matCellDef="let element">
            {{ getRecurrenceDisplayString(element.recurrencePattern) }}
          </td>
        </ng-container>

        <!-- Stamps Objective Column -->
        <ng-container matColumnDef="stampsObjective">
          <th mat-header-cell *matHeaderCellDef>Stamps Objective</th>
          <td mat-cell *matCellDef="let element">
            {{ element.stampsObjective }}
          </td>
        </ng-container>

        <!-- Campaign Status Column -->
        <ng-container matColumnDef="campaignStatus">
          <th mat-header-cell *matHeaderCellDef>Status</th>
          <td mat-cell *matCellDef="let element">
            {{ element.campaignStatus }}
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr
          mat-row
          *matRowDef="let row; columns: displayedColumns"
          [routerLink]="['/companies', row.company.id, 'stamp-cards', row.id]"
        ></tr>
      </table>
      <div class="container-paginator">
        <mat-paginator
          [length]="(viewModel$ | async)?.totalElements"
          [pageSize]="pageSize"
          [pageSizeOptions]="pageSizeOptions"
          (page)="onPageEvent($event)"
          class="mat-component-background mat-elevation-z2"
        >
        </mat-paginator>
      </div>
    </div>
  </div>
</div>
<ng-template #showShop>
  <app-buy-subscription-plan-banner></app-buy-subscription-plan-banner>
</ng-template>
