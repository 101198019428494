<div
[ngClass]="{
  'container-large': isLarge(),
  'container-medium': isMedium(),
  'container-small': isSmall(),
  'container-xsmall': isXSmall()
}"
  >
    <div
      *ngIf="
        isLoading;
        else showActiveCampaignSummaryCharts
      ">
      <div class="spinner-container">
        <mat-spinner diameter="80"></mat-spinner>
      </div>
    </div>
  </div>

  <ng-template #showActiveCampaignSummaryCharts>
    <div
       *ngFor="let companyCampaignSummary of companyCampaignSummaries$ | async; index as i"
    >
      <app-stats-active-campaign-chart [companyCampaignSummary]="companyCampaignSummary"></app-stats-active-campaign-chart>
    </div>
  </ng-template>