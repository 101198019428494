import { Component, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { RRule } from 'rrule';
import { Subject, takeUntil } from 'rxjs';
import { ArrayUtils } from 'src/app/core/utilities/array-utils';

interface RecurrenceMonthlyWhichPositionRule {
  value: string;
  viewValue: string;
}

interface RecurrenceMonthyOnWhichDayRule {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'app-monthly-repeat-rule',
  templateUrl: './monthly-repeat-rule.component.html',
  styleUrls: ['./monthly-repeat-rule.component.scss']
})
export class MonthlyRepeatRuleComponent {
  @Input()
  monthlyRepeatRuleFormGroup: UntypedFormGroup;

  @Input()
  rrule: RRule;

  ngUnsubscribeState = new Subject<void>();
  selectedChoice: string;
  selectedOnDayValue : string;

  recurrenceMonthlyOnWhichPositions: RecurrenceMonthlyWhichPositionRule[] = [
    { value: "first", viewValue: "First" },
    { value: "second", viewValue: "Second" },
    { value: "third", viewValue: "Third" },
    { value: "fourth", viewValue: "Fourth" },
    { value: "last", viewValue: "Last" },
  ];

  recurrenceMonthlyOnWhichDays: RecurrenceMonthyOnWhichDayRule[] = [
    { value: "monday", viewValue: "Monday" },
    { value: "tuesday", viewValue: "Tuesday" },
    { value: "wednesday", viewValue: "Wednesday" },
    { value: "thursday", viewValue: "Thursday" },
    { value: "friday", viewValue: "Friday" },
    { value: "saturday", viewValue: "Saturday" },
    { value: "sunday", viewValue: "Sunday" },
    { value: "weekday", viewValue: "Weekday" },
    { value: "weekendday", viewValue: "Weekend day" },

  ];

  public ngOnInit(): void {
    this.monthlyRepeatRuleFormGroup.controls.onMonthlyChoice.valueChanges.pipe(takeUntil(this.ngUnsubscribeState)).subscribe(value => {
      if (value === 'on-day') {
        this.setStateOnDayControls(true);
        this.setStateOnWhichDayControls(false);
      }
      else if (value === 'on-which-day') {
        this.setStateOnDayControls(false);
        this.setStateOnWhichDayControls(true);
      }
    });

    if (this.rrule) {
      if (this.rrule.options.bymonthday && this.rrule.options.bymonthday.length > 0) {
        this.monthlyRepeatRuleFormGroup.patchValue({ 'onMonthlyChoice': 'on-day' });
        // We only support a single number day of the month.
        this.selectedOnDayValue = this.rrule.options.bymonthday[0].toString();
        this.monthlyRepeatRuleFormGroup.patchValue({ 'recurrenceRepeatRuleMonthlyOnDay': this.selectedOnDayValue });
      }
      else if(this.rrule.options.bysetpos 
        && this.rrule.options.bysetpos.length > 0
        && this.rrule.options.bynweekday
        && this.rrule.options.bynweekday.length > 0
      ) {
        this.monthlyRepeatRuleFormGroup.patchValue({ 'onMonthlyChoice': 'on-which-day' });
        this.monthlyRepeatRuleFormGroup.patchValue({ 'recurrenceRepeatRuleMonthlyOnWhichDayPosition': this.getOnWhichDayPosition() });
        this.monthlyRepeatRuleFormGroup.patchValue({ 'recurrenceRepeatRuleMonthlyOnWhichDayDay': this.getOnWhichDay() });
      }
    }
  }

  public ngOnDestroy(): void {
    this.ngUnsubscribeState.next();
    this.ngUnsubscribeState.complete();
  }

  public dayRange = () => ArrayUtils.range(1, 32);

  private setStateOnDayControls(enable: boolean) {
    if (enable) {
      this.monthlyRepeatRuleFormGroup.controls.recurrenceRepeatRuleMonthlyOnDay.enable();
    }
    else {
      this.monthlyRepeatRuleFormGroup.controls.recurrenceRepeatRuleMonthlyOnDay.disable();
    }
  }

  private setStateOnWhichDayControls(enable: boolean) {
    if (enable) {
      this.monthlyRepeatRuleFormGroup.controls.recurrenceRepeatRuleMonthlyOnWhichDayPosition.enable();
      this.monthlyRepeatRuleFormGroup.controls.recurrenceRepeatRuleMonthlyOnWhichDayDay.enable();

    }
    else {
      this.monthlyRepeatRuleFormGroup.controls.recurrenceRepeatRuleMonthlyOnWhichDayPosition.disable();
      this.monthlyRepeatRuleFormGroup.controls.recurrenceRepeatRuleMonthlyOnWhichDayDay.disable();
    }
  }

  private getOnWhichDayPosition() : string {
    // We only support a single position.
    return this.recurrenceMonthlyOnWhichPositions[this.rrule.options.bysetpos[0] - 1].value;
  }

  private getOnWhichDay() : string {
    // We only support a single position.
    return this.recurrenceMonthlyOnWhichDays[this.rrule.options.bynweekday[0][0]].value;
  }
}
