import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { filter } from 'rxjs';
import { selectBusinessAccount } from 'src/app/stores/global/app.selectors';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {
  onBoarded: boolean = false;

  constructor(private readonly store: Store) {
    // Do nothing
  }

  ngOnInit(): void {
    // Redirect if not onboarded.
    this.store.select(selectBusinessAccount).pipe(filter(result => Boolean(result))).subscribe(businessAccount => {
      const companyDetailsOnBoarded: boolean = Boolean(businessAccount.company.name) && businessAccount.company.name !== '';
      const companyLogoOnBoarded: boolean = Boolean(businessAccount.company.logo);
      this.onBoarded = companyDetailsOnBoarded && companyLogoOnBoarded;
    });
  }
}
