<div class="container" [formGroup]="endDateRuleFormGroup">
  <span>Ends</span>
  <mat-form-field appearance="outline">
    <mat-select [(value)]="selectedValue" name="endRecurrenceRule" formControlName="recurrenceEndRule" panelClass="mat-app-background">
      <mat-option
        *ngFor="let endRecurrenceRule of endRecurrenceRules"
        [value]="endRecurrenceRule.value"
      >
        {{ endRecurrenceRule.viewValue }}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <div *ngIf="selectedValue === 'after'">
    <mat-form-field appearance="outline">
        <mat-label>Occurrences</mat-label>
      <input type="number" matInput formControlName="recurrenceEndRuleOccurrences"/>
    </mat-form-field>
  </div>
  <div *ngIf="selectedValue === 'on-date'">
    <mat-form-field appearance="outline">
      <mat-label>End Date</mat-label>
      <input matInput [matDatepicker]="recurrenceOnEndDatePicker" formControlName="recurrenceEndRuleEndDate" class="ng-trim-ignore"/>
      <mat-datepicker-toggle
        matIconSuffix
        [for]="recurrenceOnEndDatePicker"
      ></mat-datepicker-toggle>
      <mat-datepicker #recurrenceOnEndDatePicker></mat-datepicker>
    </mat-form-field>
  </div>
</div>
