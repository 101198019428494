import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { StoreUserModel } from 'src/app/models/store-user-model';

@Component({
  selector: 'app-store-user-details',
  templateUrl: './store-user-details.component.html',
  styleUrls: ['./store-user-details.component.scss']
})
export class StoreUserDetailsComponent implements OnInit {
  @Input()
  storeUser: StoreUserModel;

  @Output()
  onGenerateNewPassword = new EventEmitter();

  constructor() {
    // Do nothing
  }

  ngOnInit(): void {
    // Do nothing
  }

  generateNewPassword(): void {
    this.onGenerateNewPassword.emit(null);
  }
}
