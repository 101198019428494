import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { SpinnerDialogComponent } from '../core/components/dialogs/spinner-dialog/spinner-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class SpinnerService {

  constructor(private dialog: MatDialog) {
    // Do nothing 
  }

  show(): MatDialogRef<SpinnerDialogComponent> {
    return this.dialog.open(SpinnerDialogComponent, {
      disableClose: true,
    });
  }

  hide(ref: MatDialogRef<SpinnerDialogComponent>) {
    ref?.close();
  }
}
