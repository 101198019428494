<div [ngClass]="{
    'container-large': isLarge(),
    'container-medium': isMedium(),
    'container-small': isSmall(),
    'container-xsmall': isXSmall()
  }"
>
    <div *ngIf="!isLoading; else showLoading">
        <mat-card class="mat-component-background mat-elevation-z2">
            <mat-card-header>
                <mat-card-title-group>
                    <mat-card-title>
                        Total Members
                    </mat-card-title>
                    <mat-card-subtitle>{{(businessAccount$ | async).company.name }}</mat-card-subtitle>
                </mat-card-title-group>
            </mat-card-header>
            <mat-card-content class="container-card-content">
                <div echarts [options]="options" [ngClass]="{
                    'chart-large': isLarge(),
                    'chart-medium': isMedium(),
                    'chart-small': isSmall(),
                    'chart-xsmall': isXSmall()
                  }"></div>
            </mat-card-content>
        </mat-card>
    </div>
</div>

<ng-template #showLoading>
    <div class="spinner-container" >
      <mat-spinner diameter="80"></mat-spinner>
    </div>
</ng-template>


    

