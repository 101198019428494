export class PlanetVGJwtClaimNames {
    public static readonly BusinessAccountId = "business_account_id";
    public static readonly CompanyId = "company_id";
    public static readonly SubscriptionPlanOrderId = "subscription_plan_order_id";
    public static readonly Email = "email";
    public static readonly FamilyName = "family_name";
    public static readonly GivenName = "given_name";
    public static readonly Name = "name";
}

export class JwtClaimNames {
    public static readonly Sub = "sub";
    public static readonly PreferredUsername = "preferred_username";
    public static readonly Sid = "sid";
}