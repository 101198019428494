import { Injectable } from "@angular/core";
import { ComponentStore } from "@ngrx/component-store";
import { catchError, Observable, switchMap, tap } from "rxjs";
import { StatsTotalMembersChartState } from "./stats-total-members-chart.state";
import { CompanySummariesService } from "src/app/services/company-summaries.service";
import { CompanyMemberSummaryModel } from "src/app/models/company-member-summary-model";

const initialState: StatsTotalMembersChartState = {
    loading: false,
    loaded: false,
    success: false,
    errorMessage: null,
    companyMemberSummary: null
}

@Injectable({
    providedIn: 'root'
})
export class StatsTotalMembersChartComponentStore extends ComponentStore<StatsTotalMembersChartState> {
    constructor(private readonly companySummariesServices: CompanySummariesService) {
        super(initialState);
    }

    readonly companyMemberSummary$: Observable<CompanyMemberSummaryModel> = this.select(state => state.companyMemberSummary);
    readonly loading$: Observable<boolean> = this.select(state => state.loading);
    readonly loaded$: Observable<boolean> = this.select(state => state.loaded);
    readonly success$: Observable<boolean> = this.select(state => state.success);
    readonly errorMessage$: Observable<any> = this.select(state => state.errorMessage);

    readonly viewModel$ = this.select(
        this.companyMemberSummary$,
        this.loaded$,
        this.loading$,
        this.success$,
        this.errorMessage$,
        (companyMemberSummary, loaded, loading, success, errorMessage) => ({
            companyMemberSummary, loaded, loading, success, errorMessage
        })
    );

    readonly setInitial = this.updater((_: StatsTotalMembersChartState) => {
        return {
            ...initialState
        };
    });

    readonly setLoading = this.updater((state: StatsTotalMembersChartState) => {
        return {
            ...state,
            loading: true,
            loaded: false,
        };
    });

    readonly setLoaded = this.updater((state: StatsTotalMembersChartState) => {
        return {
            ...state,
            loading: false,
            loaded: true,
        };
    });

    readonly updateStatsTotalMembersChartState = this.updater((state: StatsTotalMembersChartState, value: {
        companyMemberSummary: CompanyMemberSummaryModel
    }) => {
        return {
            ...state,
            loading: false,
            loaded: true,
            success: true,
            companyMemberSummary : value.companyMemberSummary
        };
    });

    readonly setError = this.updater((state: StatsTotalMembersChartState, value: {
        errorMessage: string
    }) => {
        return {
            ...state,
            loading: false,
            loaded: true,
            success: false,
            errorMessage: value.errorMessage,
        };
    });

    readonly getCompanyMemberSummary = this.effect((params$: Observable<{ companyId: string }>) => {
        this.setLoading();
        return params$.pipe(
            switchMap((params => this.companySummariesServices.getCompanyMemberSummary(params.companyId).pipe(
                tap({
                    next: (companyMemberSummary) => {
                        this.updateStatsTotalMembersChartState({ companyMemberSummary: companyMemberSummary });
                        this.setLoaded();
                    },
                    error: (e) => {
                        throw e;
                    }
                }),
                catchError(async (error) => {
                    this.setError({ errorMessage: error })
                    this.setLoaded();
                    this.setInitial();
                })
            ))
            )
        );
    });
}