<div class="container">
  <mat-card
    appearance="outlined"
    class="mat-component-background mat-elevation-z2"
  >
    <mat-card-header>
      <mat-card-title>Account Details</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <div class="container-column">
        <mat-form-field appearance="outline">
          <mat-label>Account Id</mat-label>
          <input
            matInput
            readonly
            value="{{ (businessAccount$ | async)?.id }}"
          />
        </mat-form-field>
      </div>
    </mat-card-content>
  </mat-card>
</div>

<div class="container">
  <mat-card class="mat-component-background mat-elevation-z2">
    <mat-card-header>
      <mat-card-title>Account Deletion</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <div class="container-column">
        <p>This will <strong>only</strong> delete your business account. If you
        want to delete your Planet VG account. Go to your profile page.</p>
      </div>
    </mat-card-content>
    <mat-card-actions>
      <div class="container-actions">
        <button mat-button (click)="onDelete()">
          <mat-icon>delete_outline</mat-icon>
          Delete Business Account
        </button>
      </div>
    </mat-card-actions>
  </mat-card>
</div>
