<div [ngClass]="{
  'container-large': isLarge(),
  'container-medium': isMedium(),
  'container-small': isSmall(),
  'container-xsmall': isXSmall()
}">
  <div *ngIf="showPricingBanner" [ngClass]="{
    'container-banner-large': isLarge(),
    'container-banner-medium': isMedium(),
    'container-banner-small': isSmall(),
    'container-banner-xsmall': isXSmall()
  }">
    <app-buy-subscription-plan-banner></app-buy-subscription-plan-banner>
  </div>
  <div *ngIf="unpaidInvoice$ | async" [ngClass]="{
    'container-banner-large': isLarge(),
    'container-banner-medium': isMedium(),
    'container-banner-small': isSmall(),
    'container-banner-xsmall': isXSmall()
  }">
    <app-unpaid-invoice-banner
      [unpaidInvoice]="unpaidInvoice$ | async"
    ></app-unpaid-invoice-banner>
  </div>
  <div [ngClass]="{
    'container-banner-large': isLarge(),
    'container-banner-medium': isMedium(),
    'container-banner-small': isSmall(),
    'container-banner-xsmall': isXSmall()
  }">
    <app-dashboard-banner></app-dashboard-banner>
  </div>
</div>
<div [ngClass]="{
  'container-charts-large': isLarge(),
  'container-charts-medium': isMedium(),
  'container-charts-small': isSmall(),
  'container-charts-xsmall': isXSmall()
}">
  <app-stats-total-members-chart></app-stats-total-members-chart>
  <app-stats-active-promotion-charts></app-stats-active-promotion-charts>
  <app-stats-active-campaign-charts></app-stats-active-campaign-charts>
</div>