import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { SubscriptionPlanModel } from '../models/subscription-plan-model';

@Injectable({
  providedIn: 'root'
})
export class SubscriptionPlanService {

  constructor(private http: HttpClient) {

  }

  getSubscriptionPlans(): Observable<SubscriptionPlanModel[]> {
    const httpOptions = {
      headers: new HttpHeaders({
        ContentType: 'application/json',
      })
    };

    const url = environment.apiUrl + '/api/partner/subscriptionplans';
    return this.http.get<SubscriptionPlanModel[]>(url, httpOptions);
  }
}
