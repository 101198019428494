<div class="container" [formGroup]="billingDetailsFormGroup">
  <mat-card class="mat-component-background mat-elevation-z2">
    <mat-card-header>
      <mat-card-title> Editing Billing Details </mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <div class="container-content">
        <div class="container-column">
          <mat-form-field appearance="outline">
            <mat-label>Billing Id</mat-label>
            <input matInput readonly value="{{ billingDetails?.externalId }}" />
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>VAT Number</mat-label>
            <input
              matInput
              placeholder="Ex. 123456789"
              formControlName="vatNumber"
            />
            <mat-error
              *ngIf="
                billingDetailsFormGroup.get('vatNumber').hasError('required') &&
                billingDetailsFormGroup.get('vatNumber').touched
              "
            >
              <span>VAT number is required</span>
            </mat-error>
            <mat-error
              *ngIf="
                billingDetailsFormGroup
                  .get('vatNumber')
                  .hasError('notValidVATForCountry') &&
                billingDetailsFormGroup.get('vatNumber').touched
              "
            >
              <span>Not a valid EU VAT number</span>
            </mat-error>
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>Phone Number</mat-label>
            <ngx-mat-intl-tel-input
              [preferredCountries]="['nl', 'be', 'lu']"
              [enablePlaceholder]="true"
              [enableSearch]="true"
              inputPlaceholder="Ex. 020 1234567"
              name="phone"
              describedBy="phoneInput"
              formControlName="phoneNumber"
            >
            </ngx-mat-intl-tel-input>
            <mat-error
              *ngIf="
                billingDetailsFormGroup
                  .get('phoneNumber')
                  .hasError('required') &&
                billingDetailsFormGroup.get('phoneNumber').touched
              "><span>Phone number is required</span>
            </mat-error>
            <mat-error *ngIf="
                billingDetailsFormGroup
                .get('phoneNumber')
                .hasError('validatePhoneNumber') &&
            billingDetailsFormGroup.get('phoneNumber').touched
          "><span>Invalid phone number</span></mat-error>
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>Billing Email</mat-label>
            <input matInput readonly value="{{ billingDetails?.email }}" />
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>Address Line 1</mat-label>
            <input
              matInput
              placeholder="Ex. Broadway 12345"
              formControlName="addressLine1"
            />
            <mat-error
              *ngIf="
                billingDetailsFormGroup
                  .get('addressLine1')
                  .hasError('required') &&
                billingDetailsFormGroup.get('addressLine1').touched
              "
            >
              <span>Address Line 1 is required</span>
            </mat-error>
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>Address Line 2</mat-label>
            <input
              matInput
              placeholder="Ex. office no."
              formControlName="addressLine2"
            />
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>Postal Code</mat-label>
            <input
              matInput
              placeholder="Ex. 1234AB"
              formControlName="postalCode"
            />
            <mat-error
              *ngIf="
                billingDetailsFormGroup
                  .get('postalCode')
                  .hasError('required') &&
                billingDetailsFormGroup.get('postalCode').touched
              "
            >
              <span>Postal code is required</span>
            </mat-error>
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>City</mat-label>
            <input
              matInput
              placeholder="Ex. New York City"
              formControlName="city"
            />
            <mat-error
              *ngIf="
                billingDetailsFormGroup.get('city').hasError('required') &&
                billingDetailsFormGroup.get('city').touched
              "
            >
              <span>City is required</span>
            </mat-error>
          </mat-form-field>

          <app-country-selector
            label="Country"
            selectedCountry="{{ billingDetailsFormGroup.get('country').value }}"
            countryControlName="country"
            requiredErrorMessage="Please select a country"
            [formGroup]="billingDetailsFormGroup"
          ></app-country-selector>
        </div>
      </div>
    </mat-card-content>
    <mat-card-actions>
      <div class="container-actions">
        <button mat-icon-button (click)="cancel()">
          <mat-icon>cancel</mat-icon>
        </button>
        <button mat-icon-button (click)="save()">
          <mat-icon>save</mat-icon>
        </button>
      </div>
    </mat-card-actions>
  </mat-card>
</div>
