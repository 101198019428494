<div class="container mat-component-background">
  <h1 mat-dialog-title>{{ data.title }}</h1>
  <div mat-dialog-content>
    <p>
      {{ data.message }} <strong>{{ data.data }}</strong>
    </p>
  </div>
  <div mat-dialog-actions class="container-actions">
    <button
      mat-raised-button
      color="primary"
      (click)="onConfirmClick()"
      cdkFocusInitial
    >
      {{ data.confirm }}
    </button>
  </div>
</div>
