<div class="container">
    <div class="container-banner">
        <img src="/assets/img/planetvg_icon.png" width="150px" alt="planetvg-logo" />
        <br />
        <h1>No Planet B</h1>
        <div class="banner-content">
            <h2>
                It seems you were looking for Planet B, unfortunately there is no Planet B. Let's start saving this one.
            </h2>
        </div>
    </div>
</div>
<app-bottom-menu></app-bottom-menu>